import React from 'react';

/* eslint-disable react/button-has-type */
function Button(props) {
  const { btnLabel } = props;
  return (
    <button
      onClick={props.onClick}
      disabled={props.disabled}
      className={`button ${props.setClass ? props.setClass : ''}`}
    >
      {props.children}
      <span id={props.id} className='label'>
        {btnLabel}
      </span>
    </button>
  );
}
/* eslint-enable react/button-has-type */

export default Button;
