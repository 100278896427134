import React from 'react';

import PropertySearch from 'components/Common/PropertySearch';

function FooterSearch(props) {
  return (
    <div className='dark-green-bg padding-all' id='footer-search'>
      <h2 className='white'>Find A Loan Officer</h2>
      <PropertySearch transType={props.transType} className='using-bottom' />
    </div>
  );
}

export default FooterSearch;
