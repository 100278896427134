import React, { Component } from 'react';

import bgImage from 'assets/images/heroes/lendaid-header-contact.jpg';

import PageBanner from 'components/Common/PageBanner';
import Intro from 'components/Pages/Contact/Address';
import ContactUsForm from 'components/Pages/Contact/ContactUsForm';

class Contact extends Component {
  UNSAFE_componentWillMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div id='contact'>
        {/* Page Banner */}
        <PageBanner smallHeading='CONTACT US' largeHeading='Get In Touch' bgImage={bgImage} />

        <section className='padding-x padding-bottom margin-top'>
          <div className='single-column'>
            {/* Page Intro */}
            <Intro />

            {/* Page Form */}
            <ContactUsForm />
          </div>
        </section>
      </div>
    );
  }
}

export default Contact;
