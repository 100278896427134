import React from 'react';

import FooterTestmonial from 'components/Footer/FooterTestimonial';
import FooterSearch from 'components/Footer/FooterSearch';

function FooterSection(props) {
  const testifierName = props.name;
  const testifierLocation = props.location;
  const testifierComments = props.comments;
  const { transType } = props;
  const testifierImage = props.image;

  return (
    <section className='above-footer'>
      <FooterTestmonial
        testifierName={testifierName}
        testifierLocation={testifierLocation}
        testifierImage={testifierImage}
      >
        {testifierComments}
      </FooterTestmonial>

      <FooterSearch transType={transType} />
    </section>
  );
}

export default FooterSection;
