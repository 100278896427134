import React from 'react';
import { Link } from 'react-router-dom';

import Rating from 'components/Common/Rating';
import Ribbon from 'components/Common/Ribbon';
import Shield from 'components/Common/Shield';

import defaultImage from 'assets/images/default-placeholder.jpg';

import { SHIELD_TEXT, RIBBON_TEXT } from 'Constants';

function Profile(props) {
  const { agent } = props;
  let agentAvatar = defaultImage;
  if (agent.avatar) {
    agentAvatar = agent.avatar;
  }
  return (
    <div className='loan-officer'>
      <div className='loan-officer__image' style={{ backgroundImage: `url(${agentAvatar})` }} />
      <div className='loan-officer__info'>
        <Link to={`${agent.frontend_url}`}>
          <h4 className='loan-officer__name'>{agent.name}</h4>
        </Link>
        <div className='loan-officer__details'>
          <div>
            <p>NMLS: {agent.nmls}</p>
            <div className='loan-officer-icon-wrapper'>
              <p className='loan-officer__company'>{agent.lender ? agent.lender.name : ''}</p>
            </div>
            {agent.lender && agent.lender.is_certified ? <Ribbon popContent={RIBBON_TEXT} /> : null}
          </div>

          <div>
            <div className='loan-officer-icon-wrapper'>
              <Rating rate={agent.rating.points} reviewsCount={`${agent.reviews_count.total} Reviews`} />
            </div>
            <div className='shield-wrapper'>
              {agent.reviews_count.total && !agent['is_survey_enabled'] ? <Shield popContent={SHIELD_TEXT} /> : <div />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
