import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { submitAdvertiseForm } from 'actions/advertise_actions';
import { FORM_SUCCESS_MESSAGE, LO_FORM_ERROR_MESSAGE } from 'Constants';
import WithRouter from 'components/HOC/WithRouter';

class Intro extends Component {
  constructor(props) {
    super(props);
    this.renderField = this.renderField.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onFormResponse = this.onFormResponse.bind(this);
    this.state = { formSubmitting: false, formSubmitted: null };
  }

  onSubmit(formData) {
    this.setState({ formSubmitting: true });
    return this.props.submitAdvertiseForm(formData);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.formSubmitted !== this.props.formSubmitted) {
      this.onFormResponse(nextProps.status);
      this.setState({ formSubmitted: nextProps.formSubmitted });
    }
  }

  onFormResponse(status) {
    if (status === 500) {
      this.props.router.navigate(`/500`);
      return;
    }
    this.props.reset();
    this.setState({ formSubmitting: false });
  }

  renderField(field) {
    const {
      meta: { touched, error },
    } = field;
    return (
      <div className={`${field.className} ${touched && error ? 'form-error' : ''}`}>
        <label className='visually-hidden'>{field.label}</label>
        <div className='error-message-wrapper'>
          <div className='error-message'> {touched ? error : ''}</div>
        </div>
        {field.textArea ? (
          <textarea
            className={`${touched && error ? 'has-error' : ''}`}
            type={field.type}
            placeholder={field.label}
            {...field.input}
          />
        ) : (
          <input
            className={`${touched && error ? 'has-error' : ''}`}
            type={field.type}
            placeholder={field.label}
            {...field.input}
          />
        )}
      </div>
    );
  }

  render() {
    const { handleSubmit, invalid } = this.props;
    return (
      <div className='margin-top'>
        {this.state.formSubmitted === true ? (
          <div className='form-status-msg-container success'>{FORM_SUCCESS_MESSAGE}</div>
        ) : this.state.formSubmitted === false ? (
          <div className='form-status-msg-container error'>{LO_FORM_ERROR_MESSAGE}</div>
        ) : null}

        <form id='advertise-form' className='flex-form' onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Field
            className='form-item form-item--text half-width'
            label='First Name'
            name='first_name'
            type='text'
            component={this.renderField}
          />
          <Field
            className='form-item form-item--text half-width'
            label='Last Name'
            name='last_name'
            type='text'
            component={this.renderField}
          />
          <Field
            className='form-item form-item--text half-width'
            label='Email*'
            name='email'
            type='email'
            component={this.renderField}
          />
          <Field
            className='form-item form-item--text half-width'
            label='Company'
            name='company'
            type='text'
            component={this.renderField}
          />
          <Field
            className='form-item form-item--text half-width'
            label='Location*'
            name='address'
            type='text'
            component={this.renderField}
          />
          <Field
            className='form-item form-item--text half-width'
            label='Phone'
            name='phone'
            type='tel'
            component={this.renderField}
          />
          <Field
            className='form-item form-item--textarea'
            textArea='true'
            label='Message*'
            name='message'
            type='text'
            component={this.renderField}
          />
          <div className='form-item form-item--submit'>
            <button type='submit' disabled={this.state.formSubmitting || invalid} className='button'>
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProp({ advertise }) {
  return { formSubmitted: advertise.submitted, status: advertise.status };
}

function validate(values) {
  const errors = {};
  if (!values.email) {
    errors.email = 'Enter Email';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.message || !values.message.replace(/^\s+|\s+$/g, '')) {
    errors.message = 'Enter Message';
  }
  if (!values.address || !values.address.replace(/^\s+|\s+$/g, '')) {
    errors.address = 'Enter Location';
  }

  return errors;
}

export default reduxForm({ validate, form: 'AdvertiseForm' })(
  connect(mapStateToProp, { submitAdvertiseForm })(WithRouter(Intro))
);
