import React, { Component } from 'react';

import StepButton from 'components/Process/StepButton';
import { PROPERTY_TYPES, BORROW_TYPES, DOWN_TYPES, LOAN_TYPES, STEP_FIVE_HEADING } from 'Constants';
import WithRouter from 'components/HOC/WithRouter';

class Process extends Component {
  className(stepNumber, currentStep) {
    if (stepNumber < currentStep) {
      return 'completed';
    } else if (stepNumber === currentStep) {
      return 'active';
    }
    return '';
  }

  updateSearchQuery(step) {
    const searchQuery = JSON.parse(localStorage.getItem('searchQuery'));
    const stepsDataMap = { 2: 'homeType', 3: 'borrow', 4: 'down', 5: 'loanType' };
    const currentStep = parseInt(step, 10);
    let i = 2;
    for (; i < 6; i++) {
      if (i > currentStep) {
        searchQuery[stepsDataMap[i]] = null;
      }
    }
    localStorage.setItem('searchQuery', JSON.stringify(searchQuery));
  }

  changeStepComponent(toComponent, toStep) {
    if (this.props.currentStep < toStep) return;
    this.updateSearchQuery(toStep);
    this.props.router.navigate(`/search`, { state: { step: toComponent, processStep: toStep } });
  }

  render() {
    const { currentStep } = this.props;
    const searchQuery = JSON.parse(localStorage.getItem('searchQuery'));

    return (
      <div className='process'>
        <div className='steps'>
          <div className={`steps__step ${this.className('1', currentStep)}`}>
            <StepButton stepNumber='1' stepValue='Location' userValue={searchQuery.searchText.split('-')[0]} />
          </div>

          <div
            onClick={this.changeStepComponent.bind(this, 'StepOne', '2')}
            className={`steps__step ${this.className('2', currentStep)}`}
          >
            <StepButton stepNumber='2' stepValue='Property Type' userValue={PROPERTY_TYPES[searchQuery.homeType]} />
          </div>

          <div
            onClick={this.changeStepComponent.bind(this, 'StepTwo', '3')}
            className={`steps__step ${this.className('3', currentStep)}`}
          >
            <StepButton stepNumber='3' stepValue='Loan Amount' userValue={BORROW_TYPES[searchQuery.borrow]} />
          </div>
          <div
            onClick={this.changeStepComponent.bind(this, 'StepThree', '4')}
            className={`steps__step ${this.className('4', currentStep)}`}
          >
            <StepButton stepNumber='4' stepValue='Home Value' userValue={DOWN_TYPES[searchQuery.down]} />
          </div>

          <div
            onClick={this.changeStepComponent.bind(this, 'StepFour', '5')}
            className={`steps__step ${this.className('5', currentStep)}`}
          >
            {searchQuery.transactionType === 'RE' ? (
              <StepButton stepNumber='5' stepValue='Loan Type' userValue={LOAN_TYPES[searchQuery.loanType]} />
            ) : (
              <StepButton stepNumber='5' stepValue={STEP_FIVE_HEADING} userValue={searchQuery.personal} />
            )}
          </div>
          {searchQuery.transactionType === 'RE' ? (
            <div
              onClick={this.changeStepComponent.bind(this, 'StepFive', '6')}
              className={`steps__step ${this.className('6', currentStep)}`}
            >
              <StepButton stepNumber='6' stepValue={STEP_FIVE_HEADING} userValue={searchQuery.personal} />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default WithRouter(Process);
