import React from 'react';

function Rating(props) {
  let hidden = '';
  const { reviewsCount } = props;
  if (!props.alwaysShow && reviewsCount === '0 Reviews') {
    hidden = 'visually-hidden';
  }
  const rate = Math.ceil(props.rate);
  return (
    <div className={`${hidden} officer`}>
      <div className={`stars stars--${rate}`}>
        <div className='star'>
          <span className='icon-star' />
        </div>
        <div className='star'>
          <span className='icon-star' />
        </div>
        <div className='star'>
          <span className='icon-star' />
        </div>
        <div className='star'>
          <span className='icon-star' />
        </div>
        <div className='star'>
          <span className='icon-star' />
        </div>
      </div>
      <p>{reviewsCount}</p>
    </div>
  );
}

export default Rating;
