import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import AgentInfo from 'components/Common/AgentInfo';
import Loader from 'components/Common/Loader';
import NotFound from 'components/Pages/404';
import PageMeta from 'components/Common/PageMeta';
import OfficerSurveyForm from 'components/Pages/OfficerSurvey/OfficerSurveyForm';

// Actions
import { fetchAgentProfile } from 'actions/agent_actions';
import { getOfficerMetaInfo } from 'actions/utility_actions';
import { useParams } from 'react-router-dom';
import { TPO_GO_NMLS } from '../../../Constants';
import Error from '../404';

function OfficerSurvey() {
  const { nameSlug } = useParams();
  const officer = useSelector(state => state.agentProfile.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAgentProfile(nameSlug));
  }, [nameSlug, dispatch]);

  // Officer not found, or Officer survey is not enabled
  // Officer is undefined when URL is not correct
  if (officer === undefined || (officer && !officer['is_survey_enabled'])) return <NotFound />;

  if (officer && !officer.lender) return <NotFound />;

  if (officer && officer.lender && officer.lender.nmls === TPO_GO_NMLS && window.location.pathname.endsWith('/survey'))
    return <Error />;

  // Loading
  if (officer === null) {
    return (
      <div className='text-center loader-wrapper'>
        <Loader />
      </div>
    );
  }

  // Move this to new component, which calls PageMeta inside itself.
  const [metaTitle, metaDescription, logoImage] = getOfficerMetaInfo(officer);

  return (
    <div id='agent-profile'>
      <PageMeta
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        FBImage={logoImage}
        TwitterImage={logoImage}
        url={officer.frontend_url}
      />
      {officer.lender.nmls !== TPO_GO_NMLS && (
        <div className='container agent-info margin-x padding-top'>
          <AgentInfo showContactButton='off' agent={officer} />
        </div>
      )}

      <div className='container margin-x padding-all margin-bottom'>
        <OfficerSurveyForm officer={officer} />
      </div>
    </div>
  );
}

export default OfficerSurvey;
