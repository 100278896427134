import React from 'react';
import { useDispatch } from 'react-redux';

import Rating from 'components/Common/Rating';
import shield from 'assets/images/shield.png';
import ReportIcon from 'components/Icons/ReportFlag';
import { openReportReviewModal } from 'actions/modal_actions';
import Tooltip from 'components/Common/Tooltip';

function LenderTestimonial(props) {
  const { reviewId, reviewerType, purchase, address, rating } = props;
  const borrower = props.borrower || '';

  const dispatch = useDispatch();

  const handleReportClick = () => {
    dispatch(openReportReviewModal(reviewId));
  };

  return (
    <div className='testimonial'>
      <div>
        <div style={{ float: 'right' }}>
          Verified <img alt='Certified' src={shield} height='25px' />
        </div>
        <Rating rate={rating} />
      </div>

      <div className='comment'>{props.children}</div>

      <div>
        <Tooltip message='Flag review' position='top' style={{ float: 'right' }}>
          <ReportIcon onClick={handleReportClick} className='icon' />
        </Tooltip>

        <div className='user'>
          <strong>{purchase}</strong>
          <br />
          {borrower && `${borrower} - `}
          {address}
          <br />
          {reviewerType === 'RP' && 'Referral Partner'}
        </div>
      </div>
    </div>
  );
}

export default LenderTestimonial;
