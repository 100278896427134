import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';

import CloseIcon from 'components/Icons/Close';
import ReportReviewForm from 'components/Pages/AgentProfile/ReportReviewForm';
import { closeReportReviewModal } from 'actions/modal_actions';
import { resetReviewReportForm } from 'actions/form_actions';

function ReportReviewModal() {
  const { isOpen, reviewId } = useSelector(state => state.modals.reportReviewModal);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeReportReviewModal());
    dispatch(resetReviewReportForm());
  };

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={handleClose}
      overlayClassName='modal-overlay'
      className='modal-content'
    >
      <div className='modal-header'>
        <CloseIcon onClick={handleClose} className='icon' />
        <h6>Report Review</h6>
      </div>
      <div className='modal-main-content'>
        <ReportReviewForm reviewId={reviewId} />
      </div>
    </Modal>
  );
}

export default ReportReviewModal;
