import React from 'react';

// assets
import ribbon from 'assets/images/yellow-ribbon.png';

function Ribbon(props) {
  const { popContent } = props;

  return (
    <div className='hover-pop-up'>
      <div className='hover-pop-up__body'>{popContent}</div>
      <div className='hover-pop-up__icon'>
        <img alt='Certified' src={ribbon} />
      </div>
    </div>
  );
}

export default Ribbon;
