/* eslint-disable no-underscore-dangle */
import React from 'react';

import { INFO_WINDOW_STATE_TYPES, DEFAULT_INFO_WINDOW_STATE } from 'components/Pages/EmbeddedMap/Map';

function MapMarker({ lat, lng, transactions, mapRef, zipCode, infoWindow, setInfoWindow }) {
  const openInfoWindow = () => {
    mapRef.current.map_.panTo({ lat: parseFloat(lat), lng: parseFloat(lng) });
    setInfoWindow({
      state: INFO_WINDOW_STATE_TYPES.OPEN,
      transactions,
      lat,
      lng,
      zipCode,
    });
  };

  const openMinimizedInfoWindow = () => {
    // Don't update the InfoWindow to 'minimized' if there is already an 'open' InfoWindow
    if (infoWindow.state === INFO_WINDOW_STATE_TYPES.OPEN) {
      return;
    }
    setInfoWindow({
      state: INFO_WINDOW_STATE_TYPES.MINIMIZED,
      transactions,
      lat,
      lng,
      zipCode,
    });
  };

  const closeMinimizedInfoWindow = () => {
    // Close the InfoWindow on mouse leave only when the InfoWinfow is 'minimized'
    if (infoWindow.state === INFO_WINDOW_STATE_TYPES.MINIMIZED) {
      setInfoWindow(DEFAULT_INFO_WINDOW_STATE);
    }
  };

  return (
    <div
      className='map-marker'
      onClick={openInfoWindow}
      onMouseEnter={openMinimizedInfoWindow}
      onMouseLeave={closeMinimizedInfoWindow}
    >
      <img src={require('assets/images/buying-marker.png')} />
    </div>
  );
}

export default MapMarker;
