import React from 'react';
import { NavLink } from 'react-router-dom';

import youtubeIcon from 'assets/images/youtube.svg';
import phoneImage from 'assets/images/phone-footer.png';
import { ADDRESS } from 'Constants';

function Footer() {
  return (
    <footer id='footer' className='site-footer'>
      <div className='fourColWrapper site-footer__top'>
        <div className='multiColInner'>
          <h6 className='site-footer__title'>Contact</h6>
          <p>
            <a target='_blank' rel='noopener noreferrer' href='https://goo.gl/maps/wnEGuRJmSV72' aria-label='Address'>
              {ADDRESS['street']}
              <br />
              {ADDRESS['suite']}
              <br />
              {ADDRESS['state']}
            </a>
          </p>
          <img src={phoneImage} alt='phone' width='115' height='18' />
          <p>
            <a href='mailto:info@lendaid.com'>info@lendaid.com</a>
          </p>
        </div>
        <div className='multiColInner'>
          <h6 className='site-footer__title'>Company</h6>
          <p>
            <NavLink end to='/about-us'>
              About
            </NavLink>
          </p>
          <p>
            <NavLink end to='/advice'>
              Advice
            </NavLink>
          </p>
          <p>
            <NavLink end to='/contact-us'>
              Contact
            </NavLink>
          </p>
          <p>
            <NavLink end to='/advertise-with-us'>
              Advertising
            </NavLink>
          </p>
          <p>
            <NavLink end to='/espanol'>
              Espanol
            </NavLink>
          </p>
        </div>
        <div className='multiColInner'>
          <h6 className='site-footer__title'>Resources</h6>
          <p>
            <NavLink end to='/how-it-works'>
              How It Works
            </NavLink>
          </p>
          <p>
            <NavLink end to='/buying'>
              Buying
            </NavLink>
          </p>
          <p>
            <NavLink end to='/rates'>
              Rates
            </NavLink>
          </p>
        </div>
        <div className='multiColInner'>
          <ul className='social'>
            <li>
              <a
                href='https://www.facebook.com/LENDAIDinc/'
                target='_blank'
                title='Visit us on Facebook'
                rel='noopener noreferrer'
                aria-label='Visit us on Facebook'
              >
                <span className='icon-facebook' />
              </a>
            </li>
            <li>
              <a
                href='https://www.linkedin.com/company/lendaidinc/'
                target='_blank'
                title='Visit us on LinkedIn'
                rel='noopener noreferrer'
                aria-label='Visit us on LinkedIn'
              >
                <span className='icon-linkedin' />
              </a>
            </li>
            <li>
              <a
                href='https://twitter.com/lendaidinc'
                target='_blank'
                title='Visit us on Twitter'
                rel='noopener noreferrer'
                aria-label='Visit us on Twitter'
              >
                <span className='icon-tweet' />
              </a>
            </li>
            <li>
              <a
                href='https://www.instagram.com/lendaidinc/'
                target='_blank'
                title='Visit us on Instagram'
                rel='noopener noreferrer'
                aria-label='Visit us on Instagram'
              >
                <span className='icon-instagram' />
              </a>
            </li>
            <li>
              <a
                href='https://www.youtube.com/channel/UCyoB16_RUnUsueBktJ6gD0w'
                style={{ position: 'relative', height: 32 }}
                target='_blank'
                rel='noopener noreferrer'
                title='Visit us on YouTube'
              >
                <img className='icon-youtube' src={youtubeIcon} alt='Youtube logo' />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className='site-footer_bottom'>
        <div className='tos'>
          <NavLink end to='/tos'>
            Terms of Use
          </NavLink>
          <NavLink end to='/privacy'>
            Privacy Policy
          </NavLink>
        </div>
        <div className='site-by'>
          <a
            href='https://altosagency.com'
            target='_blank'
            rel='noopener noreferrer nofollow'
            aria-label='Altos Design Agency'
          >
            Site Design by Altos
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
