import React from 'react';

import shield from 'assets/images/shield.png';

function Shield(props) {
  const { popContent } = props;
  return (
    <div className='hover-pop-up'>
      <div className='hover-pop-up__body'>{popContent}</div>
      <div className='hover-pop-up__icon'>
        <img alt='Certified' src={shield} />
      </div>
    </div>
  );
}

export default Shield;
