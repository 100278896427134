import { combineReducers } from 'redux';

import AgentReducer from 'reducers/agent_reducer';
import SearchReducer from 'reducers/search_reducer';
import GeoLocationReducer from 'reducers/geoloaction_reducer';
import AdviceReducer from 'reducers/advice_reducer';
import ContactReducer from 'reducers/contact_reducer';
import AdvertiseReducer from 'reducers/advertise_reducer';
import LenderReducer from 'reducers/lenders_reducer';
import ModalReducer from 'reducers/modal_reducer';
import FormsReducer from 'reducers/forms_reducer';
import { reducer as formReducer } from 'redux-form';

const rootReducer = combineReducers({
  agentProfile: AgentReducer,
  agentsList: SearchReducer,
  locationMap: GeoLocationReducer,
  advices: AdviceReducer,
  contactForm: ContactReducer,
  advertise: AdvertiseReducer,
  lenderReducer: LenderReducer,
  modals: ModalReducer,
  forms: FormsReducer,
  form: formReducer,
});

export default rootReducer;
