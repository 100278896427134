import _ from 'lodash';

import React, { Component } from 'react';

import Button from 'components/Common/Button';

import { LOAN_TYPES, BU_STEP_FOUR_HEADING, RE_STEP_FOUR_HEADING } from 'Constants';
import WithRouter from 'components/HOC/WithRouter';

class StepFour extends Component {
  clickHandler = (loanType, LOAN_TYPES) => {
    const searchQuery = JSON.parse(localStorage.getItem('searchQuery'));
    if (searchQuery.transactionType === 'BU') searchQuery['isVeteran'] = loanType;
    else searchQuery['loanType'] = _.findKey(LOAN_TYPES, o => o === loanType);
    localStorage.setItem('searchQuery', JSON.stringify(searchQuery));
    this.props.router.navigate(`/search`, { state: { step: 'StepFive', processStep: '6' } });
  };

  render() {
    const { transactionType } = JSON.parse(localStorage.getItem('searchQuery'));
    let heading = RE_STEP_FOUR_HEADING;
    if (transactionType === 'BU') {
      heading = BU_STEP_FOUR_HEADING;
    }
    return (
      <div id='step-five'>
        <div className='container padding-all margin-x margin-bottom text-center'>
          <h1 className='capitalize'>{heading}</h1>
          {transactionType === 'BU' ? (
            <ul className='buttons-holder  process-holder'>
              <li>
                <Button onClick={() => this.clickHandler(true, null)} btnLabel='Yes' setClass='plain' />
              </li>
              <li>
                <Button onClick={() => this.clickHandler(false, null)} btnLabel='No' setClass='plain' />
              </li>
            </ul>
          ) : (
            <ul className='buttons-holder  process-holder'>
              <li>
                <Button onClick={() => this.clickHandler(`FHA/VA`, LOAN_TYPES)} btnLabel='FHA/VA' setClass='plain' />
              </li>
              <li>
                <Button
                  onClick={() => this.clickHandler(`Conventional`, LOAN_TYPES)}
                  btnLabel='CONVENTIONAL'
                  setClass='plain'
                />
              </li>
              <li>
                <Button onClick={() => this.clickHandler(`Both`, LOAN_TYPES)} btnLabel='BOTH' setClass='plain' />
              </li>
            </ul>
          )}
        </div>
      </div>
    );
  }
}

export default WithRouter(StepFour);
