import _ from 'lodash';

import React, { Component } from 'react';

import ProfileList from 'components/Profile/ProfileList';
import LenderProfileLegalTerm from 'components/Common/LenderProfileLegalTerm';

class ProfileGroup extends Component {
  renderAgentsList(agentsList, showReview) {
    // removing sorting by reviews count on agentsList because Jerry suggested this
    // agentsList.sort(function(a, b) {
    //     return (a.reviews_count.total < b.reviews_count.total) ? 1 : (b.reviews_count.total < a.reviews_count.total) ? -1 : 0;
    // });

    return _.map(agentsList, agent => (
      <li key={agent.id}>
        <ProfileList showReview={showReview} agent={agent} />
      </li>
    ));
  }

  render() {
    const { agentsList, showReview } = this.props;

    return (
      <div>
        <ul className='profile-list'>{this.renderAgentsList(agentsList, showReview)}</ul>
        <LenderProfileLegalTerm />
      </div>
    );
  }
}

export default ProfileGroup;
