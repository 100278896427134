import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import _ from 'lodash';

import { RECAPTCHA_KEY } from 'config';
import { FORM_SUCCESS_MESSAGE, LO_FORM_ERROR_MESSAGE } from 'Constants';
import { submitContactForm } from 'actions/contact_actions';
import TextField from 'components/FormComponents/TextField';

const defaultValues = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  message: '',
  recaptcha: null,
};

const validationSchema = yup
  .object({
    first_name: yup.string(),
    last_name: yup.string(),
    email: yup.string().trim().required('Enter Email').email('Invalid email address'),
    phone: yup.string(),
    message: yup.string().trim().required('Enter Message'),
    recaptcha: yup.string().nullable().trim().required(),
  })
  .required();

function ContactUsForm() {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const { submitted: formSubmitted, status } = useSelector(state => state.contactForm);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reCaptchaRef = useRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({ mode: 'onTouched', defaultValues, resolver: yupResolver(validationSchema) });

  const recaptchaValue = watch('recaptcha');

  const onSubmit = data => {
    setFormSubmitting(true);
    dispatch(submitContactForm(data));
  };

  useEffect(() => {
    if (status === 500) {
      navigate('/500');
      return;
    }
    reset();
    reCaptchaRef.current.reset();
    setFormSubmitting(false);
  }, [formSubmitted, status, reset, navigate]);

  return (
    <div className='margin-top'>
      {formSubmitted === true ? (
        <div className='form-status-msg-container success'>{FORM_SUCCESS_MESSAGE}</div>
      ) : formSubmitted === false ? (
        <div className='form-status-msg-container error'>{LO_FORM_ERROR_MESSAGE}</div>
      ) : null}

      <form onSubmit={handleSubmit(onSubmit)} className='form'>
        <div className='form-row'>
          <TextField
            id='first_name'
            type='text'
            placeholder='First Name'
            {...register('first_name')}
            containerClass='form-item col-2'
          />

          <TextField
            id='last_name'
            type='text'
            placeholder='Last Name'
            {...register('last_name')}
            containerClass='form-item col-2'
          />
        </div>

        <div className='form-row'>
          <TextField
            id='email'
            type='email'
            placeholder='Email*'
            {...register('email')}
            errorMessage={errors?.email?.message}
            containerClass='form-item col-2'
          />

          <TextField
            id='phone'
            type='tel'
            placeholder='Phone'
            {...register('phone')}
            containerClass='form-item col-2'
          />
        </div>

        <div className='form-row'>
          <TextField
            id='message'
            type='textarea'
            placeholder='Message*'
            {...register('message')}
            errorMessage={errors?.message?.message}
            containerClass='form-item'
          />
        </div>

        <div className='form-row'>
          <ReCAPTCHA
            sitekey={RECAPTCHA_KEY}
            onChange={value => {
              setValue('recaptcha', value);
            }}
            ref={reCaptchaRef}
            className='recaptcha'
          />
        </div>

        <div className='form-row'>
          <button type='submit' disabled={formSubmitting || !_.isEmpty(errors) || !recaptchaValue} className='button'>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default ContactUsForm;
