import React from 'react';

function BlogPost(props) {
  const { month, date, heading, link } = props;

  return (
    <div className='blog__item'>
      <div className='blog-date'>
        <div className='blog-date__month'>{month}</div>
        <div className='blog-date__day'>{date}</div>
      </div>
      <div className='blog-content'>
        <h2>
          <a href={link}>{heading}</a>
        </h2>
        <p>{props.children}</p>
        <p className='read-more'>
          <a className='button' href={link}>
            Read Article
          </a>
        </p>
      </div>
    </div>
  );
}

export default BlogPost;
