import { SUBMIT_REVIEW_REPORT_FORM, RESET_REVIEW_REPORT_FORM } from 'actions/action_types';

const initialState = {
  reportReviewForm: {
    isSubmitted: null,
    status: null,
  },
};

function formsReducer(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_REVIEW_REPORT_FORM:
      return {
        ...state,
        reportReviewForm: {
          isSubmitted: action.payload?.status === 201 || action.payload?.response?.status === 201,
          status: action.payload.status,
        },
      };
    case RESET_REVIEW_REPORT_FORM:
      return {
        ...state,
        reportReviewForm: { ...initialState.reportReviewForm },
      };
    default:
      return state;
  }
}

export default formsReducer;
