import { OPEN_REPORT_REVIEW_MODAL, CLOSE_REPORT_REVIEW_MODAL } from 'actions/action_types';

const initialState = {
  reportReviewModal: {
    isOpen: false,
    reviewId: null,
  },
};

function modalReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_REPORT_REVIEW_MODAL:
      return { ...state, reportReviewModal: { isOpen: true, reviewId: action.payload } };
    case CLOSE_REPORT_REVIEW_MODAL:
      return { ...state, reportReviewModal: { isOpen: false, reviewId: null } };
    default:
      return state;
  }
}

export default modalReducer;
