export const ROOT_URL = `${process.env.REACT_APP_BACKEND_URL}/api/v1`;
export const MAP_API_URL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=`;
export const IP_LOCATION_API_URL = `https://ipapi.co/json`;
export const MAP_API_KEY = `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
export const APP_URL = `${process.env.REACT_APP_FRONTEND_URL}`;
export const RECAPTCHA_KEY = `${process.env.REACT_APP_RECAPTCHA_KEY}`;
export const SENTRY_DSN = `${process.env.SENTRY_DSN}`;

export const DEFAULT_MAP_CENTER = { lat: 42.298314, lng: -71.070545 }; // Boston LatLng
export const DEFAULT_MAP_ZOOM = 8;
