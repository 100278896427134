import React from 'react';

import aboutImage from 'assets/images/about/about-in-page.jpg';

function Intro() {
  return (
    <section className='padding-x padding-bottom margin-top'>
      <div className='single-column'>
        <h2>Once Upon A Time...</h2>
        <p>
          Once upon a time in a satellite loan office in a suburb of Boston, a small group of 20 somethings scrambled to
          originate, process and close millions of dollars in mortgage loans. It was the 1980s and rates were dipping
          below double digits for the first time in years, and buyers were coming out of the woodwork to generate one of
          the strongest real estate markets we had seen in a long time.
        </p>
        <p>
          We were a team and we were unique; we truly cared about our customer. That group of 20 somethings has now
          spent another 30+ years up close and personal with borrowers and buyers, not to mention the ups and downs of
          the mortgage industry as a whole, drama, crisis and all.
        </p>
        <img className='margin-y--half block' src={aboutImage} alt='group of people at table' />
        <h5>We have learned something in the process:</h5>
        <ul className='checklist'>
          <li>Buying a home is serious business.</li>
          <li>
            Getting a mortgage loan can be complicated, and it doesn’t happen with the push of a button. Guidance is
            vital.
          </li>
          <li>When it comes to obtaining a mortgage, the loan officer matters.</li>
          <li>A good mortgage loan officer is the difference between a smooth or stressful transaction.</li>
        </ul>
        <p>
          At LENDAID we connect you with experienced local loan officers by using real data to match you with real
          people. Consulting LENDAID for a loan officer recommendation is like turning to an unbiased and knowledgeable
          friend for advice on who to talk to about a mortgage. We think this is a consumer service long overdue. We
          hope to make your life easier as you search for your mortgage.
        </p>
        <p>Consider it our life’s work come full circle.</p>
        <p>With Warm Regards,</p>
        <p>The LENDAID Team</p>
      </div>
    </section>
  );
}

export default Intro;
