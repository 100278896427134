import _ from 'lodash';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Geolocation from 'react-geolocation';

import { autoCompleteQuery } from 'actions/search_actions';
import { fetchLocationDetails } from 'actions/geolocation_actions';
import WithRouter from 'components/HOC/WithRouter';

class PropertySearch extends Component {
  constructor(props) {
    super(props);
    const locationMap = JSON.parse(localStorage.getItem('location'));

    if (locationMap && locationMap.iso_state) {
      this.state = {
        searchText: this.queryText(locationMap, locationMap.postal_code),
        textToShow: this.textToShow(locationMap, locationMap.postal_code),
        locationType: locationMap.postal_code ? 'Z' : locationMap.city ? 'C' : 'S',
        searchDisabled: false,
        dropdownDisabled: true,
        dropdownClass: this.props.className,
        disableGeoLocation: true,
      };
    } else {
      this.state = {
        dropdownDisabled: true,
        dropdownClass: this.props.className,
        searchText: '',
        textToShow: '',
        locationType: '',
        searchDisabled: true,
        disableGeoLocation: true,
      };
    }

    this.onInputChange = this.onInputChange.bind(this);
    this.queryText = this.queryText.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.locationMap !== this.props.locationMap) {
      const { locationMap } = nextProps;
      this.setState({
        dropdownClass: nextProps.className,
        searchDisabled: false,
        locationType: locationMap.postal_code ? 'Z' : locationMap.city ? 'C' : 'S',
        textToShow: this.textToShow(locationMap, locationMap.postal_code),
        searchText: this.queryText(locationMap, locationMap.postal_code),
      });
    }
  }

  clearInputField() {
    this.setState({
      dropdownClass: this.props.className,
      searchDisabled: true,
      locationType: '',
      textToShow: '',
      searchText: '',
    });
    this.searchElement.focus();
  }

  setSearchRef(node) {
    this.searchElement = node;
  }

  coordinate = data => {
    this.props.fetchLocationDetails(data.coords.latitude, data.coords.longitude);
  };

  inputFocused() {
    this.setState({ disableGeoLocation: false });
  }

  queryText(locationMap, zipCode) {
    if (!locationMap) return null;
    return zipCode
      ? `${zipCode} - ${locationMap.city}, ${locationMap.state}`
      : locationMap.city
      ? `${locationMap.city} - ${locationMap.state}`
      : `${locationMap.state}`;
  }

  textToShow(locationMap, zipCode) {
    if (!locationMap) return null;
    return zipCode
      ? `${zipCode} - ${locationMap.city}, ${locationMap.iso_state}`
      : locationMap.city
      ? `${locationMap.city} - ${locationMap.iso_state}`
      : `${locationMap.iso_state}`;
  }

  onOptionSeleced(toShow, toSearch, locationType) {
    this.setState({
      searchText: toSearch,
      locationType,
      textToShow: toShow,
      searchDisabled: false,
      dropdownDisabled: true,
    });
  }

  renderOptions(autoCompleteList) {
    return _.map(autoCompleteList, location => {
      const toSearch = this.queryText(location.location, location.location.zip_code);
      const toShow = this.textToShow(location.location, location.location.zip_code);
      const locationType = location.location_type;
      return (
        <div
          className='search-option'
          toShow={toShow}
          value={toSearch}
          onClick={this.onOptionSeleced.bind(this, toShow, toSearch, locationType)}
        >
          {toShow}
        </div>
      );
    });
  }

  onInputChange(event) {
    const query = event.target.value;
    this.setState({ dropdownClass: this.props.className, textToShow: query, searchDisabled: true });

    if (query.length < 3) {
      this.setState({ searchDisabled: true, dropdownDisabled: true });
      return;
    }
    const searchTextType = isNaN(query) ? 'CS' : 'ZI';
    this.setState({ locationType: searchTextType, dropdownDisabled: false });
    this.props.autoCompleteQuery(query, searchTextType);
  }

  onSearch(e) {
    e.preventDefault();
    let transType = null;
    if (this.props.transType) {
      transType = this.props.transType;
    } else if (e.target.elements[0].checked) transType = e.target.elements[0].value;
    else if (e.target.elements[1].checked) transType = e.target.elements[1].value;
    let { locationType } = this.state;
    if (!locationType) {
      const locationMap = JSON.parse(localStorage.getItem('location'));
      if (locationMap) {
        locationType = locationMap.postal_code ? 'Z' : locationMap.city ? 'C' : 'S';
      }
    }
    const searchQuery = {
      searchText: this.state.searchText,
      transactionType: transType,
      locationType,
      loanType: null,
      geoLocation: this.state.geoLocation,
      homeType: null,
      borrow: null,
      down: null,
      personalInfo: null,
      isVeteran: false,
    };
    localStorage.setItem('searchQuery', JSON.stringify(searchQuery));
    this.props.router.navigate(`/search`, { state: { step: 'StepOne', processStep: '2' } });
  }

  render() {
    let options = null;
    let radiusClass = '';
    const { autoCompleteList } = this.props;
    if (autoCompleteList && autoCompleteList.zip_codes) {
      if (!this.state.dropdownDisabled) radiusClass = 'search-radius';
      options = <div className='dropdown-wrapper'> {this.renderOptions(this.props.autoCompleteList.zip_codes)} </div>;
    }
    if (autoCompleteList && !autoCompleteList.zip_codes) {
      if (!this.state.dropdownDisabled) radiusClass = 'search-radius';
      options = (
        <div className='dropdown-wrapper'>
          {this.props.autoCompleteList.cities.length ? <div className='search-headings'>Cities</div> : <div />}
          {this.renderOptions(this.props.autoCompleteList.cities)}

          {this.props.autoCompleteList.states.length ? <div className='search-headings'>States</div> : <div />}
          {this.renderOptions(this.props.autoCompleteList.states)}
        </div>
      );
    }

    const locationMap = JSON.parse(localStorage.getItem('location'));
    let locationSource = '';
    let iso_state = '';
    if (locationMap) {
      locationSource = locationMap.source;
      iso_state = locationMap.iso_state;
    }
    let marginTopClass = '';
    if (this.props.className !== 'using-bottom' && !this.props.transType) marginTopClass = 'margin-top--half';

    return (
      <div id='search-form'>
        {this.state.disableGeoLocation ? null : locationSource === 'html5' ? null : (
          <Geolocation onSuccess={this.coordinate} />
        )}

        {!locationMap ? null : iso_state ? null : <Geolocation onSuccess={this.coordinate} />}

        <form
          className={`loan-officer-form loan-officer-form--in-hero ${marginTopClass} inline-search-button`}
          id='loan-officer-form'
          onSubmit={this.onSearch.bind(this)}
        >
          {this.props.transType ? null : (
            <div className='form-item form-item--radio'>
              <div className='form-radio'>
                <input
                  defaultValue='BU'
                  defaultChecked='checked'
                  id={`buying-hero ${this.props.className}`}
                  name='type-of-financing'
                  type='radio'
                />
                <label htmlFor={`buying-hero ${this.props.className}`}>Buying</label>
              </div>
              <div className='form-radio'>
                <input
                  defaultValue='RE'
                  id={`refinance-hero ${this.props.className}`}
                  name='type-of-financing'
                  type='radio'
                />
                <label htmlFor={`refinance-hero ${this.props.className}`}>Refinancing</label>
              </div>
            </div>
          )}
          <div className='form-item form-item--text search-wrapper'>
            <label htmlFor='place-hero' className='visually-hidden'>
              Enter the city or zip of Property
            </label>
            <input
              id='place-hero'
              className={`${radiusClass} geolocate`}
              type='text'
              ref={this.setSearchRef.bind(this)}
              autoComplete='off'
              placeholder='Enter the city or zip of Property'
              value={this.state.textToShow}
              onChange={event => this.onInputChange(event)}
              onFocus={this.inputFocused.bind(this)}
            />
            {!this.state.textToShow ? null : (
              <button className='close-icon' type='reset' onClick={this.clearInputField.bind(this)}>
                ×
              </button>
            )}
            {this.state.dropdownDisabled ? null : options}
          </div>
          <div className='form-item form-item--submit'>
            <button type='submit' disabled={this.state.searchDisabled} className='button'>
              Search
            </button>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProp({ locationMap, agentsList }) {
  return { locationMap: locationMap.locationMap, autoCompleteList: agentsList.autoCompleteList };
}

export default connect(mapStateToProp, { autoCompleteQuery, fetchLocationDetails })(WithRouter(PropertySearch));
