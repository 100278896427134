import React from 'react';

function StepButton(props) {
  const { stepNumber, stepValue, userValue } = props;

  return (
    <div className='steps-desc'>
      <div className='step-number'>{stepNumber}</div>
      <div className='steps-desc__number'> {stepValue} </div>
      <div className='steps-desc__body'> {userValue} </div>
    </div>
  );
}

export default StepButton;
