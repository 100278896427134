import { CONTACT_FORM } from 'actions/action_types';
import { SubmissionError } from 'redux-form';

const initialState = { submitted: null };
function contactReducer(state = initialState, action) {
  switch (action.type) {
    case CONTACT_FORM:
      if (action?.payload?.response?.status === 400 || action?.payload?.response?.status === 401)
        throw new SubmissionError(action.payload.response.data);
      if (action?.payload?.response?.status === 500)
        return { ...state, submitted: false, status: action.payload.response.status };
      if (action.payload.status === 201) return { ...state, submitted: true };
      return { ...state };
    default:
      return state;
  }
}

export default contactReducer;
