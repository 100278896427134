import React, { Component } from 'react';

import bgImage from 'assets/images/heroes/lendaid-header-buying.jpg';

import PageBanner from 'components/Common/PageBanner';
import Intro from 'components/Pages/Buying/Intro';
import Content from 'components/Pages/Buying/Content';
import FooterSections from 'components/Footer/FooterSections';
import testfierImage from 'assets/images/home-testimonial.jpg';

class Buying extends Component {
  UNSAFE_componentWillMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div id='buying'>
        {/* Page Banner */}
        <PageBanner smallHeading='BUYING' largeHeading='Why LENDAID?' bgImage={bgImage} />

        {/* Page Intro */}
        <Intro />

        {/* Page Content */}
        <Content />

        {/* FooterSection */}
        <FooterSections
          name='John M'
          transType='BU'
          image={testfierImage}
          location='Brookline, MA'
          comments='I had a time sensitive investment purchase.  LENDAID  connected me with an experienced lender that saved me valuable time and money.'
        />
      </div>
    );
  }
}

export default Buying;
