import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import Button from 'components/Common/Button';
import Rating from 'components/Common/Rating';
import Ribbon from 'components/Common/Ribbon';
import Shield from 'components/Common/Shield';

import defaultAgentPhoto from 'assets/images/default-placeholder.jpg';

import { SHIELD_TEXT, RIBBON_TEXT } from 'Constants';
import WithRouter from 'components/HOC/WithRouter';

class ProfileList extends Component {
  constructor(props) {
    super(props);
    if (this.props.agent.name !== undefined) {
      this.state = {
        agent: this.props.agent,
      };
    }
    this.viewProfile = this.viewProfile.bind(this);
  }

  viewProfile() {
    this.props.router.navigate(`${this.state.agent.frontend_url}`);
  }

  render() {
    const { agent } = this.state;
    return (
      <div className='loan-officer'>
        <div className='loan-officer__info'>
          <div className='loan-officer__image'>
            <NavLink end to={`${this.state.agent.frontend_url}`}>
              {agent.avatar ? (
                <div className='loan-officer__image' style={{ backgroundImage: `url(${agent.avatar})` }} />
              ) : (
                <div className='loan-officer__image' style={{ backgroundImage: `url(${defaultAgentPhoto})` }} />
              )}
            </NavLink>
          </div>
          <div className='loan-officer-content'>
            <NavLink end to={`${this.state.agent.frontend_url}`}>
              <h4 className='loan-officer__name'>{agent.name}</h4>
            </NavLink>
            <p className='loan-officer__company'>NMLS: {agent.nmls}</p>
            <br />
            <p className='loan-officer__company'>{agent.lender.name}</p>
            {agent.lender.is_certified ? <Ribbon popContent={RIBBON_TEXT} /> : null}
          </div>
        </div>
        <div className='push-right'>
          <div className='company-rating'>
            <Rating rate={agent.rating.points} reviewsCount={`${agent.reviews_count.total} Reviews`} />
          </div>
          {agent.reviews_count.total &&
          (!agent['is_survey_enabled'] || (agent.lender ? agent.lender['is_reviews_sourced_independently'] : false)) ? (
            <Shield popContent={SHIELD_TEXT} />
          ) : null}
          <Button onClick={this.viewProfile} btnLabel='View Profile' />
        </div>
      </div>
    );
  }
}

export default WithRouter(ProfileList);
