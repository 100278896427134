import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import _ from 'lodash';

import { RECAPTCHA_KEY } from 'config';
import { LO_FORM_SUCCESS_MESSAGE, LO_FORM_ERROR_MESSAGE } from 'Constants';
import TextField from 'components/FormComponents/TextField';
import Select from 'components/FormComponents/Select';
import CheckboxAndRadio from 'components/FormComponents/CheckboxAndRadio';
import { submitAgentContactForm } from 'actions/agent_actions';

const defaultValues = {
  name: '',
  phone: '',
  email: '',
  purpose: '',
  location: '',
  message: '',
  tos: false,
  recaptcha: null,
};

const schemaValidation = yup
  .object({
    name: yup.string().optional(),
    phone: yup.string().optional(),
    email: yup.string().trim().required('Enter Email').email('Invalid Email Address'),
    purpose: yup.string().required('Select an option'),
    location: yup.string().trim().required('Enter Location'),
    message: yup.string().trim().required('Enter Message'),
    tos: yup.boolean().isTrue(),
    recaptcha: yup.string().nullable().trim().required(),
  })
  .required();

function OfficerContactForm({ adsList, agentId }) {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const { status, contactFormSubmitted: formSubmitted } = useSelector(state => state.agentProfile);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    watch,
    trigger,
  } = useForm({ mode: 'onTouched', defaultValues, resolver: yupResolver(schemaValidation) });

  const tosValue = watch('tos');
  const recaptchaValue = watch('recaptcha');
  const purposeValue = watch('purpose');

  useEffect(() => {
    if (purposeValue !== defaultValues.purpose) {
      trigger('purpose');
    }
  }, [purposeValue, trigger]);

  useEffect(() => {
    if (status === 500) {
      navigate('/500');
      return;
    }
    reset();
    setFormSubmitting(false);
  }, [status, formSubmitted, reset, navigate]);

  const onSubmit = data => {
    if (adsList && adsList.attorneys && adsList.attorneys.length !== 0) data.advertisements = adsList.attorneys;
    else data.advertisements = null;
    setFormSubmitting(true);
    dispatch(submitAgentContactForm(agentId, data));
  };

  return (
    <div className='mt36'>
      {formSubmitted === true ? (
        <div className='form-status-msg-container success'>{LO_FORM_SUCCESS_MESSAGE}</div>
      ) : formSubmitted === false ? (
        <div className='form-status-msg-container error'>{LO_FORM_ERROR_MESSAGE}</div>
      ) : null}

      <form onSubmit={handleSubmit(onSubmit)} className='form'>
        <div className='form-row'>
          <TextField
            id='name'
            type='text'
            placeholder='Name'
            {...register('name')}
            errorMessage={errors?.name?.message}
            containerClass='form-item col-3'
          />

          <TextField
            id='phone'
            type='tel'
            placeholder='Phone'
            {...register('phone')}
            errorMessage={errors?.phone?.message}
            containerClass='form-item col-3'
          />

          <TextField
            id='email'
            type='email'
            placeholder='Email*'
            {...register('email')}
            errorMessage={errors?.email?.message}
            containerClass='form-item col-3'
          />
        </div>

        <div className='form-row'>
          <Select
            id='purpose'
            placeholder='Select your option'
            {...register('purpose')}
            options={[
              { value: 'BU', label: 'Buying' },
              { value: 'RE', label: 'Refinance' },
            ]}
            setValue={setValue}
            containerClass='form-item col-3'
            errorMessage={errors?.purpose?.message}
          />

          <TextField
            id='location'
            type='text'
            placeholder='Town or Zip*'
            {...register('location')}
            errorMessage={errors?.location?.message}
            containerClass='form-item col-3'
          />

          <div className='form-item col-3' />
        </div>

        <div className='form-row'>
          <TextField
            id='message'
            type='textarea'
            placeholder='Message*'
            {...register('message')}
            errorMessage={errors?.message?.message}
            containerClass='form-item'
          />
        </div>

        <div className='form-row'>
          <CheckboxAndRadio
            id='tos'
            type='checkbox'
            label={
              <>
                I agree to{' '}
                <NavLink end to='/tos'>
                  Terms of Use
                </NavLink>
              </>
            }
            {...register('tos')}
            containerClass='form-item'
          />
        </div>

        <div className='form-row'>
          <ReCAPTCHA
            sitekey={RECAPTCHA_KEY}
            onChange={value => {
              setValue('recaptcha', value);
            }}
            className='recaptcha'
          />
        </div>

        <div className='form-row'>
          <button
            type='submit'
            disabled={!_.isEmpty(errors) || !recaptchaValue || !tosValue || formSubmitting}
            className='button arrow small'
          >
            Let’s Get Started
          </button>
        </div>
      </form>
    </div>
  );
}

export default OfficerContactForm;
