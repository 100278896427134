import React from 'react';

// images
import stepOne from 'assets/images/how-it-works/step-1.png';
import stepTwo from 'assets/images/how-it-works/step-2.png';
import stepThree from 'assets/images/how-it-works/step-3.png';
import stepFour from 'assets/images/how-it-works/step-4.png';
import stepFive from 'assets/images/how-it-works/step-5.png';
import frame from 'assets/images/how-it-works/frame.jpg';
import couple from 'assets/images/how-it-works/couple-angle.png';
import { NavLink } from 'react-router-dom';

function Content() {
  return (
    <div>
      <section className='process blue-bg pull-up-parent vertical-line how-it-works'>
        <div className='pull-up'>
          <h2>A Quick and Easy Online Process</h2>
          <div className='steps'>
            <div className='steps__step'>
              <img src={stepOne} alt='step 1' />
              <div className='steps-desc'>
                <div className='steps-desc__number'>1.</div>
                <div className='steps-desc__body'>
                  <p>Tell us a little bit about yourself: what are you looking for, and what’s your budget?</p>
                </div>
              </div>
            </div>
            <div className='steps__step'>
              <img src={stepTwo} alt='step 2' />
              <div className='steps-desc'>
                <div className='steps-desc__number'>2.</div>
                <div className='steps-desc__body'>
                  <p>Put our algorithm to work for you; find skilled loan officers in your area.</p>
                </div>
              </div>
            </div>
            <div className='steps__step'>
              <img src={stepThree} alt='step 3' />
              <div className='steps-desc'>
                <div className='steps-desc__number'>3.</div>
                <div className='steps-desc__body'>
                  <p>Browse your personalized search results with our best matches for your plans and budget.</p>
                </div>
              </div>
            </div>
            <div className='steps__step'>
              <img src={stepFour} alt='step 4' />
              <div className='steps-desc'>
                <div className='steps-desc__number'>4.</div>
                <div className='steps-desc__body'>
                  <p>
                    Explore individual loan officer profiles for a look at their unique expertise and contact
                    information.
                  </p>
                </div>
              </div>
            </div>
            <div className='steps__step'>
              <img src={stepFive} alt='step 5' />
              <div className='steps-desc'>
                <div className='steps-desc__number'>5.</div>
                <div className='steps-desc__body'>
                  <p>
                    Contact the loan officer of your choice, learn about loans, get pre-approved, and start searching
                    with confidence.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='img-spacer'>
          <img src={frame} alt='blueprint of home' />
        </div>
        <div className='twoColWrapper bottom-align'>
          <div className='multiColInner'>
            <h2>We Work For You, Not Lenders</h2>
            <p className='intro'>
              Our independent and unbiased loan officer recommendations make us unique in the mortgage industry. We are
              not a paid lead generator for lenders - instead, we’re here to educate consumers and help you find the
              best possible loan officer. It’s kind of our thing.
            </p>
            <p>
              <NavLink end className={({ isActive }) => `button${isActive ? 'active' : ''}`} to='/'>
                Find a loan officer
              </NavLink>
            </p>
          </div>
          <div className='multiColInner'>
            <img src={couple} alt='couple' />
          </div>
        </div>
        <span className='line line--in-content ' />
      </section>
      <section>
        <div className='offset-column padding-top--half'>
          <h2>How We Define Success</h2>
          <p className='intro'>
            If LENDAID helps you
            <NavLink end to='/'>
              {' '}
              connect with a mortgage expert{' '}
            </NavLink>
            who helps you feel supported and prepared (not overwhelmed, confused or duped) we will have met our mission.
          </p>
          <div className='title-aside margin-top--half'>
            <div className='title-aside__title'>
              <h3>Success:</h3>
            </div>
            <div className='title-aside__body'>
              <p>
                You, calling our recommended loan officer with a burning question or a fast approaching deadline
                (typically involving your hard earned dollars), and quickly getting the answer or solution you need.
              </p>
            </div>
          </div>
          <div className='title-aside margin-top'>
            <div className='title-aside__title'>
              <h3>Success:</h3>
            </div>
            <div className='title-aside__body'>
              <p>
                You, making a connection with a top notch loan officer who provides you with the tools, knowledge, and
                peace of mind to navigate the mortgage process with ease - a unique scenario for many homebuyers.
              </p>
            </div>
          </div>
          <div className='title-aside margin-top'>
            <div className='title-aside__title'>
              <h3>Success:</h3>
            </div>
            <div className='title-aside__body'>
              <p>
                You, settling into the home of your dreams with none of the stress, confusion, or worries that set back
                so many borrowers.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Content;
