import { DEFAULT_LOFORM_MESSAGE } from 'Constants';
import logo from 'assets/images/logo.png';

export function getFormattedAddress(address) {
  let formattedAddress = null;
  if (address && address.geo_location) {
    formattedAddress = `${address.geo_location.city}, ${address.geo_location.state}`;
  }

  return formattedAddress;
}

export function getLocationTypeAndTextFromLocalStorage() {
  let locationText = '';
  let locationType = '';

  const search = JSON.parse(localStorage.getItem('searchQuery'));
  if (search) {
    locationText = search.searchText;
    locationType = search.locationType;
  } else {
    locationType = 'Z';
    const location = JSON.parse(localStorage.getItem('location'));
    if (location) locationText = location.postal_code;
    else if (!location || !locationText) {
      if (JSON.parse(localStorage.getItem('ipLocation')))
        locationText = JSON.parse(localStorage.getItem('ipLocation')).postal_code;
    }
  }

  return [locationText, locationType];
}

export function getOfficerMetaInfo(agent) {
  let metaName = `${agent.name}`;
  metaName += agent.nick ? ` (${agent.nick}) | LENDAID` : ' | LENDAID';
  const metaTitle = metaName;

  const address = getFormattedAddress(agent.address);
  let metaDescription = `Read what real borrowers have to say about loan officer ${agent.name}`;
  metaDescription += address ? ` in ${address}` : '';
  metaDescription += '. Ready to talk? Get in touch with a click, no obligation.';

  let logoImage = agent.avatar;
  if (!logoImage) logoImage = logo;

  return [metaTitle, metaDescription, logoImage];
}

export function formInitialValues() {
  const values = {};
  const seachQuery = JSON.parse(localStorage.getItem('searchQuery'));
  const personalInfo = JSON.parse(localStorage.getItem('personalInfo'));
  values.message = DEFAULT_LOFORM_MESSAGE;
  let name = '';
  let email = '';
  if (seachQuery) {
    values.location = seachQuery.searchText;
    if (seachQuery.personalInfo) {
      name = seachQuery.personalInfo.name;
      email = seachQuery.personalInfo.email;
    }
  }
  if (personalInfo && (!name || !email)) {
    name = personalInfo.name;
    email = personalInfo.email;
  }
  values.name = name;
  values.email = email;
  return values;
}

// ReduxForm removes keys with empty values from formData; This disables backend validation
// To prevent this, we manually add removed keys with empty value
export function fillFormDataWithEmptyValues(formData, requiredKeys) {
  requiredKeys.forEach(key => {
    if (!formData[key]) {
      formData[key] = '';
    }
  });
  return formData;
}

export function sanitizeAdiveBody(bodyText, limit = 200) {
  // Html removed
  const body = bodyText.replace(/<\/?[^>]+(>|$)/g, '').slice(0, limit);
  // nbsp removed
  return body.replace(/&nbsp;/g, ' ');
}

export function getFirstName(fullName) {
  return fullName ? fullName.split(' ')[0] : '';
}

export function getFullFormattedAddress(address) {
  let formattedAddress = null;
  if (address && address.geo_location) {
    const streetAddress = address.street1 ? `${address.street1}, ` : '';
    formattedAddress = `${streetAddress}${getFormattedAddress(address)}`;
  }

  return formattedAddress;
}
