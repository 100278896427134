import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Components
import AdviceHeader from 'components/Pages/Advice/AdviceHeader';
import BlogPost from 'components/Pages/Advice/BlogPost';
import Error from 'components/Pages/404';
import Loader from 'components/Common/Loader';

// Contants
import { MONTH_NAMES } from 'Constants';

// Actions
import { fetchAdviceList } from 'actions/advice_actions';
import { sanitizeAdiveBody } from 'actions/utility_actions';

class Advice extends Component {
  constructor(props) {
    super(props);
    this.props.fetchAdviceList();
  }

  UNSAFE_componentWillMount() {
    window.scrollTo(0, 0);
  }

  renderAdvices(adviceList) {
    return _.map(adviceList, adviceArticle => {
      const createdAt = new Date(adviceArticle.created_at);
      return (
        <BlogPost
          key={adviceArticle.id}
          month={MONTH_NAMES[createdAt.getMonth()]}
          date={createdAt.getDate()}
          heading={adviceArticle.title}
          link={`/advice/${adviceArticle.slug}`}
        >
          {sanitizeAdiveBody(adviceArticle.body)}...
        </BlogPost>
      );
    });
  }

  render() {
    // Wrong URL
    if (this.props.adviceList === undefined) return <Error />;

    // Loading
    if (this.props.adviceList === null) {
      return (
        <div className='text-center loader-wrapper'>
          <Loader />
        </div>
      );
    }

    return (
      <div id='advice' className='blue-bg'>
        <AdviceHeader />

        <section className='padding-x padding-bottom'>
          <div className='blog blog--list'>
            {this.props.adviceList.length === 0 ? (
              <h1 className='text-center'> No Advices Found! </h1>
            ) : (
              this.renderAdvices(this.props.adviceList)
            )}
          </div>
        </section>
      </div>
    );
  }
}

function mapStateToProp({ advices }) {
  return {
    adviceList: advices.list,
  };
}

export default connect(mapStateToProp, { fetchAdviceList })(Advice);
