import React from 'react';

// position: "top" | "bottom" | "left" | "right"
function Tooltip({ position, message, style, children }) {
  return (
    <div className={`tooltip ${typeof position === 'string' ? position : ''}`} style={style}>
      {children}
      <span className='tooltip-text'>{message}</span>
    </div>
  );
}

export default Tooltip;
