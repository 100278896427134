import React from 'react';

function PageBanner(props) {
  const { smallHeading, largeHeading, bgImage } = props;

  return (
    <section className='hero vertical-line'>
      <div className='hero__content'>
        <h3>{smallHeading}</h3>
        <h1>{largeHeading}</h1>
      </div>
      <div className='hero__background' style={{ backgroundImage: `url(${bgImage})` }}>
        {' '}
      </div>
      <span className='line' />
    </section>
  );
}

export default PageBanner;
