import React from 'react';
import { NavLink } from 'react-router-dom';

function Refinance() {
  return (
    <section>
      <div className='image-text image-text--reversed blue-bg'>
        <div className='image-text__image'>{/* css background image used */}</div>
        <div className='image-text__text'>
          <h2>Refinancing</h2>
          <p>
            Most people refinance in search of the lowest interest rate, but it’s still helpful to have an experienced
            loan officer to guide you through the process.
          </p>
          <ul className='checklist'>
            <li>Find A Refinance Specialist</li>
            <li>Work With Real People To Get Good Advice</li>
            <li>Place Your Trust in Experience</li>
            <li>Save Money &amp; Time</li>
          </ul>
          <p>
            <NavLink end className='button' to='/rates'>
              learn more
            </NavLink>
          </p>
        </div>
      </div>
    </section>
  );
}

export default Refinance;
