import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import ReduxPromise from 'redux-promise';
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/browser';
import { SENTRY_DSN } from 'config';

// Reducers
import reducers from 'reducers';

// Components
import App from './App';

// Styles
import 'assets/css/styles.css';

// sentry sdk
if (SENTRY_DSN !== 'undefined'){
    Sentry.init({
      dsn: SENTRY_DSN,
    });
}

const createStoreWithMiddleware = applyMiddleware(ReduxPromise, thunk)(createStore);
ReactDOM.hydrate(
  <Provider store={createStoreWithMiddleware(reducers)}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
