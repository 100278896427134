import { LOCATION_DETAILS, IP_LOCATION_DETAILS } from 'actions/action_types';
import { fetchLocationDetailsAPI, getIPLocationDetailsAPI } from 'api';

export function fetchLocationDetails(lat, long) {
  return {
    type: LOCATION_DETAILS,
    payload: fetchLocationDetailsAPI(lat, long),
  };
}

export function getIPLocationDetails() {
  return {
    type: IP_LOCATION_DETAILS,
    payload: getIPLocationDetailsAPI(),
  };
}
