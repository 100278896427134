import { ADVICE_LIST, ADVICE_DETAIL } from 'actions/action_types';

const initialState = { list: null, article: null };

function adviceReducer(state = initialState, action) {
  switch (action.type) {
    case ADVICE_LIST:
      return { ...state, list: action.payload.data };
    case ADVICE_DETAIL:
      return { ...state, article: action.payload.data };
    default:
      return state;
  }
}

export default adviceReducer;
