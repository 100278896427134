import {
  AGENT_SURVEY,
  FETCH_AGENT_PROFILE,
  TOP_RATED_AGENT,
  AGENT_CONTACT_FORM,
  AGENT_TRANSACTIONS,
  AGENT_RATING,
  AGENT_REVIEWS,
  SUBMITTING_SURVEY,
  AGENT_GOOGLE_REVIEWS,
} from 'actions/action_types';
import { SubmissionError } from 'redux-form';
import transformTransactions from 'components/Pages/EmbeddedMap/transformTransactions';

const initialState = {
  profile: null,
  rating: null,
  review: null,
  google_reviews: null,
  transactions: null,
  topOfficer: null,
  contactFormSubmitted: null,
  agentSurveySubmitted: null,
  error: null,
  loading: null,
};
function agentReducer(state = initialState, action) {
  switch (action.type) {
    case SUBMITTING_SURVEY:
      return { ...state, loading: true };
    case AGENT_SURVEY:
      if (action.payload && action.payload.status === 201) {
        return { ...state, submittedSuccess: true, loading: false };
      } else {
        return { ...state, submittedSuccess: false, error: action.payload.data, loading: false };
      }
    case FETCH_AGENT_PROFILE:
      if (action.payload && action.payload.status === 200) {
        return {
          ...state,
          profile: action.payload.data,
          review: action.payload.data.review,
          google_reviews: action.payload.data.google_reviews,
        };
      }
      return { ...state, profile: undefined };
    case AGENT_RATING:
      return { ...state, rating: action.payload.data };
    case AGENT_REVIEWS: {
      const copyState = { ...state };
      if (copyState.review != null) {
        const oldReviews = copyState.review.results;
        action.payload.data.results = [...oldReviews, ...action.payload.data.results];
      }
      return { ...state, review: action.payload.data };
    }
    case AGENT_GOOGLE_REVIEWS: {
      const copyState = { ...state };
      if (copyState.google_reviews != null && action.payload.data.results) {
        const oldReviews = copyState.google_reviews.results;
        action.payload.data.results = [...oldReviews, ...action.payload.data.results];
      }
      if (!action.payload.data) {
        action.payload.data.next = null;
      }
      return { ...state, google_reviews: action.payload.data };
    }
    case AGENT_TRANSACTIONS:
      if (action.payload.response && action.payload.response.status === 404)
        return { ...state, transactions: null, error: 'Officer Not Found' };
      return { ...state, transactions: transformTransactions(action.payload.data) };
    case TOP_RATED_AGENT:
      if (action.payload.response && action.payload.response.status === 404) return { ...state, topOfficer: undefined };
      return { ...state, topOfficer: action.payload.data };
    case AGENT_CONTACT_FORM:
      if (action?.payload?.response?.status === 400 || action?.payload?.response?.status === 401)
        throw new SubmissionError(action.payload.response.data);
      if (action.payload.response && action.payload.response.status === 500)
        return { ...state, contactFormSubmitted: false, status: action.payload.response.status };
      if (action.payload.status === 201) return { ...state, contactFormSubmitted: true };
      return { ...state };
    default:
      return state;
  }
}

export default agentReducer;
