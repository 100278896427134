import React, { Component } from 'react';

import bgImage from 'assets/images/heroes/lendaid-header-about.jpg';

import PageBanner from 'components/Common/PageBanner';
import Intro from 'components/Pages/About/Intro';

class About extends Component {
  UNSAFE_componentWillMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div id='about'>
        {/* Page Banner */}
        <PageBanner smallHeading='ABOUT US' largeHeading='This Is Our Story' bgImage={bgImage} />

        {/* Page Intro */}
        <Intro />
      </div>
    );
  }
}

export default About;
