import React from 'react';

function TOS() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  scrollToTop();

  return (
    <div id='tos'>
      <section className='padding-all'>
        <div className='single-column'>
          <h1 className='no-margin--bottom'>LENDAID Terms of Use</h1>
        </div>
      </section>
      <section className='padding-x padding-bottom'>
        <div className='single-column'>
          <p>
            LENDAID, Inc. <strong>(“LENDAID”)</strong>, has created the LENDAID website at <strong>LENDAID.com</strong>{' '}
            (the <strong>“Website”</strong> or the <strong>“Site”</strong>) to provide an online platform for
            prospective home buyers to find Positively Rated (defined below) Licensed Mortgage Loan Officers{' '}
            <strong>(“MLOs”)</strong> employed at Licensed Mortgage Lenders <strong>(“Lenders”)</strong>.
          </p>
          <p>
            To assist you in using the Website and associated Services, and to ensure a clear understanding of the
            relationship arising from your use of the Website and participation in these Services, we have created (i)
            these Terms and Conditions of Use (the <strong>“Terms”</strong>) and (ii) a Privacy Policy. Our Privacy
            Policy explains how we treat information you provide to us through the Site, and our Terms govern your use
            of our Site and participation in our Services. Our Terms and Privacy Policy apply to any visitor to the
            Website (collectively, <strong>“you”</strong>), including (i) casual visitors to our Site, who do not
            participate in the Services
            <strong> (“Site Visitors”)</strong>, (ii) MLOs and Lenders who register to use the Site{' '}
            <strong>(“Registered Users”)</strong> and (iii) individuals who otherwise participate in the Services{' '}
            <strong>(“Service Users”)</strong>. The terms <strong>“LENDAID,” “we”</strong> and <strong>“us”</strong>
            refer to LENDAID, Inc.
          </p>

          <p className='all-caps'>
            <strong>
              PLEASE READ THIS DOCUMENT CAREFULLY BEFORE YOU ACCESS OR USE THE WEBSITE. BY ACCESSING THE WEBSITE AND
              PARTICIPATING IN THE SERVICES, YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS SET FORTH BELOW. IF YOU
              DO NOT WISH TO BE BOUND BY THESE TERMS AND CONDITIONS, PLEASE DO NOT ACCESS OUR WEBSITE.
            </strong>
          </p>

          <p id='your-agreement'>
            <strong className='heading'>1. Your Agreement. </strong>
            These Terms govern (i) your use of the Website, (ii) your receipt of and participation in LENDAID’s services
            offered through the Website (our <strong>“Services”</strong>), (iii) your provision of information in
            connection with using the Website <strong>(“User Content”)</strong>; and (iv) your use of information
            obtained through the Website, including (a) information, software, artwork, text, video, audio, pictures,
            content, trademarks, trade dress, and other intellectual property owned by LENDAID or its licensors and made
            available to you through the Website
            <strong> (“LENDAID Content”)</strong>. Please read these Terms carefully; they impose legal obligations on
            you and on LENDAID, and establish our legal relationship. By accessing our Website, you are acknowledging
            that you have read and understood these Terms and agree to be legally bound by them.
          </p>

          <p id='our-services-overview'>
            <strong className='heading'>2. Our Services: Overview. </strong>
            The Website is designed to provide an online platform for prospective home buyers to find MLOs that have
            been positively rated based upon the MLO’s volume of loans processed in the geographic area being searched,
            as well as based upon consumer reviews and ratings provided by other Service Users and by prior MLO customer
            reviews — an MLO that has a high volume of loans processed will generally be “Positively Rated,” unless
            Service User reviews are negative for that MLO. Service Users can input the zip code where they desire to
            purchase a property and get the names of highly rated MLOs working in that area. Service Users can also use
            a contact button feature to reach out to an MLO to potentially start the mortgage process. Service Users may
            also be provided the ability to rate and write a review (a <strong>“Review”</strong>) or participate in
            surveys about the Lenders and MLOs that you have worked with, which provides the basis for the ratings of
            the Lenders and MLOs displayed when a Service User queries the Website. Your submission of a Review is
            anonymous and only the town in which you purchased or refinanced a property will be displayed in connection
            with your Review (i.e., we will not include your name in or otherwise publicly associate your name with the
            Review unless you volunteer to provide your name). A Lender or MLO who does not get positive Reviews will
            not be displayed when a search is run. LENDAID IS NOT A LENDER — WE DO NOT OFFER LOANS, PROVIDE LOAN
            COMMITMENTS, WARRANT THE PERFORMANCE OF MLOS, OR NEGOTIATE LENDING TERMS.
          </p>

          <p id='surveys-reviews'>
            <strong className='heading'>2.1. Surveys, Reviews and Ratings. </strong>
            If we provide the functionality for you to provide a Review, survey response or rating, whether via the
            website, email or social media, by submitting a Review, survey response and/or rating an MLO, you represent
            that you are a bona fide purchaser of real estate or referral partner (real estate agent or other
            professional who participated in the purchase of real estate) who actually worked with the individual MLO
            you are Reviewing/rating. You agree to be respectful, factual and professional in submitting Reviews on the
            Website. You are responsible for the content, accuracy and veracity of any Reviews you submit on the
            Website. Registered Users can report Reviews for removal that are not from their customers or referral
            partners, do not include facts, or include vulgarity, as judged using the Code of Conduct. Registered Users
            are not able to manually delete Reviews. As noted in our Privacy Policy, information included in a Review
            becomes public immediately. Accordingly, you should use discretion in providing any information in your
            Review. LENDAID reserves the right not to publish Reviews that it believes violate these Terms. If the
            Review is provided through a third-party service provider, the third party’s privacy policy applies to the
            collection, use, and disclosure of your information.
          </p>

          <p id='mlo-profiles'>
            <strong className='heading'>2.2. MLO Profiles. </strong>
            We rely on Registered Users to update and correct personal information. MLOs may update or correct their
            personal information or close their account at any time by contacting us at{' '}
            <a href='mailto:info@lendaid.com'>info@LENDAID.com</a>. Lenders may also update or correct information on
            their employees’ profiles or delete their employees’ profiles by contacting us. Note that we may keep
            historical information in our backup files as permitted by law. We reserve the right to update and correct
            information at our discretion.
          </p>

          <p id='no-affiliation-endorsement'>
            <strong className='heading'>2.3. No Affiliation or Endorsement; Not a Lender. </strong>
            LENDAID is not affiliated with and does not recommend or endorse any MLO on the Website. MLOs are licensed
            by the government. We recommend that you go to nmlsconsumeraccess.com to verify that the MLO you are
            considering has a license that is up to date, valid for the state in which you are seeking financing, and
            also to obtain the MLO’s most up to date work history and contact information. Your decision to work with
            any MLO is at your own risk. LENDAID is not responsible for and disclaims all liability for the acts or
            omissions of any MLO you choose to work with, regardless of whether such MLO was Positively Rated on the
            LENDAID Website. As noted above, LENDAID is not a Lender – we do not offer loans, provide loan commitments,
            warrant the performance of MLOs, or negotiate lending terms.
          </p>

          <p id='our-privacy-practices'>
            <strong className='heading'>3. Your Consent to Our Privacy Practices. </strong>
            As noted above, our Privacy Policy explains how we treat information you provide to us through the Site.
          </p>

          <br />
          <p id='grant-of-rights'>
            <strong className='heading'>4. Grant of Rights to Users</strong>
          </p>
          <p id='grant-of-rights-to-users'>
            <strong className='heading'>4.1. Grant of Rights to Users. </strong>
            Subject to Your compliance with these Terms, you are granted a limited, non-exclusive, non-transferable,
            worldwide right to access, execute, display, perform, and otherwise use the Website solely for Your personal
            purposes, provided that you shall not (i) license, sublicense, sell, resell, distribute or otherwise
            commercially exploit the LENDAID Website to any third party; (ii) modify or make derivative works based upon
            the LENDAID Website or LENDAID Content; or (iii) reverse engineer, reverse compile, or access the LENDAID
            Website in order to build a competitive product or service.
          </p>

          <p id='duration-of-rights'>
            <strong className='heading'>4.2. Duration of Rights. </strong>
            You will continue to enjoy Your rights under{' '}
            <a href='#grant-of-rights-to-users'>Section 4.1 (Grant of Rights to Users)</a> for as long as you are a
            Service User, unless Your access to the Website is revoked or suspended for misconduct, as set out in
            <a href='#monitoring'> Section 8 (Monitoring; Revocation or Suspension of Use Privileges). </a>
          </p>

          <p id='reservation-of-rights'>
            <strong className='heading'>5. Ownership; Reservation of Rights. </strong>
            The information, software, artwork, text, video, audio, pictures, trademarks, trade dress, and other
            intellectual property embodied in the Website or the LENDAID Content are the proprietary property of LENDAID
            and its licensors, and are protected by U.S. and international copyright and other intellectual property
            laws, or are used under the principles of fair use. LENDAID and its licensors retain all rights with respect
            to the Website, Services and the LENDAID Content except those expressly granted to you in these Terms. You
            agree not to duplicate, publish, display, distribute, modify, or create derivative works from the material
            presented through the Website and/or through the Services unless specifically authorized in writing by
            LENDAID.
          </p>

          <br />
          <p id='service-user-grant'>
            <strong className='heading'>6. LENDAID Grant of Rights </strong>
          </p>
          <p>
            <strong className='heading'>6.1. Grant of Rights to LENDAID in User Content. </strong>
            By submitting User Content, including Reviews, for participation in the Services, uploading files, or
            otherwise (if such features are available to you), you grant LENDAID the right to use, copy, reformat,
            index, modify, display, and distribute your User Content for the purposes of providing our Services. No
            compensation will be paid with respect to LENDAID&#39;s use of your User Content under this grant.
          </p>

          <p id='service-user-comments'>
            <strong className='heading'>6.2. User Comments/Feedback. </strong>
            Our Website may allow users to provide comments or feedback regarding our Website and our Services. By
            providing comments/feedback, you grant us the right to use your comments and feedback for the purposes of
            improving the Website and our Services.
          </p>

          <p id='permissions-you-must-have'>
            <strong className='heading'>6.3. Rights/Permissions You Must Have in Your Submitted Content. </strong>
            In submitting User Content, you warrant that you have sufficient authority and rights to post such Content,
            and provide these use rights.
          </p>

          <p id='decline-submitted-content'>
            <strong className='heading'>6.4. Right to Decline Submitted Content. </strong>
            We expressly reserve the right to refuse to use (or to disable) any User Content that we conclude, in our
            sole discretion, violates these Terms or our Privacy Policy, or is incompatible with the purposes of the
            Website.
          </p>

          <p id='code-of-conduct'>
            <strong className='heading'>7. Code of Conduct. </strong>
            AS A CONDITION TO YOUR USE OF THE WEBSITE AND THE SERVICES, YOU AGREE TO FOLLOW OUR CODE OF CONDUCT, SET OUT
            BELOW. Under this Code, you will not:
          </p>

          <ul className='point-list'>
            <li>
              Submit a Review or survey response that you know to be false or inaccurate or that is not based upon your
              own personal experience working with the individual MLO that is the subject of the Review.
            </li>
            <li>
              Upload, email or otherwise transmit any images or other User Content that is unlawful, obscene, harmful,
              hateful, invade the privacy of any third party, contain nudity or pornography, or are otherwise
              objectionable.
            </li>
            <li>
              Disseminate materials that impact or invade the privacy of others, such as photographs, video clips, sound
              recordings, personally identifiable information, or other materials that reveal personal, private or
              sensitive information about another person, without that person’s consent.
            </li>
            <li>
              Submit material that is intentionally false, defamatory, unlawfully threatening, or unlawfully harassing.
            </li>
            <li>
              Infringe any third party&#39;s copyright, patent, trademark, trade secret, or other proprietary rights or
              rights of publicity or privacy. Electronic materials — such as music, videos, games, images, and text in
              electronic form — can easily be copied, modified and sent over networks (such as the Internet). These
              electronic materials are thus extremely vulnerable to unauthorized distribution and copyright
              infringement. These materials may not be transmitted over the Website without the copyright owner&#39;s
              permission, or without a legitimate “fair use” justification for the transmittal.
            </li>
            <li>
              Transmit materials that contain any viruses, Trojan horses, worms, time bombs, cancel bots, or other
              computer-programming routines that are intended to damage, detrimentally interfere with, surreptitiously
              intercept, or expropriate any system, data, or personal information.
            </li>
            <li>
              Use the Website to artificially generate traffic or page links to a website or for any other purpose not
              expressly allowed under these Terms.
            </li>
            <li>
              Use the Website in a manner that could disable, overburden, or impair the Website or Services or interfere
              with any other party&#39;s use and enjoyment of the Website and Services, such as through sending “spam”
              email.
            </li>
            <li>
              Use the Website to test or reverse engineer the Website in order to find limitations, vulnerabilities or
              to evade filtering capabilities.
            </li>
            <li>
              Seek to obtain access to any materials or information through “hacking”, “data harvesting”, or through
              other means we have not intentionally made available to you through the Website.
            </li>
            <li>
              Use the Website for any purpose that is unlawful or prohibited by these Terms. For example, you will not
              use the Website to violate any law, statute, or regulation (including, without limitation, those governing
              export control, consumer protection, unfair competition, anti-discrimination, or false advertising).
            </li>
          </ul>

          <p id='monitoring'>
            <strong className='heading'>8. Monitoring; Revocation or Suspension of Use Privileges. </strong>
            We reserve the right at any time to (i) monitor your use of the Website, and (ii) terminate or suspend your
            use of some or all of the Services if you engage in activities that we conclude, in our discretion, breach
            our Code of Conduct or otherwise violate these Terms or our Privacy Policy.
          </p>
          <p>
            Although we have no — and assume no — obligation to monitor activities on the Website, please understand
            that we may employ filters designed to detect and block inappropriate content under this Code of Conduct. We
            reserve the right to request edits to remove any information or materials, in whole or in part, that we
            believe, in our sole discretion, are incompatible with our Code of Conduct. IF YOU DO NOT REMOVE
            OBJECTIONABLE CONTENT IN RESPONSE TO OUR REASONABLE REQUESTS, WE WILL TERMINATE YOUR USE OF SOME OR ALL OF
            THE LENDAID WEBSITE OR SERVICES AT ISSUE.
          </p>
          <p>
            Users should also understand that our Code of Conduct is based in many instances on principles of applicable
            law. Users who violate our Code of Conduct accordingly may be exposed under these laws to criminal charges,
            and civil liability to harmed parties for compensatory damages and attorney&#39;s fees. LENDAID reserves the
            right at all times to disclose information it deems necessary to satisfy any applicable law, regulation,
            legal process, or governmental request, consistent with its Privacy Policy.
          </p>

          <p id='reports-and-complaints'>
            <strong className='heading'>9. Reports and Complaints. </strong>
            If you believe that a user has acted inappropriately, such as by violating our Code of Conduct, you may
            report your concerns either via the links we have included on the Website, or by contacting us in accordance
            with <a href='#contact-us'> Section 21 (Contact Us)</a>. If we are notified by a user that he/she believes
            User Content on the Website does not comply with our Code of Conduct, we will investigate the allegation and
            determine in good faith, in our sole discretion, whether to remove or block access to such Content, or to
            take action with respect to the person or persons responsible for posting the Content.
          </p>

          <p id='third-party-sites'>
            <strong className='heading'>10. Links to Third-Party Sites. </strong>
            The Website may also contain links or produce search results that reference links to third-party websites
            (collectively <strong>“Linked Sites”</strong>). LENDAID has no control over these Linked Sites or their
            content and does not assume responsibility or liability for any content, opinions, or materials available on
            Linked Sites. LENDAID does not endorse the content of any Linked Site, nor does LENDAID warrant that a
            Linked Site will be free of computer viruses or other harmful code that can impact your computer or other
            web-access device. By using the Website to search for or link to another site, you agree and understand that
            such use is at your own risk.
          </p>

          <p id='user-conduct'>
            <strong className='heading'>11. User Conduct; User Disputes. </strong>
            LENDAID is not responsible for and is not liable for the User Content or conduct of users. You are solely
            responsible for your User Content, conduct, and interaction with other Service Users, both online and
            offline. We have no obligation to become involved in disputes between Service Users. If you have a dispute
            with one or more Service Users, you release LENDAID (and our officers, directors, agents, employees,
            subsidiaries, and affiliates) from claims, demands, and damages (actual and consequential) of every kind and
            nature, known and unknown, arising out of or in any way connected with such dispute.
          </p>
          <p id='warranty-disclaimer'>
            <strong className='heading'>12. Warranty Disclaimer. </strong>
            <span className='all-caps'>
              LENDAID DOES NOT PROMISE THAT THE WEBSITE OR SERVICES WILL BE ERROR-FREE OR UNINTERRUPTED, OR THAT THE
              WEBSITE WILL PROVIDE SPECIFIC RESULTS FROM YOUR USE OF THE WEBSITE OR PARTICIPATION IN THE SERVICES OR
              YOUR USE OF ANY CONTENT, SEARCH, OR LINK ON IT. THE WEBSITE AND ALL SERVICES AND CONTENT WITHIN IT ARE
              DELIVERED ON AN “AS IS” AND “AS AVAILABLE” BASIS. WHEN YOU ACCESS THE WEBSITE, YOU DO SO AT YOUR OWN RISK.
              LENDAID DOES NOT WARRANT OR REPRESENT THAT MATERIALS YOU DOWNLOAD FROM LENDAID SITE WILL BE FREE OF
              VIRUSES OR OTHER HARMFUL FEATURES.
            </span>
          </p>

          <p className='all-caps'>
            LENDAID DOES NOT WARRANT THAT ANY MLO RATED ON THE WEBSITE WILL MEET YOUR NEEDS OR OTHERWISE PROVIDE
            SERVICES TO YOU IN A MANNER THAT MEETS YOUR NEEDS. WHEN YOU CHOOSE TO WORK WITH AN MLO RATED ON THE WEBSITE,
            YOU DO SO AT YOUR OWN RISK.
          </p>
          <p className='all-caps'>
            WHILE LENDAID WILL USE REASONABLE PRECAUTIONS IN MONITORING REVIEWS, LENDAID IS NOT RESPONSIBLE FOR THE
            CONTENT, ACCURACY, VERACITY, OR AUTHENTICITY OF REVIEWS AND FULLY DISCLAIMS ANY RESPONSIBILITY OR LIABILITY
            FOR THE CONTENT OF REVIEWS SUBMITTED BY SERVICE USERS ON THE WEBSITE.
          </p>
          <p className='all-caps'>
            LENDAID DISCLAIMS (I) ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY IMPLIED WARRANTIES OF
            MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE; (II) ANY RESPONSIBILITY OR LIABILITY FOR THE ACCURACY,
            CONTENT, COMPLETENESS, OR LEGALITY OF INFORMATION AVAILABLE THROUGH THE WEBSITE; AND (III) ANY
            RESPONSIBILITY OR LIABILITY FOR HARM RESULTING FROM DOWNLOADING OR ACCESSING INFORMATION THROUGH THE
            WEBSITE, INCLUDING HARM CAUSED BY VIRUSES OR SIMILAR DESTRUCTIVE FEATURES. YOU EXPRESSLY AGREE THAT USE OF
            THE LENDAID WEBSITE AND RELATED SERVICES AND CONTENT IS AT YOUR SOLE RISK.
          </p>

          <p id='limitation-of-liability'>
            <strong className='heading'>13. Limitation of Liability. </strong>
            <span className='all-caps'>
              UNDER NO CIRCUMSTANCES, INCLUDING BUT NOT LIMITED TO BREACH OF CONTRACT, TORT, OR NEGLIGENCE, WILL LENDAID
              BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL DAMAGES
              (INCLUDING LOST PROFITS) THAT ARISE OUT OF OR ARE RELATED TO YOUR USE OF THE LENDAID WEBSITE AND RELATED
              SERVICES AND CONTENT.
            </span>
          </p>

          <p id='indemnity'>
            <strong className='heading'>14. Indemnity. </strong>
            You agree to defend, indemnify, and hold LENDAID and its subsidiaries, affiliates, officers, directors,
            agents, and employees harmless from any liability to third parties, including reasonable attorneys&#39;
            fees, arising from or related to your breach of these Terms.
          </p>

          <p id='copyright-infringement'>
            <strong className='heading'>15. Contact for Alleged Copyright Infringement. </strong>
            LENDAID respects the intellectual property rights of others and requires that its users do the same. If you
            believe that Content on the Website or other activity taking place on the Website constitutes infringement
            of a work protected by copyright (a <strong>“Work”</strong>), please notify our agent, designated under the
            Digital Millennium Copyright Act (17 U.S.C. §512) (the <strong>“DMCA”</strong>) to respond to such concerns,
            as follows:
          </p>
          <p>
            <a href='mailto:info@LENDAID.com'>
              <strong>info@LENDAID.com</strong>
            </a>
          </p>
          <p>
            Your notice must comply with the DMCA. Upon receipt of a compliant notice, we will respond and proceed in
            accordance with the DMCA.
          </p>

          <p id='modifications-terms'>
            <strong className='heading'>16. Modifications to these Terms. </strong>
            We may modify and change these Terms over time. We will not “retroactively” change these Terms, and any
            modifications we make shall take effect proactively, once you next access the Website. Please feel free to
            print out a copy of these Terms for your records.
          </p>

          <p id='assignment'>
            <strong className='heading'>17. Assignment. </strong>
            These Terms shall not be assignable by you, either in whole or in part. LENDAID reserves the right to assign
            its rights and obligations under these Terms.
          </p>

          <p id='general'>
            <strong className='heading'>18. General. </strong>
            These Terms shall be governed in all respects by the laws of the Commonwealth of Massachusetts without
            giving effect to its conflicts of law provisions. Both parties submit to the personal jurisdiction of and
            venue in the state and federal courts in the Commonwealth of Massachusetts in the judicial district where
            LENDAID has its principal place of business. The parties further agree that any cause of action arising
            under these Terms or our Privacy Policy shall exclusively be brought in such courts. If any provision of
            these Terms is held to be invalid or unenforceable, such provision shall be struck and the remaining
            provisions shall be enforced. Headings are for reference purposes only and in no way define, limit,
            construe, or describe the scope or extent of such section. LENDAID’s failure to act with respect to a breach
            by you or others does not waive its right to act with respect to subsequent or similar breaches. This
            agreement and the terms and conditions contained herein set forth the entire understanding and agreement
            between LENDAID and you with respect to the subject matter hereof and supersede any prior or contemporaneous
            understanding, whether written or oral.
          </p>

          <p id='survival'>
            <strong className='heading'>19. Survival. </strong>
            The following provisions shall survive the termination of these Terms and shall apply indefinitely:
          </p>

          <ul className='point-list'>
            <li>
              <a href='#reservation-of-rights'>Section 5</a> (Ownership; Reservation of Rights)
            </li>
            <li>
              <a href='#warranty-disclaimer'>Section 12</a> (Warranty Disclaimer)
            </li>
            <li>
              <a href='#limitation-of-liability'>Section 13</a> (Limitation of Liability)
            </li>
            <li>
              <a href='#indemnity'>Section 14</a> (Indemnity)
            </li>
            <li>
              <a href='#assignment'>Section 17</a> (Assignment)
            </li>
            <li>
              <a href='#general'>Section 18</a> (General)
            </li>
            <li>
              <a href='#survival'>Section 19</a> (Survival)
            </li>
          </ul>

          <p id='relationship-privacy-policy'>
            <strong className='heading'>20. Relationship to Privacy Policy and Other Contracts. </strong>
            These Terms must be read in conjunction (i) with other agreements into which you may enter concerning the
            Website or our Services (if any), and (ii) with our Privacy Policy. The provisions of our Privacy Policy are
            incorporated herein. To the extent these Terms conflict with the terms of our Privacy Policy, the terms of
            our Privacy Policy will control. Similarly, to the extent these Terms conflict with the terms and conditions
            of any specific agreement you enter with us, the terms and conditions of such specific agreement will
            control.
          </p>

          <p id='contact-us'>
            <strong className='heading'>21. Contact Us.</strong>
            If you have any questions about these Terms, the practices of this Site, or your dealings with this Website,
            please contact us at:
          </p>
          <p>
            <a href='mailto:info@LENDAID.com'>
              <strong>info@LENDAID.com</strong>
            </a>
          </p>

          <p id='effective-date'>
            <strong>Effective Date:</strong>
            The effective date of these Terms of Use is August 30, 2022.
          </p>
          <p>
            <strong className='all-caps'>COPYRIGHT AND LEGAL NOTICE.</strong>
            Copyright © 2017 LENDAID LLC. All Rights Reserved.
          </p>
        </div>
      </section>
    </div>
  );
}

export default TOS;
