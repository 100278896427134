import React from 'react';

function Intro() {
  return (
    <section className='margin-top'>
      <div className='offset-column'>
        <p className='intro'>
          Buying a home is a big investment, and one of the most important and complex decisions you’ll ever make. We
          can help you with the mortgage piece of the puzzle by matching you with a qualified, compassionate (and human)
          loan officer to guide you.
        </p>
        <p>
          Working with the right loan officer can take the guess work out of the mortgage process, and having a strong
          and knowledgeable advocate can be the difference between winning and losing the contract on your dream home.
        </p>
        <p>
          Our algorithm pairs honest reviews with real data to direct you toward the best-in-class mortgage experts in
          your community.
        </p>
      </div>
    </section>
  );
}

export default Intro;
