import React from 'react';

import PropertySearch from 'components/Common/PropertySearch';

function HeroBanner() {
  return (
    <section className='hero hero--full-size hero--homepage'>
      <div className='hero__content'>
        <h1 className='white'>The First Step To Home Ownership</h1>
        <h2 className='white'>CONNECT WITH A TOP RATED LOAN OFFICER</h2>
        <h3 className='white'>free advice</h3>
        <h3 className='white'>unbiased reviews</h3>

        {/* Search Form */}
        <PropertySearch className='using-top' />
      </div>
    </section>
  );
}

export default HeroBanner;
