import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { fetchAgentTransactions } from 'actions/agent_actions';
import Map from 'components/Pages/EmbeddedMap/Map';

function EmbeddedMap() {
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const { nameSlug } = params;
    const idWithLender = nameSlug.split('-').slice(-1)[0];
    const id = idWithLender.replace(/[A-Z]/g, '');
    dispatch(fetchAgentTransactions(id));
  }, [dispatch, params]);

  return <Map isEmbedded />;
}

export default EmbeddedMap;
