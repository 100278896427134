import { LENDER_PROFILE } from 'actions/action_types';
import { searchLendersAgentAPI } from 'api';

/* eslint-disable import/prefer-default-export */
export function searchLendersAgent(nameSlug) {
  return {
    type: LENDER_PROFILE,
    payload: searchLendersAgentAPI(nameSlug),
  };
}
/* eslint-enable import/prefer-default-export */
