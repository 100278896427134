import { LOCATION_DETAILS, IP_LOCATION_DETAILS } from 'actions/action_types';

const initialState = { locationMap: null };
function geolocationReducer(state = initialState, action) {
  const savedLocation = JSON.parse(localStorage.getItem('location'));
  const storableLocation = {};
  switch (action.type) {
    case LOCATION_DETAILS: {
      storableLocation.source = 'html5';
      const { results } = action.payload.data;
      if (!results) return { locationMap: storableLocation };

      for (let ac = 0; ac < results[0].address_components.length; ac++) {
        const component = results[0].address_components[ac];
        switch (component.types[0]) {
          case 'locality':
            storableLocation.city = component.long_name;
            break;
          case 'administrative_area_level_1':
            storableLocation.state = component.long_name;
            storableLocation.iso_state = component.short_name;
            break;
          case 'country':
            storableLocation.country = component.long_name;
            break;
          case 'postal_code':
            storableLocation.postal_code = component.long_name;
            break;
          default:
            break;
        }
      }
      if (storableLocation.country !== 'United States') return { ...state };
      localStorage.setItem('location', JSON.stringify(storableLocation));
      return { locationMap: storableLocation };
    }
    case IP_LOCATION_DETAILS:
      if (action.payload.data && !action.payload.data.error) {
        storableLocation.city = action.payload.data.city;
        storableLocation.state = action.payload.data.region;
        storableLocation.iso_state = action.payload.data.region_code;
        storableLocation.country = action.payload.data.country_name;
        storableLocation.postal_code = action.payload.data.postal;
        storableLocation.coordinates = { lat: action.payload.data.latitude, long: action.payload.data.longitude };
        storableLocation.source = 'ip';
        localStorage.setItem('ipLocation', JSON.stringify(storableLocation));

        if (storableLocation.country !== 'United States') return { ...state };
        if (savedLocation && savedLocation.source === 'html5') {
          return { ...state };
        } else {
          localStorage.setItem('location', JSON.stringify(storableLocation));
          return { locationMap: storableLocation };
        }
      }

      return { ...state };

    default:
      return state;
  }
}

export default geolocationReducer;
