import React from 'react';
import { Outlet } from 'react-router-dom';

import Header from 'components/layouts/BaseLayout/Header';
import Footer from 'components/Footer/Footer';

/**
 * BaseLayout to prevent re-renders of Header and Footer while navigating inside the app.
 * @returns FunctionalComponent
 */
function BaseLayout() {
  return (
    <>
      <Header />

      <Outlet />

      <Footer />
    </>
  );
}

export default BaseLayout;
