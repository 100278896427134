import React from 'react';

import buyingImage from 'assets/images/buying/buying-in-page.jpg';

function Content() {
  return (
    <div>
      <section className='padding-x'>
        <img src={buyingImage} className='full-width' alt='Couple shaking hands' />
      </section>
      {/* numbered list */}
      <section className='padding-all'>
        <div className='single-column'>
          <h2>What is LENDAID?</h2>
          <p>
            LENDAID is a free service that connects you with top rated mortgage loan officers. Our purpose is to provide
            free, confidential, expert advice to consumers to help navigate the difficult and complex process of
            financing a home.
          </p>
          <h5 className='margin-top--half'>LENDAID is here to:</h5>
          <ul className='checklist'>
            <li>Make the home buying process easier and more transparent,</li>
            <li>Help future homebuyers and homeowners make better decisions,</li>
            <li>Provide the latest information on mortgage products, service and customer reviews,</li>
            <li>Pass along unfiltered, unbiased and data driven loan officer recommendations,</li>
            <li>And maintain a position of autonomy and independence to benefit consumers.</li>
          </ul>
          <p>LENDAID is not a mortgage lender. We do not offer loans and cannot make or offer specific loan terms.</p>
        </div>
      </section>
    </div>
  );
}

export default Content;
