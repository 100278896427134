import { OPEN_REPORT_REVIEW_MODAL, CLOSE_REPORT_REVIEW_MODAL } from 'actions/action_types';

export function openReportReviewModal(reviewId) {
  return {
    type: OPEN_REPORT_REVIEW_MODAL,
    payload: reviewId,
  };
}

export function closeReportReviewModal() {
  return {
    type: CLOSE_REPORT_REVIEW_MODAL,
  };
}
