import React from 'react';
import { NavLink } from 'react-router-dom';

function OfficerSearchLegalTerm() {
  return (
    <div className='search-legal-term'>
      LENDAID does not recommend or endorse any lender or loan officer. LENDAID displays loan officers based upon a
      number of factors including lending activity, customer reviews and other data. For more information visit our{' '}
      <NavLink end to='/tos'>
        Terms of Use
      </NavLink>{' '}
      and{' '}
      <NavLink end to='/privacy'>
        Privacy
      </NavLink>
      .
    </div>
  );
}

export default OfficerSearchLegalTerm;
