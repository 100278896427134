import { ADVICE_LIST, ADVICE_DETAIL } from 'actions/action_types';
import { adviceArticleDetailAPI, fetchAdviceListAPI } from 'api';

export function fetchAdviceList() {
  return {
    type: ADVICE_LIST,
    payload: fetchAdviceListAPI(),
  };
}

export function adviceArticleDetail(slug) {
  return {
    type: ADVICE_DETAIL,
    payload: adviceArticleDetailAPI(slug),
  };
}
