import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class Error500 extends Component {
  UNSAFE_componentWillMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div id='error'>
        <section className='padding-x padding-bottom margin-top'>
          <div className='container mt150'>
            <div className='text-center' aria-label='Error' role='main'>
              <h1>500 Error</h1>
              <p>Sorry, Something went wrong on server. Try again later.</p>
              <ul className='button-holder'>
                <li>
                  <NavLink end className={({ isActive }) => `button${isActive ? 'active' : ''}`} to='/'>
                    Back To Homepage
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Error500;
