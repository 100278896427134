import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropertySearch from 'components/Common/PropertySearch';

class Intro extends Component {
  constructor(props) {
    super(props);
    const locationMap = JSON.parse(localStorage.getItem('location'));
    this.state = {
      searchText: Intro.queryText(locationMap),
    };
    this.onInputChange = this.onInputChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.locationMap !== this.props.locationMap) {
      const { locationMap } = nextProps;
      this.setState({ searchText: Intro.queryText(locationMap) });
    }
  }

  static queryText(locationMap) {
    if (!locationMap) return null;
    return locationMap.postal_code ? locationMap.postal_code : `${locationMap.city} - ${locationMap.state}`;
  }

  onInputChange(query) {
    this.setState({ searchText: query });
  }

  render() {
    return (
      <section className='margin-top'>
        <div className='offset-column'>
          <h2>We Can Connect You</h2>
          <p>
            Finding a best-in-class, personalized loan officer to work with is a great first step in the home buying
            process. Your “loan officer” is your liason to the lender.
          </p>
          <h5 className='margin-top--half'>He or she will:</h5>
          <ul className='checklist'>
            <li>Crunch the numbers for you on qualifying,</li>
            <li>Issue a prequalification letter to strengthen an offer to purchase property</li>
            <li>Educate you on loan programs and rates, as well as required documentation</li>
            <li>Watch your contract dates to ensure you meet them, and</li>
            <li>Address every question or concern you have with compassion and understanding.</li>
          </ul>
          <p>
            We connect you with the best kind of loan officers -- the local, human sort, not a virtual loan officer
            delivered via an internet connection.
          </p>
          <div className='dark-green-bg rounded-corners padding-inside-block margin-top--half'>
            <h3 className='white'>Find Your best-in-class LOAN OFFICER</h3>
            <PropertySearch className='using-top' transType='BU' />
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProp({ locationMap }) {
  return { locationMap: locationMap.locationMap };
}

export default connect(mapStateToProp)(Intro);
