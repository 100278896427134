import React, { forwardRef } from 'react';
import classNames from 'classnames';

function Input(props, ref) {
  const { className, ...inputProps } = props;
  const inputClass = classNames('input', className);
  return <input className={inputClass} {...inputProps} ref={ref} />;
}

export default forwardRef(Input);
