import React from 'react';

// TODO: Fix obsolete frameborder
function YoutubeVideo(props) {
  return (
    <section className='blue-bg padding-all'>
      <div className='youtube-video'>
        <iframe
          className='video-player'
          allowFullScreen='allowFullScreen'
          title='LENDAID - Mortgage Smarter'
          src={`https://www.youtube.com/embed/${props.videoUrl}?rel=0;ecver=1&amp;showinfo=0&amp;autohide=2&amp;`}
          allowtransparency='true'
          frameBorder='0'
        />
      </div>
    </section>
  );
}

export default YoutubeVideo;
