import React from 'react';

import buyingImage from 'assets/images/buying/buying-in-page.jpg';

function Content() {
  return (
    <div>
      <section className='padding-x'>
        <img src={buyingImage} className='full-width' alt='Couple shaking hands' />
      </section>
      {/* numbered list */}
      <section className='padding-all'>
        <div className='single-column'>
          <h2>Why Partner With A Loan Officer Early in The Home Buying Process?</h2>
          <ol className='numbered-list'>
            <li>
              <h4>Save time in your home search</h4>
              <p>Know what you can afford and eliminate guesswork; a loan officer can prequalify you.</p>
            </li>
            <li>
              <h4>Negotiate with strength</h4>
              <p>A loan officer can provide a pre-approval letter to present with your Offer to Purchase.</p>
            </li>
            <li>
              <h4>Knowledge is power</h4>
              <p>
                Get educated on various loan programs and eligibility requirements before jumping into the mortgage
                process.
              </p>
            </li>
            <li>
              <h4>Save Money</h4>
              <p>
                Loan officers can help you understand the costs of borrowing and will push for a competitive rate you
                can lock in.
              </p>
            </li>
            <li>
              <h4>Eliminate Headaches</h4>
              <p>Get expert mortgage advice early and purchase your dream home with confidence. </p>
            </li>
          </ol>
          <p className='margin-top--half'>
            When a contract deadline is fast approaching (with your hard-earned money hanging in the balance) and you
            still haven’t heard from your lender with a loan approval, you want a seasoned and compassionate live loan
            officer to step in and take action. We make finding the right loan officer on your terms fast and simple.
          </p>
        </div>
      </section>
    </div>
  );
}

export default Content;
