import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropertySearch from 'components/Common/PropertySearch';

class Intro extends Component {
  constructor(props) {
    super(props);
    const locationMap = JSON.parse(localStorage.getItem('location'));
    this.state = {
      searchText: Intro.queryText(locationMap),
    };
    this.onInputChange = this.onInputChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.locationMap !== this.props.locationMap) {
      const { locationMap } = nextProps;
      this.setState({ searchText: Intro.queryText(locationMap) });
    }
  }

  static queryText(locationMap) {
    if (!locationMap) return null;
    return locationMap.postal_code ? locationMap.postal_code : `${locationMap.city} - ${locationMap.state}`;
  }

  onInputChange(query) {
    this.setState({ searchText: query });
  }

  render() {
    return (
      <section className='margin-top'>
        <div id='shop-for-rates' className='offset-column'>
          <h2>Our Advice on Interest Rates</h2>
          <ol className='numbered-list'>
            <li>
              <h4>Most mortage rates come from the same place.</h4>
              <p>
                As a result rates do not differ that much from one lender to another. Most rate differences reflect the
                various lender business models.
              </p>
            </li>
            <li>
              <h4>Rate is only part of the story.</h4>
              <p>
                At LENDAID we think your priority should be finding the best loan officer. Mortgages are complicated and
                buying a home is a big deal; lender service and experience is critical.
              </p>
            </li>
            <li>
              <h4>Beware of an extremely low rate.</h4>
              <p>
                Securing a good rate is important but not worth it if the lender cuts corners. Do your homework and
                proceed with caution. A rate below the competition may result in poor service, hidden fees or worse. A
                missed closing date could wipe out the savings of a low rate very quickly. At LENDAID we review the
                closed loan production of our participating lenders. Our honest and unbiased process lets you proceed
                with confidence in this most important purchase.
              </p>
            </li>
          </ol>
          <p className='margin-top--half'>
            A good loan officer can save you money and time with good advice and service. A poor loan officer or company
            can cost you thousands in bad advice and expensive delays.
          </p>

          <div className='dark-green-bg rounded-corners padding-inside-block margin-top--half'>
            <h3 className='white'>Find Your best-in-class LOAN OFFICER</h3>
            <PropertySearch className='using-top' transType='RE' />
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProp({ locationMap }) {
  return {
    locationMap: locationMap.locationMap,
  };
}

export default connect(mapStateToProp)(Intro);
