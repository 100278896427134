import { SEARCH_AGENTS, SEARCH_AUTOCOMPLETE_QUERY, SEARCHING_AGENTS } from 'actions/action_types';

const initialState = { agentsList: null, autoCompleteList: null, fetching: false, forbidden: false };
function searchReducer(state = initialState, action) {
  switch (action.type) {
    case SEARCHING_AGENTS:
      return { agentsList: null, autoCompleteList: null, fetching: true };
    case SEARCH_AGENTS:
      if (action.payload.response && action.payload.response.status === 403)
        return { ...state, fetching: false, forbidden: true };
      if (action.payload.response && action.payload.response.status === 500)
        return { ...state, agentsList: undefined, fetching: false, forbidden: false };
      if (action.payload.response && (action.payload.response.status === 404 || action.payload.response.status === 400))
        return { ...state, agentsList: [], fetching: false, forbidden: false, locationError: true };
      return { ...state, agentsList: action.payload.data, fetching: false, forbidden: false };
    case SEARCH_AUTOCOMPLETE_QUERY:
      return { ...state, autoCompleteList: action.payload.data };
    default:
      return state;
  }
}

export default searchReducer;
