import React from 'react';

function Intro() {
  return (
    <div className='single-column'>
      <h2>A Fresh Addition...</h2>
      <p>
        A fresh addition to the online space, LENDAID is the best first step for homebuyers needing a mortgage.
        Shockingly a loan officer recommendation based on unbiased data and 100% honest reviews does not currently exist
        in the marketplace.
      </p>
      <p>
        We are on a mission to provide this needed service, connecting today’s large population of online consumers with
        genuinely skilled mortgage professionals in their community.
      </p>
      <br />

      <h4>Our Target Audience</h4>
      <ul className='checklist'>
        <li>The Online Shopper, Researcher and Dreamer</li>
        <li>The Home Buyer Needing a Mortgage </li>
        <li>The Homeowner Needing to Refinance </li>
        <li>The Individual Dreaming About Buying a Home</li>
      </ul>

      <br />
      <h4>Our Mission</h4>
      <p>
        Unbiased, independent, service, top rated, local, experienced, skill matters, customer care, honesty, consumer
        value, human to human, expert, professional
      </p>

      <br />
      <h4>Advertising Offer</h4>
      <p>Locally targeted, exclusive banner ad space at a competitive rate</p>

      <p>
        If any of the above resonates with you, and you seek increased online exposure aligned with a message of service
        and integrity, please reach out to us at
        <a href='mailto:advertising@lendaid.com'> advertising@lendaid.com</a>
      </p>
      <p>Thank you for your interest.</p>
      <p>The LENDAID Team</p>
    </div>
  );
}

export default Intro;
