import { NavLink } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import * as yup from 'yup';
import _ from 'lodash';
import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import RatingInput from 'components/FormComponents/Rating';
import CheckboxAndRadio from 'components/FormComponents/CheckboxAndRadio';
import TextField from 'components/FormComponents/TextField';
import { RECAPTCHA_KEY } from 'config';

const defaultValues = {
  borrower: '',
  loan_number: '',
  account_executive_points: null,
  underwriting_time_points: null,
  underwriting_comm_points: null,
  closing_time_points: null,
  closing_comm_points: null,
  ease_of_use_points: null,
  overall_points: null,
  comments: '',
  tos: false,
  recaptcha: null,
};

const schemaValidation = yup
  .object({
    borrower: yup.string().required('Name Required.'),
    loan_number: yup.string(),
    account_executive_points: yup.number().nullable(),
    underwriting_time_points: yup.number().nullable(),
    underwriting_comm_points: yup.number().nullable(),
    closing_time_points: yup.number().nullable(),
    closing_comm_points: yup.number().nullable(),
    ease_of_use_points: yup.number().nullable(),
    overall_points: yup.number().nullable(),
    comments: yup.string(),
    tos: yup.boolean().isTrue(),
    recaptcha: yup.string().nullable().trim().required(),
  })
  .required();

function TpoGoSurveyForm({ officer, agentProfile, onSubmit }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    watch,
  } = useForm({
    mode: 'onTouched',
    defaultValues,
    resolver: yupResolver(schemaValidation),
  });

  const tosValue = watch('tos');
  const recaptchaValue = watch('recaptcha');

  return (
    <div>
      {agentProfile.submittedSuccess === true ? (
        <div className='survey-success'>
          <h3>Thanks for your review!</h3>
          <p>
            On behalf of {officer.name} and TPO Go, thank you for sharing your experience with the TPO Go wholesale
            lending process.
          </p>
        </div>
      ) : (
        <div>
          <h2>TPO Go Review</h2>
          <p>
            On behalf of {officer.name} and TPO Go, thank you in advance for sharing your experience with the TPO Go
            wholesale lending process.
          </p>

          <br />
          <br />

          <h3>OUR ONE MINUTE SURVEY</h3>
          <form onSubmit={handleSubmit(onSubmit)} className='form'>
            <div className='form-row'>
              <RatingInput
                id='account_executive_points'
                label='Please rate your experience with your Account Executive.'
                helpText='(1=very dissatisfied, 5=very satisfied)'
                {...register('account_executive_points')}
                setValue={setValue}
                clearErrors={clearErrors}
                containerClass='form-item'
                errorMessage={errors?.account_executive_points?.message}
              />
            </div>

            <div className='form-row'>
              <RatingInput
                id='underwriting_time_points'
                label='How would you rate the underwriting turnaround time?'
                helpText='(1=very dissatisfied, 5=very satisfied)'
                {...register('underwriting_time_points')}
                setValue={setValue}
                clearErrors={clearErrors}
                containerClass='form-item'
                errorMessage={errors?.underwriting_time_points?.message}
              />
            </div>

            <div className='form-row'>
              <RatingInput
                id='underwriting_comm_points'
                label='How would you rate the underwriting communication?'
                helpText='(1=very dissatisfied, 5=very satisfied)'
                {...register('underwriting_comm_points')}
                setValue={setValue}
                clearErrors={clearErrors}
                containerClass='form-item'
                errorMessage={errors?.underwriting_comm_points?.message}
              />
            </div>

            <div className='form-row'>
              <RatingInput
                id='closing_time_points'
                label='How would you rate the closing turnaround time?'
                helpText='(1=very dissatisfied, 5=very satisfied)'
                {...register('closing_time_points')}
                setValue={setValue}
                clearErrors={clearErrors}
                containerClass='form-item'
                errorMessage={errors?.closing_time_points?.message}
              />
            </div>

            <div className='form-row'>
              <RatingInput
                id='closing_comm_points'
                label='How would you rate the closing communication?'
                helpText='(1=very dissatisfied, 5=very satisfied)'
                {...register('closing_comm_points')}
                setValue={setValue}
                clearErrors={clearErrors}
                containerClass='form-item'
                errorMessage={errors?.closing_comm_points?.message}
              />
            </div>

            <div className='form-row'>
              <RatingInput
                id='ease_of_use_points'
                label='How would you rate the ease of use of the TPO Go GreenLight Portal?'
                helpText='(1=very dissatisfied, 5=very satisfied)'
                {...register('ease_of_use_points')}
                setValue={setValue}
                clearErrors={clearErrors}
                containerClass='form-item'
                errorMessage={errors?.ease_of_use_points?.message}
              />
            </div>

            <div className='form-row'>
              <RatingInput
                id='overall_points'
                label='How would you rate the overall process working with TPO Go?'
                helpText='(1=very dissatisfied, 5=very satisfied)'
                {...register('overall_points')}
                setValue={setValue}
                clearErrors={clearErrors}
                containerClass='form-item'
                errorMessage={errors?.overall_points?.message}
              />
            </div>

            <div className='form-row'>
              <TextField
                id='comments'
                type='textarea'
                placeholder='Comment'
                label='Please provide any additional comments that you would like to share.'
                helpText='Max 1000 characters'
                {...register('comments')}
                maxLength='1000'
                errorMessage={errors?.comments?.message}
                containerClass='form-item'
              />
            </div>

            <div className='form-row'>
              <TextField
                id='borrower'
                type='text'
                placeholder='John D'
                label='Please enter your first name and last initial:'
                {...register('borrower')}
                errorMessage={errors?.borrower?.message}
                containerClass='form-item'
                inputClass='col-3'
              />
            </div>

            <div className='form-row'>
              <TextField
                id='loan_number'
                type='text'
                placeholder='ABC123456'
                label='Please enter your loan number:'
                {...register('loan_number')}
                errorMessage={errors?.loan_number?.message}
                containerClass='form-item'
                inputClass='col-3'
              />
            </div>

            <div className='form-row'>
              <CheckboxAndRadio
                id='tos'
                type='checkbox'
                label={
                  <>
                    I certify I am a real customer with {officer.name} and TPO Go. This is my personal experience. I
                    understand LENDAID&apos;s{' '}
                    <NavLink end to='/tos'>
                      Terms of Use
                    </NavLink>
                  </>
                }
                {...register('tos')}
                containerClass='form-item'
              />
            </div>

            <div className='form-row'>
              <ReCAPTCHA
                sitekey={RECAPTCHA_KEY}
                onChange={value => {
                  setValue('recaptcha', value);
                }}
                className='recaptcha'
              />
            </div>

            <div className='form-row'>
              <button
                type='submit'
                disabled={!_.isEmpty(errors) || !recaptchaValue || !tosValue || agentProfile.submittedSuccess}
                className='button arrow small'
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default TpoGoSurveyForm;
