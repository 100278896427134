import React from 'react';
import { NavLink } from 'react-router-dom';

function Buying() {
  return (
    <section>
      <div className='image-text'>
        <div className='image-text__image'>{/* css background image used */}</div>
        <div className='image-text__text'>
          <h2>Buying</h2>
          <p>
            We match you with top-rated mortgage loan officers by tirelessly seeking the best data and customer
            feedback. That way, we can be sure we are recommending loan officers with the best knowledge and
            service-oriented approach to deliver the mortgage experience you deserve.
          </p>
          <ul className='checklist'>
            <li>Search Our Advanced Database</li>
            <li>Browse Your Personalized Results</li>
            <li>Contact The Right Loan Officer</li>
          </ul>
          <p>
            <NavLink end className='button' to='/buying'>
              learn more
            </NavLink>
          </p>
        </div>
      </div>
    </section>
  );
}

export default Buying;
