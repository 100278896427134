import _ from 'lodash';

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams, useNavigate, useLocation } from 'react-router-dom';

import OfficerContactForm from 'components/Pages/AgentProfile/OfficerContactForm';
import AgentReviews from 'components/Pages/AgentProfile/AgentReviews';
import AgentInfo from 'components/Common/AgentInfo';
import Error from 'components/Pages/404';
import Loader from 'components/Common/Loader';
import OfficerSearchLegalTerm from 'components/Pages/AgentProfile/SearchLegalTerm';

import Ad from 'components/Common/Ad';
import Map from 'components/Pages/EmbeddedMap/Map';
import Button from 'components/Common/Button';

import {
  fetchAgentProfile,
  fetchAgentRatings,
  fetchAgentReviews,
  fetchAgentGoogleReviews,
  fetchAgentTransactions,
} from 'actions/agent_actions';

import { advertisementList } from 'actions/advertise_actions';
import { getFirstName, getOfficerMetaInfo } from 'actions/utility_actions';
import AboutOfficer from 'components/Pages/AgentProfile/AboutOfficer';
import PopupProfile from 'components/Pages/AgentProfile/PopupProfile';
import PageMeta from 'components/Common/PageMeta';
import TopBanner from 'components/Common/TopBanner';
import Rating from 'components/Common/Rating';
import { TPO_GO_NMLS } from '../../../Constants';

function AgentProfile() {
  const [pageNumber, setPageNumber] = useState(1);
  const [displayedReviews, setDisplayedReviews] = useState([]);
  const [remainingReviews, setRemainingReviews] = useState([]);
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    advertise: { advertisementList: adsList },
    agentProfile,
    agentsList: { agentsList },
  } = useSelector(state => state);

  useEffect(() => {
    window.scrollTo(0, 0);
    const { nameSlug } = params;
    const idWithLender = nameSlug.split('-').slice(-1)[0];
    const id = idWithLender.replace(/[A-Z]/g, '');
    params.id = id;
    dispatch(fetchAgentProfile(nameSlug));
    dispatch(fetchAgentRatings(id));
    dispatch(fetchAgentTransactions(id));
    dispatch(advertisementList());
  }, [dispatch, params]);

  const renderAds = adList =>
    _.map(adList, ad => (
      <div key={ad.id} className='multiColInner'>
        <Ad advertisement={ad} />
      </div>
    ));

  const goToSurveyPage = currentUrl => {
    // check URL has ending slash
    // TODO: Remove after server redirection of slash urls
    const surveyUrl = currentUrl[currentUrl.length - 1] !== '/' ? '/survey' : 'survey';
    navigate(currentUrl + surveyUrl);
  };

  const showMore = () => {
    if (agentProfile.review.next) {
      const pgNo = pageNumber + 1;
      dispatch(fetchAgentReviews(params.id, pgNo));
      setPageNumber(pageNumber + 1);
    }
    if (remainingReviews.length >= 10) {
      setDisplayedReviews([...displayedReviews, ...remainingReviews.slice(0, 10)]);
      setRemainingReviews(remainingReviews.slice(10));
    } else if (agentProfile.google_reviews && agentProfile.google_reviews.next) {
      const page_token = agentProfile.google_reviews.next;
      dispatch(fetchAgentGoogleReviews(params.id, page_token));
    } else if (!agentProfile.review.next) {
      setDisplayedReviews([...displayedReviews, ...remainingReviews]);
      setRemainingReviews([]);
    }
  };

  const sortReviews = reviews => {
    reviews.sort((a, b) => {
      const dateA = new Date(a.review.created_at);
      const dateB = new Date(b.review.created_at);
      return dateB - dateA;
    });

    return reviews;
  };

  const agent = agentProfile.profile;
  if (agent && agent.rating && agent.rating.points !== 0) {
    agent.rating.points = (agent.rating.points - 0.05).toFixed(1);
  }

  const getMergedReviews = (lendaidReviews, googleReviews) => {
    let mergedReviews = [];
    if (lendaidReviews != null) {
      mergedReviews = lendaidReviews.results;
    }
    if (googleReviews != null) {
      mergedReviews = [...mergedReviews, ...googleReviews.results];
    }
    if (lendaidReviews && googleReviews) {
      mergedReviews = sortReviews(mergedReviews);
    }

    return mergedReviews;
  };
  const lendaidReviews = agentProfile.review;
  const googleReviews = agentProfile.google_reviews;

  const setReviews = useCallback(() => {
    if (lendaidReviews && googleReviews) {
      let reviews = getMergedReviews(lendaidReviews, googleReviews);
      const existingReviews = new Set(displayedReviews);
      reviews = reviews.filter(review => !existingReviews.has(review));
      if (remainingReviews.length < 10) {
        setDisplayedReviews(displayedReviews => [...displayedReviews, ...reviews.slice(0, 10)]);
        setRemainingReviews(reviews.slice(10));
      } else {
        setRemainingReviews(reviews);
      }
    } else if (lendaidReviews) {
      setDisplayedReviews(lendaidReviews.results);
    } else if (googleReviews) {
      const existingReviews = new Set(displayedReviews);
      const reviews = googleReviews.filter(review => !existingReviews.has(review));
      if (remainingReviews.length < 10) {
        setDisplayedReviews(displayedReviews => [...displayedReviews, ...googleReviews.slice(0, 10)]);
        setRemainingReviews(googleReviews.results.slice(10));
      } else {
        setRemainingReviews(reviews);
      }
    }
  }, [lendaidReviews, googleReviews]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (lendaidReviews || googleReviews) {
      setReviews();
    }
  }, [lendaidReviews, googleReviews, setReviews]);

  if (agent === undefined) return <Error />;

  // Loading
  if (agent === null) {
    return (
      <div className='text-center loader-wrapper'>
        <Loader />
      </div>
    );
  }

  if (agent && agent.lender && agent.lender.nmls === TPO_GO_NMLS) return <Error />;

  // Move this to new component, which calls PageMeta inside itself.
  const [metaTitle, metaDescription, logoImage] = getOfficerMetaInfo(agent);

  return (
    <div id='agent-profile'>
      <PageMeta
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        FBImage={logoImage}
        TwitterImage={logoImage}
        url={agent.frontend_url}
      />
      <TopBanner />

      <PopupProfile agent={agent} />

      <div className='container agent-info margin-x padding-top'>
        {agentsList ? (
          <div className='back-btn-wrapper'>
            <Button setClass='back' onClick={() => navigate(-1)} btnLabel='Back To Results' />
          </div>
        ) : null}
        <AgentInfo showContactButton='on' agent={agent} />
      </div>

      {agent.about_officer ? <AboutOfficer agentName={agent.name} aboutOfficer={agent.about_officer} /> : null}

      {(displayedReviews && displayedReviews.length) || (displayedReviews !== null && agent['is_survey_enabled']) ? (
        <div>
          <div className='container margin-x padding-all'>
            <div>
              <div className='leave-review'>
                <h2>
                  {getFirstName(agent.name)}&apos;s Reviews
                  {agent['is_survey_enabled'] && (
                    <button
                      className='button arrow small'
                      onClick={() => goToSurveyPage(location.pathname)}
                      type='button'
                    >
                      Leave a Review
                    </button>
                  )}
                </h2>
              </div>

              <div className='rating-banner'>
                <Rating rate={agent.rating.points} />
                <div className='desc'>
                  {agent.rating.points} Stars - {agent.reviews_count} {agent.reviews_count === 1 ? 'Review' : 'Reviews'}
                </div>
              </div>
              <AgentReviews reviews={displayedReviews} />
              {remainingReviews.length > 0 || agentProfile.review.next ? (
                <div align='center'>
                  <button className='button' onClick={() => showMore()} type='button'>
                    Show more
                  </button>
                </div>
              ) : null}
            </div>
          </div>
          {adsList === undefined ? null : adsList === null ? null : adsList.attorneys.length === 0 ? null : (
            <div className='container ad  margin-x padding-x'>
              <div className='twoColWrapper'>{renderAds(adsList.attorneys)}</div>
            </div>
          )}
        </div>
      ) : null}

      <div className='container margin-x padding-all'>
        <div>
          <h2>{getFirstName(agent.name)}&apos;s Sample Area of Work</h2>
          <Map />
        </div>
      </div>

      {adsList === undefined ? null : adsList === null ? null : adsList.attorneys.length === 0 ? null : (
        <div className='container ad  margin-x padding-x'>
          <div className='twoColWrapper'>{renderAds(adsList.attorneys)}</div>
        </div>
      )}

      <div className='container margin-x padding-all margin-bottom'>
        <div id='agent-form-container'>
          <h2>Contact {agent.name}</h2>
          <div className='row'>
            We respect your privacy and will share your information with this loan officer ONLY.
            <br />
            See our{' '}
            <NavLink end to='/privacy'>
              Privacy Policy
            </NavLink>
          </div>
          <OfficerContactForm adsList={adsList} agentId={agent.id} />
          <OfficerSearchLegalTerm />
        </div>
      </div>
    </div>
  );
}

export default AgentProfile;
