import React from 'react';
import Helmet from 'react-helmet/es/Helmet';

function PageMeta(props) {
  const url = props.url ? props.url : 'https://Lendaid.com';

  return (
    <Helmet>
      <title>{props.metaTitle}</title>
      <meta name='description' content={props.metaDescription} />
      <meta property='fb:app_id' content='287461329250223' />
      <meta property='og:image:width' content='200' />
      <meta property='og:image:height' content='200' />
      <meta property='og:type' content='website' />
      <meta property='og:title' content={props.metaTitle} />
      <meta property='og:description' content={props.metaDescription} />
      <meta property='og:url' content={url} />
      <meta property='og:image' content={props.FBImage} />

      <meta name='twitter:description' content={props.metaDescription} />
      <meta name='twitter:title' content={props.metaTitle} />
      <meta name='twitter:site' content='@lendaidinc' />
      <meta name='twitter:image' content={props.TwitterImage} />
      <meta name='twitter:card' content='summary' />
    </Helmet>
  );
}

export default PageMeta;
