import _ from 'lodash';

import { TRANSACTION_TYPES } from 'Constants';

/**
 * Combines the transaction and review objects from the input array and returns an array of
 * consistent object type containing the properties required for the Map.
 *
 * @param {*} transactions Transactions array that may also contain review objects.
 * @returns Returns array of a consistent object type with properties required for the Map.
 */
function combineTransactions(transactions) {
  const combinedTransactions = [];
  if (transactions) {
    transactions.forEach(transaction => {
      if (transaction.review) {
        transaction = { ...transaction.review, officerRating: transaction?.rating?.officer?.points };
      }

      if (transaction.address.geo_location) {
        const { city, state, zip_code, longitude, latitude } = transaction.address.geo_location;
        combinedTransactions.push({
          id: transaction.id,
          title: `${city} ${state}`,
          mortgage: transaction.mortgage,
          date: transaction.filing_date || transaction.closing_date,
          purpose: TRANSACTION_TYPES[transaction.purpose],
          officerRating: transaction.officerRating,
          comments: transaction?.comments?.trim(),
          zip_code,
          latitude,
          longitude,
        });
      }
    });
  }
  return combinedTransactions;
}

/**
 * Transforms the array of mixed object types to a consistent object type. Then, sorts the array
 * for date and officerRating. Lastly, it groups the sorted objects based on the zip_code.
 *
 * @param {*} transactions Transactions array that may also contain review objects.
 * @returns Returns an array of grouped objects required for the Map.
 */
export default function transformTransactions(transactions) {
  const combinedTransactions = combineTransactions(transactions);
  // const sortedTransactions = _.orderBy(combinedTransactions, ['date', 'officerRating'], ['desc', 'desc']);
  const groupedTransactions = _.groupBy(combinedTransactions, transaction => transaction.zip_code);
  return groupedTransactions;
}
