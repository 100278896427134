export const FETCH_AGENT_PROFILE = 'FETCH_AGENT_PROFILE';
export const TOP_RATED_AGENT = 'TOP_RATED_AGENT';
export const AGENT_REVIEWS = 'AGENT_REVIEWS';
export const AGENT_GOOGLE_REVIEWS = 'AGENT_GOOGLE_REVIEWS';
export const AGENT_RATING = 'AGENT_RATING';
export const AGENT_TRANSACTIONS = 'AGENT_TRANSACTIONS';
export const AGENT_CONTACT_FORM = 'AGENT_CONTACT_FORM';
export const SEARCH_AGENTS = 'SEARCH_AGENTS';
export const SEARCHING_AGENTS = 'SEARCHING_AGENTS';
export const SEARCH_AUTOCOMPLETE_QUERY = 'SEARCH_AUTOCOMPLETE_QUERY';
export const LOCATION_DETAILS = 'LOCATION_DETAILS';
export const IP_LOCATION_DETAILS = 'IP_LOCATION_DETAILS';
export const ADVICE_LIST = 'ADVICE_LIST';
export const ADVICE_DETAIL = 'ADVICE_DETAIL';
export const CONTACT_FORM = 'CONTACT_FORM';
export const ADVERTISE_FORM = 'ADVERTISE_FORM';
export const ADVERTISEMENT_LIST = 'ADVERTISEMENT_LIST';
export const ADVERTISEMENT_COUNT = 'ADVERTISEMENT_COUNT';
export const LENDER_PROFILE = 'LENDER_PROFILE';
export const AGENT_SURVEY = 'AGENT_SURVEY';
export const SUBMITTING_SURVEY = 'SUBMITTING_SURVEY';
export const OPEN_REPORT_REVIEW_MODAL = 'OPEN_REPORT_REVIEW_MODAL';
export const CLOSE_REPORT_REVIEW_MODAL = 'CLOSE_REPORT_REVIEW_MODAL';
export const SUBMIT_REVIEW_REPORT_FORM = 'SUBMIT_REVIEW_REPORT_FORM';
export const RESET_REVIEW_REPORT_FORM = 'RESET_REVIEW_REPORT_FORM';
