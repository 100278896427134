import _ from 'lodash';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import Process from 'components/Process/Process';
import SearchProcessing from 'components/Process/SearchProcessing';
import ProfileGroup from 'components/Profile/ProfileGroup';
import Error from 'components/Pages/500';
import Ad from 'components/Common/Ad';

import { searchAgents, searchingAgents } from 'actions/search_actions';
import { advertisementList } from 'actions/advertise_actions';
import FrownFace from 'assets/images/frown-face.png';
import WithRouter from 'components/HOC/WithRouter';

class SearchResult extends Component {
  UNSAFE_componentWillMount() {
    window.scrollTo(0, 0);
  }

  searchAgain() {
    this.props.searchingAgents();
    this.props.searchAgents();
    this.props.advertisementList();
    this.props.router.navigate('/result');
  }

  renderAds(adList) {
    return _.map(adList, ad => (
      <div key={ad.id} className='multiColInner'>
        <Ad advertisement={ad} />
      </div>
    ));
  }

  backToHome() {
    this.props.router.navigate(`/`);
  }

  render() {
    if (this.props.error) return <Error />;

    if (
      !this.props.fetching &&
      !this.props.locationError &&
      !this.props.recommendedOfficers &&
      !this.props.forbidden &&
      !this.props.error
    ) {
      this.backToHome();
      return null;
    }

    const { searchText } = JSON.parse(localStorage.getItem('searchQuery'));

    const resultNotFound = (
      <div className='container padding-all margin-x margin-bottom'>
        <div className='text-center'>
          <h2>
            We are not yet serving this community.
            <span className='frown-image-wrapper'>
              <img src={FrownFace} alt='sad' />
            </span>
          </h2>
          <p>
            LENDAID is actively working to expand our coverage. <br />
            In the meantime we would love to hear from you at
          </p>
          <a href='mailto:info@lendaid.com'>info@lendaid.com</a>
        </div>
      </div>
    );

    const locationErrorMessage = (
      <div className='container padding-all margin-x margin-bottom text-center'>
        <div>
          <h2>We apologize for any inconvenience!</h2>
          <p>
            Your search location is invalid. Kindly try again with a valid location.
            <br />
            If you are seeing this message persistently, share your concerns at{' '}
          </p>
          <a href='mailto:info@lendaid.com'>info@lendaid.com</a>
        </div>
        <div style={{ marginTop: '20px' }}>
          <button className='button plain' onClick={this.backToHome.bind(this)} type='button'>
            Back To Home
          </button>
        </div>
      </div>
    );

    const onlyTopOfficers = (
      <div className='container padding-all margin-x margin-bottom'>
        <div className='text-center mb80'>
          <h2>We are still building our search engine in {searchText}.</h2>
          <p>
            But we would like to share the highly rated loan officers below, licensed and serving your area. <br />{' '}
            Click on the loan officer name to see their reviews, independently and honestly sourced by the LENDAID team.
          </p>
        </div>
        <ProfileGroup showReview={false} agentsList={this.props.topOfficers} />
      </div>
    );

    const forbidden = (
      <div className='container padding-all margin-x margin-bottom'>
        <div className='text-center'>
          <h2>
            We apologize for any inconvenience!
            <br />
            You have reached your search limit!
          </h2>
          <p>
            Kindly try after a few minutes. Sorry for the inconvenience!
            <br />
            You can share your concerns at <a href='mailto:info@lendaid.com'>info@lendaid.com</a>
          </p>
          <p>
            <button className='button plain' onClick={this.searchAgain.bind(this)} type='button'>
              Try Now
            </button>
          </p>
        </div>
      </div>
    );

    return (
      <div id='search-results'>
        {/* Process */}
        <Process currentStep='7' />

        {this.props.forbidden ? (
          forbidden
        ) : this.props.locationError ? (
          locationErrorMessage
        ) : this.props.fetching ? (
          <SearchProcessing />
        ) : (
          <div>
            {this.props.recommendedOfficers.length === 0 ? (
              this.props.topOfficers.length === 0 ? (
                resultNotFound
              ) : (
                onlyTopOfficers
              )
            ) : (
              <div className='container padding-all margin-x margin-bottom'>
                <h1 className='text-center capitalize'>best Match In Your Community</h1>
                <ProfileGroup showReview={false} agentsList={this.props.recommendedOfficers} />
              </div>
            )}

            {this.props.adsList === undefined ? null : this.props.adsList === null ? null : this.props.adsList.attorneys
                .length === 0 ? null : (
              <div className='container  padding-x margin-x margin-bottom ad'>
                <div className='twoColWrapper'>{this.renderAds(this.props.adsList.attorneys)}</div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProp({ agentsList, advertise }) {
  if (agentsList.fetching) {
    return { fetching: true, recommendedOfficers: null, topOfficers: null };
  } else if (agentsList.agentsList === undefined) {
    return { error: true, fetching: false };
  } else if (agentsList.locationError) {
    return { locationError: true, fetching: false };
  } else if (agentsList.forbidden) {
    return { forbidden: true, fetching: false };
  } else if (!agentsList.fetching && agentsList.agentsList) {
    return {
      fetching: false,
      error: false,
      recommendedOfficers: agentsList.agentsList.recommended_officers,
      topOfficers: agentsList.agentsList.top_rated_officers,
      adsList: advertise.advertisementList,
    };
  }
}

export default connect(mapStateToProp, { searchingAgents, searchAgents, advertisementList })(WithRouter(SearchResult));
