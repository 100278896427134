import { create as axiosCreate } from 'axios';
import { ROOT_URL } from 'config';

const axios = axiosCreate({ baseURL: ROOT_URL });

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Search
export const searchAgentsAPI = data => {
  const route = '/search';
  return axios.post(route, data);
};

export const autoCompleteQueryAPI = (searchTextType, data) => {
  const route = `/apps/locations/auto-complete/?type=${searchTextType}`;
  return axios.post(route, data);
};

// Contact Us
export const submitContactFormAPI = data => {
  const route = '/contact-us';
  return axios.post(route, data);
};

// Officer
export const fetchAgentProfileAPI = nameSlug => {
  const route = `/officers/${nameSlug}`;
  return axios.get(route);
};

export const fetchAgentRatingsAPI = agentId => {
  const route = `/officers/${agentId}/ratings`;
  return axios.get(route);
};

export const fetchAgentReviewsAPI = (agentId, page) => {
  const route = `/officers/${agentId}/reviews?page=${page}`;
  return axios.get(route);
};

export const fetchAgentGoogleReviewsAPI = (agentId, page) => {
  const route = `/officers/${agentId}/google-reviews?page_token=${page}`;
  return axios.get(route);
};

export const submitAgentContactFormAPI = (agentId, data) => {
  const route = `/officers/${agentId}/contact`;
  return axios.post(route, data);
};

export const topRatedAgentsAPI = location => {
  const route = `/officers/top_zipcode/?location_text=${location[0]}&location_type=${location[1]}`;
  return axios.get(route);
};

export const fetchAgentTransactionsAPI = agentId => {
  const route = `/officers/${agentId}/transactions`;
  return axios.get(route);
};

export const agentSurveyAPI = (data, officerId) => {
  const route = `/officers/${officerId}/reviews/`;
  return axios.post(route, data);
};

// Report Reviews
export const submitReviewReportAPI = (reviewId, data) => {
  const route = `/reviews/${reviewId}/report`;
  return axios.post(route, data);
};

// Advice
export const fetchAdviceListAPI = () => {
  const route = '/advices';
  return axios.get(route);
};

export const adviceArticleDetailAPI = slug => {
  const route = `/advices/${slug}`;
  return axios.get(route);
};

// Advertise
export const submitAdvertiseFormAPI = data => {
  const route = '/advertisement';
  return axios.post(route, data);
};

export const advertisementListAPI = location => {
  const route = `/advertisement/?location_text=${location[0]}&location_type=${location[1]}`;
  return axios.get(route);
};

export const advertisementCountAPI = advertismentId => {
  const route = `/advertisement/${advertismentId}`;
  return axios.put(route);
};

// Lender
export const searchLendersAgentAPI = nameSlug => {
  const route = `/lenders/${nameSlug}`;
  return axios.get(route);
};
