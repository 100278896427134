import React, { Component } from 'react';

import bgImage from 'assets/images/heroes/lendaid-header-how.jpg';

import PageBanner from 'components/Common/PageBanner';
import Intro from 'components/Pages/HowItWorks/Intro';
import Content from 'components/Pages/HowItWorks/Content';

import FooterSections from 'components/Footer/FooterSections';
import testfierImage from 'assets/images/home-testimonial.jpg';

class HowItWorks extends Component {
  UNSAFE_componentWillMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div id='howitworks'>
        {/* Page Banner */}
        <PageBanner
          smallHeading='How It Works'
          largeHeading="Don't Leave A Decision This Big To Chance"
          bgImage={bgImage}
        />

        {/* Page Intro */}
        <Intro />

        {/* Page Content */}
        <Content />

        {/* FooterSection */}
        <FooterSections
          name='John M'
          image={testfierImage}
          location='Brookline, MA'
          comments='I had a time sensitive investment purchase.  LENDAID  connected me with an experienced lender that saved me valuable time and money.'
        />
      </div>
    );
  }
}

export default HowItWorks;
