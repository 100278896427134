import React, { Component } from 'react';
import { connect } from 'react-redux';
import { advertisementCount } from 'actions/advertise_actions';

function createAdDescription(ad) {
  return { __html: ad.description };
}

class Ad extends Component {
  advertisementCount(advertisementId) {
    this.props.advertisementCount(advertisementId);
  }

  render() {
    const ad = this.props.advertisement;
    return (
      <div className='ad'>
        <div className='image-holder' style={{ backgroundImage: `url(${ad.image})` }} />
        <div className='content-holder'>
          <h4>{ad.title}</h4>
          <p className='small-text company'>{ad.company}</p>
          <p className='small-text' dangerouslySetInnerHTML={createAdDescription(ad)} />
          <a
            onClick={this.advertisementCount.bind(this, ad.id)}
            href={ad.target}
            target='_blank'
            rel='noreferrer nofollow'
          >
            Learn More
          </a>
        </div>
      </div>
    );
  }
}

export default connect(null, { advertisementCount })(Ad);
