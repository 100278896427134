import { SUBMIT_REVIEW_REPORT_FORM, RESET_REVIEW_REPORT_FORM } from 'actions/action_types';

export function submitReviewReport(reviewId, data) {
  return {
    type: SUBMIT_REVIEW_REPORT_FORM,
    payload: submitReviewReport(reviewId, data),
  };
}

export function resetReviewReportForm(reviewId, data) {
  return {
    type: RESET_REVIEW_REPORT_FORM,
  };
}
