import React, { forwardRef } from 'react';
import classNames from 'classnames';

import Input from 'components/FormComponents/Input';
import Textarea from 'components/FormComponents/Textarea';

function TextField(
  {
    id,
    name,
    type,
    label,
    placeholder,
    helpText,
    errorMessage,
    onChange,
    onBlur,
    labelClass,
    inputClass,
    containerClass,
  },
  ref
) {
  const labelProps = {
    htmlFor: id,
    className: classNames('label', labelClass),
  };
  const inputProps = {
    id,
    name,
    type,
    placeholder,
    onChange,
    onBlur,
    className: classNames(inputClass, { invalid: !!errorMessage }),
  };
  containerClass = classNames('text-field', containerClass, { invalid: !!errorMessage });

  return (
    <div className={containerClass}>
      {label ? <label {...labelProps}>{label}</label> : null}
      {type === 'textarea' ? <Textarea {...inputProps} ref={ref} /> : <Input {...inputProps} ref={ref} />}

      {errorMessage ? <span className='error-message'>{errorMessage}</span> : null}

      {helpText ? <span className='help-text'>{helpText}</span> : null}
    </div>
  );
}

export default forwardRef(TextField);
