import classNames from 'classnames';
import React, { forwardRef } from 'react';

import Input from 'components/FormComponents/Input';

function CheckboxAndRadio({ id, name, type, value, label, onChange, labelClass, inputClass, containerClass }, ref) {
  const labelProps = {
    htmlFor: id,
    className: classNames(labelClass),
  };
  const inputProps = {
    id,
    name,
    type,
    value,
    onChange,
    className: classNames('checkbox-and-radio', inputClass),
  };
  containerClass = classNames('checkbox-and-radio-container', containerClass);

  return (
    <div className={containerClass}>
      <Input {...inputProps} ref={ref} />
      <label {...labelProps}>{label}</label>
    </div>
  );
}

export default forwardRef(CheckboxAndRadio);
