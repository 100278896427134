import React from 'react';

function AboutOfficer(props) {
  return (
    <div className='container margin-x padding-all about-officer'>
      <div>
        <h2>About {props.agentName}</h2>
        <p style={{ margin: 0, whiteSpace: 'pre-wrap' }}>{props.aboutOfficer}</p>
      </div>
    </div>
  );
}

export default AboutOfficer;
