import React, { Component } from 'react';

import Process from 'components/Process/Process';
import StepOne from 'components/Process/StepOne';
import StepTwo from 'components/Process/StepTwo';
import StepThree from 'components/Process/StepThree';
import StepFour from 'components/Process/StepFour';
import StepFive from 'components/Process/StepFive';
import WithRouter from 'components/HOC/WithRouter';

class SearchProcess extends Component {
  constructor(props) {
    super(props);
    const searchQuery = JSON.parse(localStorage.getItem('searchQuery'));
    let steps = { StepOne: <StepOne />, StepTwo: <StepTwo />, StepThree: <StepThree />, StepFour: <StepFive /> };
    if (searchQuery && searchQuery.transactionType === 'RE') {
      steps = {
        StepOne: <StepOne />,
        StepTwo: <StepTwo />,
        StepThree: <StepThree />,
        StepFour: <StepFour />,
        StepFive: <StepFive />,
      };
    }
    if (!this.props.router.location.state) return;
    this.state = {
      currentStep: steps[this.props.router.location.state.step],
      searchQuery,
      processStep: this.props.router.location.state.processStep,
    };
  }

  UNSAFE_componentWillMount() {
    window.scrollTo(0, 0);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const searchQuery = JSON.parse(localStorage.getItem('searchQuery'));
    let steps = { StepOne: <StepOne />, StepTwo: <StepTwo />, StepThree: <StepThree />, StepFour: <StepFive /> };
    if (searchQuery.transactionType === 'RE') {
      steps = {
        StepOne: <StepOne />,
        StepTwo: <StepTwo />,
        StepThree: <StepThree />,
        StepFour: <StepFour />,
        StepFive: <StepFive />,
      };
    }
    if (nextProps.router.location.state.processStep !== this.props.router.location.state.processStep) {
      this.setState({
        currentStep: steps[nextProps.router.location.state.step],
        searchQuery,
        processStep: nextProps.router.location.state.processStep,
      });
    }
  }

  render() {
    if (!this.props.router.location.state) {
      this.props.router.navigate(`/`);
      return null;
    }
    return (
      <div id='searchProcess'>
        <div className='step-holder'>
          <Process currentStep={this.state.processStep} />
        </div>

        {this.state.currentStep}
      </div>
    );
  }
}

export default WithRouter(SearchProcess);
