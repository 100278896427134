import _ from 'lodash';

import React, { Component } from 'react';

import Button from 'components/Common/Button';

import { DOWN_TYPES, BU_STEP_THREE_HEADING, RE_STEP_THREE_HEADING } from 'Constants';
import WithRouter from 'components/HOC/WithRouter';

class StepThree extends Component {
  clickHandler = (downType, DOWN_TYPES) => {
    const searchQuery = JSON.parse(localStorage.getItem('searchQuery'));
    searchQuery['down'] = _.findKey(DOWN_TYPES, o => o === downType);
    localStorage.setItem('searchQuery', JSON.stringify(searchQuery));
    if (searchQuery['down'] === '0') {
      this.props.router.navigate(`/search`, { state: { step: 'StepFour', processStep: '6' } });
      return;
    }
    this.props.router.navigate(`/search`, { state: { step: 'StepFour', processStep: '5' } });
  };

  render() {
    const { transactionType } = JSON.parse(localStorage.getItem('searchQuery'));
    let value = '80';
    const lableValue = '20';
    let heading = RE_STEP_THREE_HEADING;
    if (transactionType === 'BU') {
      value = '20';
      heading = BU_STEP_THREE_HEADING;
    }
    return (
      <div id='step-three'>
        <div className='container padding-all margin-x margin-bottom text-center'>
          <h1 className='capitalize'>{heading}</h1>
          <ul className='buttons-holder  process-holder'>
            <li>
              <Button
                onClick={() => this.clickHandler(`Less than ${value}%`, DOWN_TYPES)}
                btnLabel={`Less than ${lableValue}%`}
                setClass='plain'
              />
            </li>
            <li>
              <Button
                onClick={() => this.clickHandler(`More than ${value}%`, DOWN_TYPES)}
                btnLabel={`${lableValue}% or more`}
                setClass='plain'
              />
            </li>

            {transactionType === 'RE' ? (
              <li />
            ) : (
              <li>
                <Button
                  onClick={() => this.clickHandler('Not sure', DOWN_TYPES)}
                  btnLabel='Not sure'
                  setClass='plain'
                />
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

export default WithRouter(StepThree);
