import React from 'react';
import { Route, Routes } from 'react-router-dom';

import BaseLayout from 'components/layouts/BaseLayout';
import {
  Home,
  SearchResult,
  SearchProcess,
  AgentProfile,
  HowItWorks,
  Buying,
  Refinance,
  ConsejoVideos,
  About,
  Contact,
  Advertise,
  SinglePost,
  Advice,
  TOS,
  Privacy,
  NotFound,
  Error500,
  Lender,
  OfficerSurvey,
  EmbeddedMap,
} from 'components/Pages';

function AppRoutes() {
  return (
    <Routes>
      <Route caseSensitive path='/embed_officers_map/:nameSlug' element={<EmbeddedMap />} />
      <Route path='/' element={<BaseLayout />}>
        <Route path='/' element={<Home />} />
        <Route path='/result' element={<SearchResult />} />
        <Route path='/search' element={<SearchProcess />} />
        <Route path='/officers/:nameSlug' element={<AgentProfile />} />
        <Route path='/officers/:nameSlug/survey' element={<OfficerSurvey />} />
        <Route path='/lenders/:nameSlug' element={<Lender />} />
        <Route path='/how-it-works' element={<HowItWorks />} />
        <Route path='/buying' element={<Buying />} />
        <Route path='/rates' element={<Refinance />} />
        <Route path='/espanol' element={<ConsejoVideos />} />
        <Route path='/about-us' element={<About />} />
        <Route path='/contact-us' element={<Contact />} />
        <Route path='/advertise-with-us' element={<Advertise />} />
        <Route path='/advice/:slug' element={<SinglePost />} />
        <Route path='/advice' element={<Advice />} />
        <Route path='/tos' element={<TOS />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/500' element={<Error500 />} />
        <Route path='/404' element={<NotFound />} />
        <Route path='*' element={<NotFound />} />
      </Route>
      <Route path='/officers/:nameSlug/tpo-go-survey' element={<OfficerSurvey />} />
    </Routes>
  );
}

export default AppRoutes;
