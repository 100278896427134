import React from 'react';
import { NavLink } from 'react-router-dom';

import Nav from 'components/layouts/BaseLayout/Nav';

import Logo from 'assets/images/logo.svg';

function Header() {
  const onNavclick = e => {
    // handle clicking the menu button to open/close button
    const menubutton = document.querySelector('.mobile-menu-button');
    const nav = document.querySelector('nav.main-nav');
    const MOBILE_MENU_OPEN_CLASS = 'open';

    e.preventDefault();
    if (nav.classList.contains(MOBILE_MENU_OPEN_CLASS)) {
      menubutton.classList.remove(MOBILE_MENU_OPEN_CLASS);
      nav.classList.remove(MOBILE_MENU_OPEN_CLASS);
    } else if (e.target.alt !== 'LENDAID  Logo') {
      nav.classList.add(MOBILE_MENU_OPEN_CLASS);
      menubutton.classList.add(MOBILE_MENU_OPEN_CLASS);
    }
  };

  return (
    <header id='header' className='site-header'>
      <div className='logo-holder'>
        <div id='logo' className='logo' onClick={onNavclick}>
          <NavLink end className={({ isActive }) => (isActive ? 'active' : '')} to='/'>
            <img src={Logo} alt='LENDAID Logo' />
          </NavLink>
        </div>
      </div>
      <button className='mobile-menu-button' onClick={onNavclick} type='button' aria-label='Mobile Menu Button'>
        <div className='mobile-menu-button__bar' />
        <div className='mobile-menu-button__bar' />
        <div className='mobile-menu-button__bar' />
        <div className='mobile-menu-button__bar' />
      </button>
      <nav id='navbar' className='main-nav'>
        <Nav />
      </nav>
    </header>
  );
}

export default Header;
