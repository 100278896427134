import React from 'react';
import Loader from 'components/Common/Loader';

function StepFive() {
  return (
    <div id='step-five'>
      <div className='container padding-all margin-x margin-bottom text-center'>
        <h1>We Are Searching For Your Unbiased Match</h1>
        <div>
          <Loader />
        </div>
      </div>
    </div>
  );
}

export default StepFive;
