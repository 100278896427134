import React from 'react';

function ZillowLogo(props) {
  return (
    <span {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='1em'
        height='1em'
        preserveAspectRatio='xMidYMid meet'
        viewBox='0 0 24 24'
      >
        <path
          fill='currentColor'
          d='M12.006 0L1.086 8.627v3.868c3.386-2.013 11.219-5.13 14.763-6.015c.11-.024.16.005.227.078c.372.427 1.586 1.899 1.916 2.301a.128.128 0 0 1-.03.195a43.607 43.607 0 0 0-6.67 6.527c-.03.037-.006.043.012.03c2.642-1.134 8.828-2.94 11.622-3.452V8.627zm-.48 11.177c-2.136.708-8.195 3.307-10.452 4.576V24h21.852v-7.936c-2.99.506-11.902 3.16-15.959 5.246a.183.183 0 0 1-.23-.036l-2.044-2.429c-.055-.061-.062-.098.011-.208c1.574-2.3 4.789-5.899 6.833-7.418c.042-.03.031-.06-.012-.042Z'
        />
      </svg>
    </span>
  );
}

export default ZillowLogo;
