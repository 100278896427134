import React from 'react';

// import images
import unbaised from 'assets/images/homepage/hp-unbiased-guidance.jpg';
import personal from 'assets/images/homepage/hp-personalized-match.jpg';

function Unbaised() {
  const getStarted = () => {
    window.scrollTo(0, 0);
  };

  return (
    <section className='padding-all'>
      <div className='twoColWrapper'>
        <div className='multiColInner'>
          <h2>Free Unbiased Guidance</h2>
          <img alt='LENDAID  App seen on tablets' src={unbaised} />
          <p>
            We’re independent of lender influence. We’re in the business of connecting you with top tier mortgage
            experts - because you deserve to have best-in-class professionals working for you.
          </p>
          <ul className='checklist'>
            <li>100% Free</li>
            <li>Lenders Don&apos;t Pay for Placement</li>
            <li>Find An Extraordinary Loan Officer</li>
            <li>Don’t Leave Your Biggest Purchase To Chance</li>
          </ul>
        </div>

        <div className='multiColInner'>
          <h2>A Personalized Match</h2>
          <img alt='LENDAID  App seen on phones' src={personal} />
          <p>
            Buying a home may be the biggest purchase of your life, and getting a mortgage can be a complicated and
            frustrating process. Working with the right loan officer can make the process a whole lot easier.
          </p>
          <ul className='checklist'>
            <li>Pick A Loan Officer From Your Custom Search Results</li>
            <li>Get A Prequalification Letter</li>
            <li>Save Time & Money</li>
            <li>Loan Officer Experience Matters</li>
          </ul>
        </div>
      </div>
      <div className='text-center margin-top--half'>
        <a onClick={getStarted} className='button'>
          Let&apos;s Get Started
        </a>
      </div>
    </section>
  );
}

export default Unbaised;
