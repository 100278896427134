import { CONTACT_FORM } from 'actions/action_types';
import { fillFormDataWithEmptyValues } from 'actions/utility_actions';
import { submitContactFormAPI } from 'api';

/* eslint-disable import/prefer-default-export */
export function submitContactForm(formData) {
  formData = fillFormDataWithEmptyValues(formData, ['email', 'message']);
  return {
    type: CONTACT_FORM,
    payload: submitContactFormAPI(formData),
  };
}
/* eslint-enable import/prefer-default-export */
