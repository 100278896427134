import React from 'react';
import { NavLink } from 'react-router-dom';
import { FRONT_END_ADMIN } from '../../../Constants';

function Nav() {
  const onNavclick = e => {
    const menubutton = document.querySelector('.mobile-menu-button');
    const nav = document.querySelector('nav.main-nav');
    const MOBILE_MENU_OPEN_CLASS = 'open';

    if (nav.classList.contains(MOBILE_MENU_OPEN_CLASS)) {
      menubutton.classList.remove(MOBILE_MENU_OPEN_CLASS);
      nav.classList.remove(MOBILE_MENU_OPEN_CLASS);
    }
  };

  return (
    <ul id='nav'>
      <li className='main-nav__item'>
        <NavLink end onClick={onNavclick} className={({ isActive }) => (isActive ? 'active' : '')} to='/how-it-works'>
          How It Works
        </NavLink>
      </li>
      <li className='main-nav__item'>
        <NavLink end onClick={onNavclick} className={({ isActive }) => (isActive ? 'active' : '')} to='/buying'>
          Buying
        </NavLink>
      </li>
      <li className='main-nav__item'>
        <NavLink end onClick={onNavclick} className={({ isActive }) => (isActive ? 'active' : '')} to='/rates'>
          Rates
        </NavLink>
      </li>
      <li className='main-nav__item'>
        <NavLink end onClick={onNavclick} className={({ isActive }) => (isActive ? 'active' : '')} to='/about-us'>
          About
        </NavLink>
      </li>
      <li className='main-nav__item'>
        <NavLink end onClick={onNavclick} className={({ isActive }) => (isActive ? 'active' : '')} to='/advice'>
          Advice
        </NavLink>
      </li>
      <li className='main-nav__item'>
        <NavLink end onClick={onNavclick} className={({ isActive }) => (isActive ? 'active' : '')} to='/contact-us'>
          Contact
        </NavLink>
      </li>
      <li className='main-nav__item'>
        <NavLink end onClick={onNavclick} className={({ isActive }) => (isActive ? 'active' : '')} to='/espanol'>
          Espanol
        </NavLink>
      </li>
      <li className='main-nav__item main-nav__login'>
        <a href={FRONT_END_ADMIN} target='_blank' rel='noreferrer'>
          Login
        </a>
      </li>
    </ul>
  );
}

export default Nav;
