import _ from 'lodash';

import React, { Component } from 'react';

import Button from 'components/Common/Button';

import singleFamily from 'assets/images/icon-single-family.svg';
import unitHome from 'assets/images/icon-unit-home.svg';
import condominium from 'assets/images/icon-condominium.svg';

import { PROPERTY_TYPES, STEP_ONE_HEADING } from 'Constants';
import WithRouter from 'components/HOC/WithRouter';

class StepOne extends Component {
  clickHandler = (homeType, PROPERTY_TYPES) => {
    const searchQuery = JSON.parse(localStorage.getItem('searchQuery'));
    searchQuery['homeType'] = _.findKey(PROPERTY_TYPES, o => o === homeType);
    localStorage.setItem('searchQuery', JSON.stringify(searchQuery));
    this.props.router.navigate(`/search`, { state: { step: 'StepTwo', processStep: '3' } });
  };

  render() {
    return (
      <div id='stepOne'>
        <div className='container padding-all margin-x margin-bottom text-center'>
          <h1 className='capitalize'>{STEP_ONE_HEADING}</h1>
          <ul className='buttons-holder inline process-holder'>
            <li>
              <Button
                onClick={() => this.clickHandler('Single Family', PROPERTY_TYPES)}
                btnLabel='Single Family'
                setClass='btn-image'
              >
                <div className='image-holder'>
                  <div className='icon'>
                    <img src={singleFamily} alt='Single Family' />
                  </div>
                </div>
              </Button>
            </li>
            <li>
              <Button
                onClick={() => this.clickHandler('2-4 Unit Home', PROPERTY_TYPES)}
                btnLabel='2-4 Unit Home'
                setClass='btn-image'
              >
                <div className='image-holder'>
                  <div className='icon'>
                    <img src={unitHome} alt='Unit Home' />
                  </div>
                </div>
              </Button>
            </li>
            <li>
              <Button
                onClick={() => this.clickHandler('Condominium', PROPERTY_TYPES)}
                btnLabel='Condominium'
                setClass='btn-image'
              >
                <div className='image-holder'>
                  <div className='icon'>
                    <img src={condominium} alt='Condominium' />
                  </div>
                </div>
              </Button>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default WithRouter(StepOne);
