import React from 'react';
import { ADDRESS } from 'Constants';

function Intro() {
  return (
    <div className='twoColWrapper'>
      <div className='multiColInner'>
        <h4>Find Us</h4>
        <p>
          {ADDRESS['street']}
          <br />
          {ADDRESS['suite']}
          <br />
          {ADDRESS['state']}
        </p>
      </div>
      <div className='multiColInner'>
        <h4>Call or Email</h4>
        <p>
          617-584-1111
          <br />
          <a href='mailto:info@lendaid.com'>info@lendaid.com</a>
        </p>
      </div>
    </div>
  );
}

export default Intro;
