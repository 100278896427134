import { ADVERTISE_FORM, ADVERTISEMENT_LIST } from 'actions/action_types';
import { SubmissionError } from 'redux-form';

const initialState = { submitted: null, advertisementList: null };
function advertiseReducer(state = initialState, action) {
  switch (action.type) {
    case ADVERTISE_FORM:
      if (action.payload.response && action.payload.response.status === 400)
        throw new SubmissionError(action.payload.response.data);
      if (action.payload.response && action.payload.response.status === 500)
        return { ...state, submitted: false, status: action.payload.response.status };
      if (action.payload.status === 201) return { ...state, submitted: true };
      return { ...state };
    case ADVERTISEMENT_LIST:
      if (action.payload.response && action.payload.response.status === 404)
        return { ...state, advertisementList: undefined };
      return { ...state, advertisementList: action.payload.data };
    default:
      return state;
  }
}

export default advertiseReducer;
