import React from 'react';

function Expand(props) {
  return (
    <span {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='1em'
        height='1em'
        preserveAspectRatio='xMidYMid meet'
        viewBox='0 0 24 24'
      >
        <path
          fill='currentColor'
          d='M7.596 15.35a.75.75 0 0 1 1.06 1.06l-3.095 3.094H7.75a.75.75 0 0 1 .743.648l.007.102a.75.75 0 0 1-.75.75h-4a.75.75 0 0 1-.75-.75v-4a.75.75 0 0 1 1.5 0v2.19l3.096-3.094Zm8.659 5.654a.75.75 0 0 1 0-1.5h2.186L15.35 16.41a.75.75 0 0 1-.073-.976l.073-.084a.75.75 0 0 1 1.06 0l3.095 3.096v-2.192a.75.75 0 0 1 .648-.743l.102-.007a.75.75 0 0 1 .75.75v4a.75.75 0 0 1-.75.75h-4ZM7.75 3a.75.75 0 0 1 0 1.5H5.563l3.093 3.095a.75.75 0 0 1 .072.976l-.073.085a.75.75 0 0 1-1.06 0L4.5 5.557V7.75a.75.75 0 0 1-.648.743L3.75 8.5A.75.75 0 0 1 3 7.75v-4A.75.75 0 0 1 3.75 3h4Zm12.505 0a.75.75 0 0 1 .75.75v4a.75.75 0 0 1-1.5 0V5.559L16.41 8.655a.75.75 0 0 1-.976.073l-.084-.073a.75.75 0 0 1 0-1.06L18.442 4.5h-2.187a.75.75 0 0 1-.743-.648l-.007-.102a.75.75 0 0 1 .75-.75h4Z'
        />
      </svg>
    </span>
  );
}

export default Expand;
