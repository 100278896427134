import _ from 'lodash';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import Rating from 'components/Common/Rating';
import LenderTestimonial from 'components/Pages/Lender/LenderTestimonial';

import { TRANSACTION_TYPES } from 'Constants';
import ReportReviewModal from 'components/Pages/AgentProfile/ReportReviewModal';
import OfficerTestimonial from '../AgentProfile/OfficerTestimonial';

function RenderReviews(props) {
  const { review, avatar } = props;
  const purpose = TRANSACTION_TYPES[review.review.purpose];
  const address =
    review.source !== 'Google' &&
    `${review.review.address.geo_location ? review.review.address.geo_location.city : ''}, ${
      review.review.address.geo_location ? review.review.address.geo_location.state : ''
    }`;
  const closingDate = new Date(review.review.closing_date);
  const closingDateFormatted = `${closingDate.toLocaleDateString('en-us', {
    month: 'long',
  })} ${closingDate.getFullYear()}`;
  const { comments } = review.review;
  return (
    <LenderTestimonial
      key={review.review.id}
      reviewId={review.review.id}
      purchase={purpose}
      borrower={review.review.borrower}
      reviewerType={review.review.reviewer_type}
      address={`${address} ${closingDateFormatted}`}
      rating={review.rating.officer.points}
      reply={review.comment}
      avatar={avatar || ''}
      source={review.source || ''}
    >
      {comments ? `"${comments}"` : comments}
    </LenderTestimonial>
  );
}

function LenderReviews(props) {
  const { reviews, ratings } = props;
  const avatar = props.officerAvatar || '';
  if (!reviews || !ratings) return <div />;

  return (
    <div id='agent-reviews'>
      <ReportReviewModal />
      <div className='reviews-holder'>
        <div className='left-side'>
          <ul className='list'>
            <li>
              <Rating rate='5' reviewsCount={`${ratings.points.five_points} Reviews`} alwaysShow='true' />
            </li>
            <li>
              <Rating rate='4' reviewsCount={`${ratings.points.four_points} Reviews`} alwaysShow='true' />
            </li>
            <li>
              <Rating rate='3' reviewsCount={`${ratings.points.three_points} Reviews`} alwaysShow='true' />
            </li>
            <li>
              <Rating rate='2' reviewsCount={`${ratings.points.two_points} Reviews`} alwaysShow='true' />
            </li>
            <li>
              <Rating rate='1' reviewsCount={`${ratings.points.one_points} Reviews`} alwaysShow='true' />
            </li>
          </ul>
        </div>
        <div className='right-side'>
          <div className='testimonial-holder'>
            {_.map(reviews, (review, index) => (
              <RenderReviews key={index} review={review} avatar={avatar} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LenderReviews;
