import _ from 'lodash';

import React, { Component } from 'react';

import Button from 'components/Common/Button';

import { BORROW_TYPES, STEP_TWO_HEADING } from 'Constants';
import WithRouter from 'components/HOC/WithRouter';

class StepTwo extends Component {
  clickHandler = (borrowType, BORROW_TYPES) => {
    const searchQuery = JSON.parse(localStorage.getItem('searchQuery'));
    searchQuery['borrow'] = _.findKey(BORROW_TYPES, o => o === borrowType);
    localStorage.setItem('searchQuery', JSON.stringify(searchQuery));
    if (searchQuery['borrow'] === '0') {
      searchQuery['down'] = '0';
      localStorage.setItem('searchQuery', JSON.stringify(searchQuery));
      this.props.router.navigate(`/search`, { state: { step: 'StepFour', processStep: '6' } });
      return;
    }
    this.props.router.navigate(`/search`, { state: { step: 'StepThree', processStep: '4' } });
  };

  render() {
    const { transactionType } = JSON.parse(localStorage.getItem('searchQuery'));
    return (
      <div id='step-two'>
        <div className='container padding-all margin-x margin-bottom text-center'>
          <h1 className='capitalize'>{STEP_TWO_HEADING}</h1>
          <ul className='buttons-holder  process-holder'>
            <li>
              <Button
                onClick={() => this.clickHandler('Less than $726,200', BORROW_TYPES)}
                btnLabel='Less than $726,200'
                setClass='plain'
              />
            </li>
            <li>
              <Button
                onClick={() => this.clickHandler('More than $726,200', BORROW_TYPES)}
                btnLabel='More than $726,200'
                setClass='plain'
              />
            </li>

            {transactionType === 'RE' ? (
              <li />
            ) : (
              <li>
                <Button
                  onClick={() => this.clickHandler('Not sure', BORROW_TYPES)}
                  btnLabel='Not sure'
                  setClass='plain'
                />
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

export default WithRouter(StepTwo);
