import React from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { NavLink } from 'react-router-dom';

function CustomCookieConsent() {
  return (
    <CookieConsent
      location='bottom'
      buttonText='Accept'
      disableStyles='true'
      containerClasses='cookie-consent'
      buttonClasses='accept-button'
    >
      This website uses cookies to improve and personalize user experience. You consent to our cookies usage policy if
      you continue to use our website.
      <br />
      You can read more about our <NavLink to='/tos'>Terms of Use</NavLink> and{' '}
      <NavLink to='/privacy'>Privacy Policy</NavLink>.
    </CookieConsent>
  );
}

export default CustomCookieConsent;
