import React, { Component } from 'react';

import PageBanner from 'components/Common/PageBanner';
import Intro from 'components/Pages/Refinance/Intro';
import Content from 'components/Pages/Refinance/Content';

import FooterSections from 'components/Footer/FooterSections';
import testfierImage from 'assets/images/how-it-work-testimonial.jpg';
import bgImage from 'assets/images/heroes/lendaid-header-refi.jpg';

class Buying extends Component {
  UNSAFE_componentWillMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div id='refinance'>
        {/* Page Banner */}
        <PageBanner smallHeading='Rates' largeHeading='Insider Information' bgImage={bgImage} />

        {/* Page Intro */}
        <Intro />

        {/* Page Content */}
        <Content />

        {/* FooterSection */}
        <FooterSections
          name='Christine D.'
          image={testfierImage}
          location='Boston, MA'
          transType='RE'
          comments="When I bought my first home, a new construction condo, I didn't know that new construction and a multi-unit building can add more complexity to the mortgage process. LENDAID  connected me with an experienced loan officer that made a challenging process a lot easier."
        />
      </div>
    );
  }
}

export default Buying;
