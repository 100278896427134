import React from 'react';

import Rating from 'components/Common/Rating';

function MapTransactionInfo({ transaction }) {
  const { id, mortgage, purpose, date, officerRating, comments } = transaction;
  return (
    <div className='transaction-item' key={id}>
      {mortgage && (
        <p>
            Mortgage: <b> ${mortgage / 1000}K </b>
        </p>
      )}


      <p>
        {purpose || 'N/A'}
        {date
          ? `, ${new Date(date).toLocaleDateString('en-us', {
              month: 'long',
              year: 'numeric',
            })}`
          : ''}
      </p>

      {officerRating && (
        <>
          <Rating rate={officerRating} />
          {comments && <p className='comments'>&quot;{comments}&quot;</p>}
        </>
      )}
    </div>
  );
}

export default MapTransactionInfo;
