import React from 'react';

import CloseIcon from 'components/Icons/Close';
import { INFO_WINDOW_STATE_TYPES, DEFAULT_INFO_WINDOW_STATE } from 'components/Pages/EmbeddedMap/Map';
import MapTransactionInfo from 'components/Pages/EmbeddedMap/MapTransactionInfo';

function MarkerInfoWindow({ state, transactions, zipCode, setInfoWindow }) {
  const closeInfoWindow = () => {
    setInfoWindow(DEFAULT_INFO_WINDOW_STATE);
  };

  if (state === INFO_WINDOW_STATE_TYPES.CLOSE) {
    return null;
  }

  return (
    <div className='info-window'>
      {state === INFO_WINDOW_STATE_TYPES.MINIMIZED ? (
        <>
          <h2>
            {transactions[0].title}, {zipCode}
          </h2>
          <span>Number of transactions: {transactions.length}</span>
        </>
      ) : state === INFO_WINDOW_STATE_TYPES.OPEN ? (
        <>
          <CloseIcon onClick={closeInfoWindow} className='icon' />
          {transactions.length > 1 && <span>Number of transactions: {transactions.length}</span>}

          <h2>
            {transactions[0].title}, {zipCode}
          </h2>

          {transactions.map(transaction => (
            <MapTransactionInfo transaction={transaction} />
          ))}
        </>
      ) : null}
    </div>
  );
}

export default MarkerInfoWindow;
