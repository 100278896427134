import React from 'react';

function FooterTestimonial(props) {
  const { testifierName, testifierLocation, testifierImage } = props;

  return (
    <div className='green-bg padding-all'>
      <h2 className='white'>Real Data. Real People.</h2>
      <div className='testimonial'>
        <div className='testimonial__image_wrapper'>
          <div className='testimonial__image' style={{ backgroundImage: `url(${testifierImage})` }} />
        </div>
        <div className='testimonial__body'>
          <p>“{props.children}”</p>
          <p className='testifier'>
            <span className='testifier__name'>{testifierName}</span>
            <span className='testifier__location'> / {testifierLocation}</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default FooterTestimonial;
