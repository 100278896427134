import React from 'react';

import Rating from 'components/Common/Rating';
import { getFirstName } from 'actions/utility_actions';
import { Expand, Minimize, Close } from 'components/Icons';

class PopupProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      popupState: 'closed',
    };
  }

  handleScrollToElement = () => {
    document.getElementById('agent-form-container').scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  openPopup = () => {
    this.setState({ popupState: 'open' });
  };

  minimizePopup = () => {
    this.setState({ popupState: 'minimized' });
  };

  closePopup = () => {
    this.setState({ popupState: 'closed' });
  };

  displayPopupOnScroll = () => {
    const ctaDiv = document.getElementById('rating-and-cta-buttons');
    if (ctaDiv) {
      const ctaDivPositionBottom = ctaDiv.getBoundingClientRect().bottom;
      if (ctaDivPositionBottom < 0) {
        window.removeEventListener('scroll', this.displayPopupOnScroll);
        // Display the popup as open for larger screens and minmized for smaller ones
        window.innerWidth > 600 ? this.openPopup() : this.minimizePopup();
      }
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.displayPopupOnScroll);
  }

  render() {
    const { agent } = this.props;
    const { popupState } = this.state;

    if (popupState === 'open') {
      const timeoutId = setTimeout(() => {
        this.minimizePopup();
      }, 30000);
      window.localStorage.setItem('popupTimeoutId', timeoutId);
    } else {
      const existingTimeoutId = window.localStorage.getItem('popupTimeoutId');
      clearTimeout(existingTimeoutId);
    }

    return (
      <div className={`popup-container ${popupState}`}>
        <div className='profile'>
          <div className='picture-container'>
            <img className='picture' src={agent.avatar || '/images/default-placeholder.jpg'} alt={agent.name}/>
            <div className='rating'>
              <Rating rate={agent.rating.points} />
            </div>
          </div>

          <div className='profile-info'>
            <p className='name'>{agent.name}</p>
            <p className='job-title'>{agent.job_title}</p>
            <p className='nmls'>NMLS # {agent.nmls}</p>
          </div>
        </div>

        <button className='button' onClick={this.handleScrollToElement} type='button'>
          Contact {getFirstName(agent.name)}
        </button>

        <span className='popup-close-icon' onClick={this.closePopup}>
          <Close />
        </span>

        {popupState === 'open' ? (
          <span className='popup-expand-icon' onClick={this.minimizePopup} key='popup-minimize-icon'>
            <Minimize />
          </span>
        ) : (
          <span className='popup-expand-icon' onClick={this.openPopup} key='popup-expand-icon'>
            <Expand />
          </span>
        )}
      </div>
    );
  }
}

export default PopupProfile;
