import React from 'react';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailShareButton } from 'react-share';

function ShareAdvice(props) {
  let { url } = props;

  // Facebook doesn't allow localhost urls to share so change for development
  if (url.indexOf('localhost') > 0) {
    url = 'https://lendaid.com/advice/your-unique-personal-information-superhighway';
  }

  return (
    <div className='social-sharing'>
      <div className='social-sharing__text'>Share Article:</div>
      <div className='social-sharing__fb'>
        <FacebookShareButton url={url} quote={props.metaDescription} hashtag='#LENDAID'>
          <span className='icon-facebook' />
        </FacebookShareButton>
      </div>

      <div className='social-sharing__twitter'>
        <TwitterShareButton url={url} title={props.metaDescription} hashtag='#LENDAID'>
          <span className='icon-twitter' />
        </TwitterShareButton>
      </div>

      <div className='social-sharing__li'>
        <LinkedinShareButton url={url}>
          <span className='icon-linkedin' />
        </LinkedinShareButton>
      </div>

      <div className='social-sharing__email'>
        <EmailShareButton subject='LENDAID Advice For Mortgage'>
          <span className='icon-envelope' />
        </EmailShareButton>
      </div>
    </div>
  );
}

export default ShareAdvice;
