import React from 'react';

function CopyButton({ reviewComment, businessGoogleUrl }) {
  function handleShareClick() {
    if (businessGoogleUrl) {
      window.open(businessGoogleUrl, '_blank');
    }
  }

  function checkPermissions() {
    navigator.permissions
      .query({ name: 'clipboard-write' })
      .then(result => result.state === 'granted' || result.state === 'prompt');
  }

  function handleCopyClick() {
    if (reviewComment && checkPermissions) {
      navigator.clipboard.writeText(reviewComment).then(() => {
        handleShareClick();
      });
    }
  }

  return (
    <button
      type='button'
      className='button'
      onClick={() => {
        handleCopyClick();
      }}
    >
      Copy Review & Share to Google
    </button>
  );
}

export default CopyButton;
