export const STEP_ONE_HEADING = 'What Type of home interests you?';
export const STEP_TWO_HEADING = 'How much are you looking to Borrow?';
export const RE_STEP_THREE_HEADING = 'How much equity do you have in your home?';
export const BU_STEP_THREE_HEADING = 'How much can you put down?';
export const RE_STEP_FOUR_HEADING = 'What type of loan are you interested in refinancing?';
export const BU_STEP_FOUR_HEADING = 'Are you a veteran?';
export const STEP_FIVE_HEADING = 'Tailored Results';

export const PROPERTY_TYPES = {
  'SIN': 'Single Family',
  'COD': 'Condominium',
  '2-4': '2-4 Unit Home',
};

export const BORROW_TYPES = {
  449999: 'Less than $726,200',
  450001: 'More than $726,200',
  0: 'Not sure',
};

export const LOAN_TYPES = {
  FHA: 'FHA/VA',
  COV: 'Conventional',
  JUM: 'Both',
};

export const DOWN_TYPES = {
  19: 'Less than 20%',
  21: 'More than 20%',
  79: 'Less than 80%',
  81: 'More than 80%',
  0: 'Not sure',
};

export const TRANSACTION_TYPES = {
  BU: 'Buying',
  RE: 'Refinancing',
};

export const LOF_TRANSACTION_TYPES = {
  '': 'Select your option',
  'BU': 'Buying',
  'RE': 'Refinancing',
};

export const IS_VETERAN = {
  true: 'Yes',
  false: 'No',
};

export const LOF_LOAN_TYPES = {
  '': 'Select your loan type',
  'FHA': 'FHA (government program)',
  'VA': 'VA (government program)',
  'JUM': 'Jumbo (high loan amount)',
  'COV': 'Conventional',
};

export const LOF_BU_LOAN_TYPES = {
  '': 'Select your loan type',
  'E': 'In the very Early Stages',
  'A': 'Anxious to get Started',
  'W': 'Working With Realtor',
};

export const LOF_CHECKBOXES = {
  help_in_afford: 'How much home can I afford?',
  help_in_pre_approval_letter: 'Pre-Approval Letter',
  help_in_general_advice: 'General Advice',
  help_in_rate_quote: 'Rate Quote',
  help_in_home_buy: 'Homebuyer Education Classes',
  help_in_other: 'Other (Comment Below)',
};

export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const SEO_DATA = {
  '/': {
    description:
      'Do you need a mortgage to purchase or refinance a home? Using unbiased data & honest reviews, we match consumers with local experienced loan officers. 100% free',
    title: 'LENDAID | Top Rated Loan Officers',
  },
  '/about-us': {
    description:
      'As career mortgage professionals, we know that LENDAID is the best possible way to begin an online search for a mortgage. Unbiased. Local. Trustworthy. Free.',
    title: 'About Us | LENDAID',
  },
  '/contact-us': {
    description:
      'We are a mortgage consumer service site providing unbiased loan officer recommendations. For questions & information, please contact us at info@lendaid.com.',
    title: 'Contact Us | LENDAID',
  },
  '/advertise-with-us': {
    description:
      'We are a mortgage consumer service site providing unbiased loan officer recommendations. For questions & information, please contact us at info@lendaid.com.',
    title: 'Advertise With Us | LENDAID',
  },
  '/advice': {
    description:
      'Do you need a mortgage to purchase or refinance a home? The LENDAID team shares independent and unbiased blog advice that all mortgage consumers need to know. ',
    title: 'Advice | LENDAID',
  },
  '/buying': {
    description:
      'Buying a home? Need a mortgage? Our trusted, free & unbiased service will match you with local experienced loan officers who can save you time, money & stress. ',
    title: 'Buying | LENDAID',
  },
  '/rates': {
    description:
      'Need to refinance? The right loan officer can get you a good rate, save you time & aggravation. Find a local loan officer at LENDAID. Trusted. Unbiased. Free.',
    title: 'Rates | LENDAID',
  },
  '/how-it-works': {
    description:
      'LENDAID is the best way to begin your search for a mortgage. Free unbiased independent & personalized local loan officer recommendations from an honest source.',
    title: 'How It Works | LENDAID',
  },
  '/privacy': {
    description:
      'LENDAID is committed to maintaining your privacy policy covers how we collect, use, and treat personal information that we may collect through our Website.  ',
    title: 'Privacy Policy | LENDAID',
  },
  '/tos': {
    description:
      'To assist you in using the Website and associated Services, and to ensure a clear understanding of the relationship arising from your use of the Website...',
    title: 'Terms of Use | LENDAID',
  },
  'default': {
    description:
      'Do you need a mortgage to purchase or refinance a home? Using unbiased data & honest reviews, we match consumers with local experienced loan officers. 100% free',
    title: 'LENDAID | Top Rated Loan Officers',
  },
};

export const RIBBON_TEXT =
  'Participating lenders have agreed to a LENDAID code of conduct relative to transparency and independent customer surveys.';

export const SHIELD_TEXT =
  "Reviews are independently sourced by LENDAID from closed loan customers to ensure true, unbiased feedback of the loan officer's customer experience.";

export const DEFAULT_LOFORM_MESSAGE =
  'I am reaching out for your assistance in obtaining a mortgage. Please contact me at your earliest convenience.';

export const LO_FORM_SUCCESS_MESSAGE =
  'Your message has been sent to the loan officer. You should hear from him/her shortly. Thanks!';

export const FORM_SUCCESS_MESSAGE = 'Thanks for filling in the details. You will hear from us shortly. Thanks!';

export const LO_FORM_ERROR_MESSAGE = 'Something Went Wrong! Try Again Later.';

export const PROFILE_HEADER_BANNER = `LENDAID is a free consumer service dedicated to providing independent, trustworthy reviews of lenders & loan officers.`;

export const TOP_OFFICER = {
  1: {
    officer: 'officer',
    this: 'this',
    has: 'has',
  },
  multiple: {
    officer: 'officers',
    this: 'these',
    has: 'have',
  },
};

export const ADDRESS = {
  street: '202 Washington Street',
  suite: 'Suite 309',
  state: 'Brookline, MA 02445',
};

export const SOCIAL_PROFILE_PLATFORM_TYPES = {
  FB: 'Facebook',
  GB: 'Google My Business',
  IG: 'Instagram',
  IN: 'LinkedIn',
  TW: 'Twitter',
  ZI: 'Zillow',
};

export const REVIEW_REPORT_SUCCESS_MESSAGE = 'Review has been reported.';
export const REVIEW_REPORT_FAILURE_MESSAGE = 'Something went wrong while reporting the review.';

/**
 * Main website front Admin URL
 * */
export const FRONT_END_ADMIN = `${process.env.REACT_APP_FRONTEND_ADMIN}`;

export const TPO_GO_NMLS = '71655';
// for local testing uncomment below line
// export const TPO_GO_NMLS = '090078601';
