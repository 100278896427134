import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Rating from 'components/Common/Rating';
import ProfileGroup from 'components/Profile/ProfileGroup';
import Shield from 'components/Common/Shield';
import TopBanner from 'components/Common/TopBanner';
import Error from 'components/Pages/404';
import Loader from 'components/Common/Loader';
import { SHIELD_TEXT } from 'Constants';
import { searchLendersAgent } from 'actions/lenders_actions';
import PageMeta from 'components/Common/PageMeta';
import LenderReviews from './LenderReviews';

function Lender() {
  const { nameSlug } = useParams();
  const lender = useSelector(state => state.lenderReducer.lender);
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(searchLendersAgent(nameSlug));
  }, [nameSlug, dispatch]);

  if (lender === undefined) return <Error />;

  // Loading
  if (lender === null) {
    return (
      <div className='text-center loader-wrapper'>
        <Loader />
      </div>
    );
  }

  let address, website;

  // if lender had no reviews
  lender['reviews_count'] = lender['reviews_count'] ? lender['reviews_count'] : 0;

  if (lender.address && lender.address.geo_location) {
    address = `${lender.address.street1} ${lender.address.geo_location.city}, ${lender.address.geo_location.state}`;
  }

  if (lender.website) {
    website = lender.website.indexOf('://') === -1 ? `http://${lender.website}` : lender.website;
  }

  const metaTitle = `${lender.name} Ratings and Reviews | LENDAID`;
  const metaDescription = `Read unbiased, independent LENDAID Certified customer ratings and reviews for ${lender.name} loan officers. 'Mortgage Smarter' with LENDAID.`;
  const logoImage = lender.avatar;
  const url = `https://lendaid.com/lenders/${lender.name_slug}`;

  return (
    <div id='lender-profile'>
      <PageMeta
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        FBImage={logoImage}
        TwitterImage={logoImage}
        url={url}
      />

      <TopBanner />

      <div className='container agent-info margin-x padding-top'>
        <div className='agent-detail'>
          {lender.avatar ? (
            <div className='image-holder' style={{ backgroundImage: `url(${lender.avatar})` }} />
          ) : (
            <div
              className='image-holder'
              style={{
                backgroundImage: `url(https://placeholdit.imgix.net/~text?txtsize=30&bg=ffffff&txtclr=444&txt=${lender.name_slug}&w=176&h=176)`,
              }}
            />
          )}

          <div className='contact-info'>
            <h1>{lender.name}</h1>
            <div className='display-table'>
              {lender.nmls ? (
                <div className='display-table-row'>
                  <div className='display-table-cell'>NMLS</div>
                  <div className='display-table-cell'>{lender.nmls}</div>
                </div>
              ) : (
                <div />
              )}

              {website ? (
                <div className='display-table-row'>
                  <div className='display-table-cell'>Website</div>
                  <div className='display-table-cell'>
                    <a href={website} target='_blank' rel='nofollow noreferrer'>
                      {lender.website}
                    </a>
                  </div>
                </div>
              ) : (
                <div />
              )}

              {address ? (
                <div className='display-table-row'>
                  <div className='display-table-cell'>Address</div>
                  <div className='display-table-cell'>{address}</div>
                </div>
              ) : (
                <div />
              )}

              {lender.phone ? (
                <div className='display-table-row'>
                  <div className='display-table-cell'>Phone</div>
                  <div className='display-table-cell'>{lender.phone}</div>
                </div>
              ) : (
                <div />
              )}
            </div>
            <span className='small-text'>
              For updated state licensing information visit&nbsp;
              <a
                href={`http://nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/${lender.nmls}`}
                target='_blank'
                rel='nofollow noreferrer'
              >
                NMLS Consumer Access
              </a>
            </span>
          </div>
          <div className='rating-holder'>
            <Rating rate={lender.rating.avg_point} reviewsCount={`${lender.reviews_count} LENDAID Certified Reviews`} />
            <Shield popContent={SHIELD_TEXT} />
          </div>
        </div>
      </div>

      <div className='container margin-x padding-all about-officer'>
        <div>
          <h2>About {lender.name}</h2>
          {lender.about ? <p>{lender.about}</p> : null}

          {lender.licenses ? (
            <p>
              <strong>State Licenses:</strong> {lender.licenses}
            </p>
          ) : null}

          {lender.find_lo_link ? (
            <a href={lender.find_lo_link} className='button margin-top--half' target='_blank' rel='nofollow noreferrer'>
              Find a Loan Officer
            </a>
          ) : null}
        </div>
      </div>

      {lender.top_officers ? (
        <div className='container margin-x padding-all margin-bottom'>
          <div>
            <h2 className='text-center capitalize'>Highest and Best Rated Loan Officers</h2>
            <ProfileGroup showReview agentsList={lender.top_officers} />
          </div>
        </div>
      ) : null}

      {lender.top_reviews && (
        <div className='container margin-x padding-all'>
          <div className='leave-review'>
            <h2>{lender.name}&apos;s Reviews</h2>
          </div>
          <div className='rating-banner'>
            <Rating rate={lender.rating.avg_point} />
            <div className='desc'>
              {lender.rating.avg_point} Stars - {lender.reviews_count}{' '}
              {lender.reviews_count === 1 ? 'Review' : 'Reviews'}
            </div>
          </div>
          <LenderReviews reviews={lender.top_reviews.results} ratings={lender.detailed_rating} />
        </div>
      )}
    </div>
  );
}

export default Lender;
