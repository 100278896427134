import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { searchAgents, searchingAgents } from 'actions/search_actions';
import { advertisementList } from 'actions/advertise_actions';
import { formInitialValues } from 'actions/utility_actions';

import Button from 'components/Common/Button';

import { STEP_FIVE_HEADING } from 'Constants';
import WithRouter from 'components/HOC/WithRouter';

class StepFive extends Component {
  constructor(props) {
    super(props);
    const initialValues = formInitialValues();
    this.isDisable = this.isDisable.bind(this);
    this.state = {
      isTosChecked: localStorage.getItem('checkedTos'),
      name: initialValues.name,
      email: initialValues.email,
      error: { name: '', email: '' },
    };
  }

  handleChange() {
    this.setState(prevState => ({ isTosChecked: !prevState.isTosChecked }));
  }

  nameHandler(e, error) {
    let nameError = '';
    if (e.target.value === '') {
      nameError = 'Name is Blank';
    }
    this.setState({ name: e.target.value, error: { ...error, name: nameError || '' } });
  }

  emailHandler(e) {
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email) || this.state.email === '')
      this.setState({ email: e.target.value, error: { email: '' } });
    else this.setState({ email: e.target.value });
  }

  validateEmail(e) {
    let emailError = '';
    if (this.state.email === '') {
      emailError = 'Email is Blank';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)) {
      emailError = 'Email is Invalid';
    }
    this.setState(prevState => ({ error: { email: emailError, name: prevState.error.name } }));
  }

  isDisable() {
    if (!this.state.isTosChecked) return true;
    if (!this.state.name || !this.state.email || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)) {
      return true;
    }
    return false;
  }

  search() {
    this.props.searchingAgents();
    this.props.searchAgents();
    this.props.advertisementList();
    this.props.router.navigate('/result');
  }

  findLoanOfficer = () => {
    const searchQuery = JSON.parse(localStorage.getItem('searchQuery'));
    const personalInfo = { name: this.state.name, email: this.state.email };
    searchQuery['personalInfo'] = personalInfo;
    localStorage.setItem('personalInfo', JSON.stringify(personalInfo));
    localStorage.setItem('searchQuery', JSON.stringify(searchQuery));
    this.search();
  };

  render() {
    return (
      <div id='step-four'>
        <div className='container padding-all margin-x margin-bottom text-center'>
          <h1 className='capitalize'>{STEP_FIVE_HEADING}</h1>
          <ul className='buttons-holder process-holder'>
            <li>
              <div className='i-agree-wrapper'>
                <input
                  id='i-agree'
                  onClick={this.handleChange.bind(this)}
                  defaultChecked={this.state.isTosChecked}
                  type='checkbox'
                />
                <label htmlFor='i-agree'>
                  <span className='checkbox' />I Agree to
                </label>
                <NavLink end to='/tos'>
                  {' '}
                  Terms of Use
                </NavLink>
              </div>
            </li>
            <li>
              <div className={`${this.state.error.name ? 'form-error' : ''}`}>
                <div className='error-message-wrapper'>
                  <div className='error-message'> {this.state.error.name}</div>
                </div>
                <input
                  type='text'
                  onChange={e => this.nameHandler(e, this.state.error)}
                  onBlur={e => this.nameHandler(e, this.state.error)}
                  className={`textfield ${this.state.error.name ? 'has-error' : ''}`}
                  placeholder='Name'
                  value={this.state.name}
                />
              </div>
            </li>
            <li>
              <div className={`${this.state.error.email ? 'form-error' : ''}`}>
                <div className='error-message-wrapper'>
                  <div className='error-message'> {this.state.error.email}</div>
                </div>
                <input
                  type='email'
                  onChange={this.emailHandler.bind(this)}
                  onBlur={this.validateEmail.bind(this)}
                  className={`textfield ${this.state.error.email ? 'has-error' : ''}`}
                  placeholder='Email'
                  value={this.state.email}
                />
              </div>
            </li>
            <li>
              <Button
                onClick={this.findLoanOfficer}
                disabled={this.isDisable()}
                id='submit-info'
                btnLabel='Submit Name & See Results'
                setClass='plain'
              />
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default connect(null, { searchingAgents, searchAgents, advertisementList })(WithRouter(StepFive));
