import _ from 'lodash';

import React, { Component } from 'react';
import { connect } from 'react-redux';

import Profile from 'components/Profile/Profile';
import { TOP_OFFICER } from 'Constants';

class TopRated extends Component {
  renderofficersList(officersList) {
    officersList.sort((a, b) =>
      a.reviews_count.total < b.reviews_count.total ? 1 : b.reviews_count.total < a.reviews_count.total ? -1 : 0
    );

    return _.map(officersList, officer => <Profile agent={officer} key={`officer-${officer.id}`} />);
  }

  render() {
    const { topOfficers } = this.props;
    if (!topOfficers || topOfficers.length === 0) return <div />;
    const { length } = topOfficers;
    let topOfficerNouns = TOP_OFFICER[length];
    if (length !== 1) topOfficerNouns = TOP_OFFICER['multiple'];

    return (
      <section className='blue-bg padding-all'>
        <h2 className='text-center capitalize'>Top Rated Loan {topOfficerNouns['officer']}</h2>
        <p className='text-center sentence'>
          {topOfficerNouns['this']} loan {topOfficerNouns['officer']} in your community {topOfficerNouns['has']}{' '}
          honestly received stellar reviews!
        </p>
        <div className='loan-officer-wrapper margin-top--half'>{this.renderofficersList(topOfficers)}</div>
      </section>
    );
  }
}
function mapStateToProp({ agentProfile }) {
  return { topOfficers: agentProfile.topOfficer };
}

export default connect(mapStateToProp)(TopRated);
