import { SEARCH_AGENTS, SEARCH_AUTOCOMPLETE_QUERY, SEARCHING_AGENTS } from 'actions/action_types';
import { autoCompleteQueryAPI, searchAgentsAPI } from 'api';

export function searchAgents() {
  const searchQuery = JSON.parse(localStorage.getItem('searchQuery'));
  const ipLocation = JSON.parse(localStorage.getItem('ipLocation'));
  const htmlLocation = JSON.parse(localStorage.getItem('location'));
  localStorage.setItem('checkedTos', true);
  const data = {
    purpose: searchQuery['transactionType'],
    location_type: searchQuery['locationType'],
    location_text: searchQuery['searchText'],
    geo_location: searchQuery['geoLocation'],
    property_type: searchQuery['homeType'],
    borrow: searchQuery['borrow'],
    percentage: searchQuery['down'],
    is_veteran: searchQuery['isVeteran'],
    loan: searchQuery['loanType'],
    name: searchQuery['personalInfo'] ? searchQuery['personalInfo']['name'] : '',
    email: searchQuery['personalInfo'] ? searchQuery['personalInfo']['email'] : '',
    ipLocation,
    htmlLocation,
  };

  return {
    type: SEARCH_AGENTS,
    payload: searchAgentsAPI(data),
  };
}

export function autoCompleteQuery(searchText, searchTextType) {
  const data = { text: searchText };
  return {
    type: SEARCH_AUTOCOMPLETE_QUERY,
    payload: autoCompleteQueryAPI(searchTextType, data),
  };
}

export function searchingAgents() {
  return {
    type: SEARCHING_AGENTS,
    payload: 'fetching',
  };
}
