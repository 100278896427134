import axios from 'axios';
import { MAP_API_KEY, MAP_API_URL, IP_LOCATION_API_URL } from 'config';

// Geolocation
export const fetchLocationDetailsAPI = (lat, long) => {
  const route = `${MAP_API_URL}${lat},${long}&key=${MAP_API_KEY}`;
  return axios.get(route);
};

export const getIPLocationDetailsAPI = () => {
  const route = IP_LOCATION_API_URL;
  return axios.get(route, { headers: { 'X-Requested-With': 'XMLHttpRequest' } });
};
