import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { agentSurvey, submittingSurvey } from 'actions/agent_actions';
import Loader from 'components/Common/Loader';
import SurveyForm from './SurveyForm';
import TpoGoSurveyForm from './TpoGoSurveyForm';
import { TPO_GO_NMLS } from '../../../Constants';

function OfficerSurveyForm({ officer }) {
  const agentProfile = useSelector(state => state.agentProfile);
  const dispatch = useDispatch();
  const [comment, setComment] = useState('');

  const onSubmit = data => {
    dispatch(submittingSurvey());
    dispatch(agentSurvey(data, officer.id));
    setComment(data.comment);
    const containerOffSet = document.getElementsByClassName('container')[1]
      ? document.getElementsByClassName('container')[1].offsetTop
      : 0;
    window.scrollTo(0, containerOffSet);
  };

  const onTpoGoReviewSubmit = data => {
    onSubmit(data);
  };

  const onGenericReviewSubmit = data => {
    const [closeMonth, closeYear] = data.closingDate.split('/');
    data.closingDate = `${closeYear}-${closeMonth}-15`;
    if (data.mortgage === '') {
      delete data.mortgage;
    }
    onSubmit(data);
  };

  return (
    <div>
      {agentProfile.loading ? (
        <div className='text-center loader-wrapper'>
          <Loader />
        </div>
      ) : agentProfile.submittedSuccess === false ? (
        <div className='survey-error'>
          <h1>ERROR</h1>
          <p>{agentProfile.errorMessage || 'There are some errors.'}</p>
        </div>
      ) : officer.lender.nmls === TPO_GO_NMLS ? (
        <TpoGoSurveyForm officer={officer} agentProfile={agentProfile} onSubmit={onTpoGoReviewSubmit} />
      ) : (
        <SurveyForm officer={officer} agentProfile={agentProfile} comment={comment} onSubmit={onGenericReviewSubmit} />
      )}
    </div>
  );
}

export default OfficerSurveyForm;
