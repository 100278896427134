import 'babel-polyfill';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// Constants
import { SEO_DATA } from 'Constants';

// Assets
import FBImage from 'assets/images/lendaid-fb-home.png';
import TwitterImage from 'assets/images/lendaid-logo300x200.jpg';

import PageMeta from 'components/Common/PageMeta';
import AppRoutes from 'routes';
import CustomCookieConsent from './components/Common/CustomCookieConsent';

function App() {
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return null;
  }

  /* class "blue-bg" should be conditional. Only for inner pages. */
  const componentPath = location.pathname;
  const staticPages = [
    '/',
    '/how-it-works',
    '/buying',
    '/rates',
    '/about-us',
    '/contact-us',
    '/advertise-with-us',
    '/advice',
  ];
  const blueClass = staticPages.indexOf(componentPath) !== -1 ? '' : 'blue-bg';

  const url = `https://lendaid.com${componentPath}`;
  let metaDescription = SEO_DATA['default'].description;
  let metaTitle = SEO_DATA['default'].title;
  if (SEO_DATA[componentPath]) {
    metaDescription = SEO_DATA[componentPath].description;
    metaTitle = SEO_DATA[componentPath].title;
  }

  return (
    <div className={`wrapper ${blueClass}`}>
      <CustomCookieConsent />
      <PageMeta
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        FBImage={FBImage}
        TwitterImage={TwitterImage}
        url={url}
      />

      <AppRoutes />
    </div>
  );
}

export default App;
