import React, { Component } from 'react';
// import Geolocation from "react-geolocation";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import HeroBanner from 'components/Pages/Home/HeroBanner';
import YoutubeVideo from 'components/Common/YoutubeVideo';
import Unbaised from 'components/Pages/Home/Unbaised';
import TopRated from 'components/Pages/Home/TopRated';
import Buying from 'components/Pages/Home/Buying';
import Refinance from 'components/Pages/Home/Refinance';

import { getIPLocationDetails } from 'actions/geolocation_actions';
import { topRatedAgents } from 'actions/agent_actions';

import FooterSections from 'components/Footer/FooterSections';
import testfierImage from 'assets/images/how-it-work-testimonial.jpg';

class Home extends Component {
  constructor(props) {
    super(props);
    this.props.getIPLocationDetails();
    this.props.topRatedAgents();
  }

  UNSAFE_componentWillMount() {
    window.scrollTo(0, 0);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.locationMap !== this.props.locationMap) {
      this.props.topRatedAgents();
    }
  }

  render() {
    return (
      <div id='home'>
        {/* Hero Banner */}
        <HeroBanner />

        {/* Youtube Video */}
        <YoutubeVideo videoUrl='NxxClIR1ubo' />

        {/* Unbiased Guidance  */}
        <Unbaised />

        {/* Top Rated */}
        <TopRated />

        {/* Buying */}
        <Buying />

        {/* Refinance */}
        <Refinance />

        {/* Footer Section */}
        <FooterSections
          name='Christine D.'
          image={testfierImage}
          location='Boston, MA'
          comments="When I bought my first home, a new construction condo, I didn't know that new construction and a multi-unit building can add more complexity to the mortgage process. LENDAID  connected me with an experienced loan officer that made a challenging process a lot easier."
        />
      </div>
    );
  }
}

function mapDispatchToProp(dispatch) {
  return bindActionCreators({ topRatedAgents, getIPLocationDetails }, dispatch);
}

function mapStateToProp({ locationMap }) {
  return { locationMap: locationMap.locationMap };
}

export default connect(mapStateToProp, mapDispatchToProp)(Home);
