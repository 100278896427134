import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Error from 'components/Pages/404';
import Loader from 'components/Common/Loader';
import PageMeta from 'components/Common/PageMeta';
import ShareAdvice from 'components/Pages/Advice/ShareAdvice';

import logoImage from 'assets/images/logo.png';

import { adviceArticleDetail } from 'actions/advice_actions';
import { sanitizeAdiveBody } from 'actions/utility_actions';
import { MONTH_NAMES } from 'Constants';

function createAdviceBody(adviceArticle) {
  return { __html: adviceArticle.body };
}

function SinglePost() {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const advice = useSelector(state => state.advices.article);

  useEffect(() => {
    dispatch(adviceArticleDetail(slug));
  }, [slug, dispatch]);

  if (advice === undefined) return <Error />;

  if (!advice) {
    return (
      <div className='text-center loader-wrapper'>
        <Loader />
      </div>
    );
  }

  const adviceArticle = advice;
  const createdAt = new Date(adviceArticle.created_at);
  const metaTitle = adviceArticle.title;
  const metaDescription = sanitizeAdiveBody(adviceArticle.body, 160);
  const url = `https://lendaid.com/advice/${adviceArticle.slug}`;

  return (
    <div id='single-post' className='blue-bg'>
      <PageMeta
        metaTitle={metaTitle}
        metaDescription={metaDescription}
        FBImage={logoImage}
        TwitterImage={logoImage}
        url={url}
      />

      <section className='padding-all'>
        <div className='blog blog--article'>
          <div className='blog__item'>
            <div className='blog-date'>
              <div className='blog-date__month'>{MONTH_NAMES[createdAt.getMonth()]}</div>
              <div className='blog-date__day'>{createdAt.getDate()}</div>
            </div>
            <div className='blog-content'>
              <h1>{adviceArticle.title}</h1>

              <ShareAdvice url={url} metaDescription={metaDescription} />

              <img className='block margin-y--half' src={adviceArticle.image} alt={adviceArticle.title} />
              <div className='advice-body' dangerouslySetInnerHTML={createAdviceBody(adviceArticle)} />

              <ShareAdvice url={url} metaDescription={metaDescription} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SinglePost;
