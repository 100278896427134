import React, { Component } from 'react';
import YoutubeVideo from 'components/Common/YoutubeVideo';
import ConsejoHeader from 'components/Pages/Consejo/Header';

class Consejo extends Component {
  UNSAFE_componentWillMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <ConsejoHeader />

        {/* Mortgage Smarter - Spanish */}
        <YoutubeVideo videoUrl='gjZM1OntLyg' />

        {/* Get Pre-Approved - Spanish */}
        <YoutubeVideo videoUrl='6WAhJAL2F7c' />
      </div>
    );
  }
}

export default Consejo;
