import { ADVERTISE_FORM, ADVERTISEMENT_LIST, ADVERTISEMENT_COUNT } from 'actions/action_types';
import { getLocationTypeAndTextFromLocalStorage, fillFormDataWithEmptyValues } from 'actions/utility_actions';
import { advertisementCountAPI, advertisementListAPI, submitAdvertiseFormAPI } from 'api';

export function submitAdvertiseForm(formData) {
  formData = fillFormDataWithEmptyValues(formData, ['email', 'message', 'location']);
  return {
    type: ADVERTISE_FORM,
    payload: submitAdvertiseFormAPI(formData),
  };
}

export function advertisementList() {
  const location = getLocationTypeAndTextFromLocalStorage();
  return {
    type: ADVERTISEMENT_LIST,
    payload: advertisementListAPI(location),
  };
}

export function advertisementCount(advertismentId) {
  return {
    type: ADVERTISEMENT_COUNT,
    payload: advertisementCountAPI(advertismentId),
  };
}
