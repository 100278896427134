import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class NotFound extends Component {
  UNSAFE_componentWillMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div id='error'>
        <section className='padding-x padding-bottom margin-top'>
          <div className='container mt150'>
            <div className='text-center' aria-label='Error' role='main'>
              <h1>404 Error</h1>
              <p>
                Sorry, the page you were looking for could not be found. Please check to see if you have typed the
                correct address.
              </p>
              <ul className='button-holder'>
                <li>
                  <NavLink end className={({ isActive }) => `button${isActive ? ' active' : ''}`} to='/'>
                    Back To Homepage
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default NotFound;
