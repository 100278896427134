import React from 'react';

function LenderProfileLegelTerm() {
  return (
    <div className='search-legal-term'>
      In order to provide the most comprehensive feedback on lenders and loan officers, we survey all closed loan
      customers of our participating lenders. We only publish reviews that we obtain independently, labeled “LENDAID
      Certified.”
    </div>
  );
}

export default LenderProfileLegelTerm;
