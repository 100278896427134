import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Rating from 'components/Common/Rating';
import Ribbon from 'components/Common/Ribbon';
import Shield from 'components/Common/Shield';

import { SHIELD_TEXT, RIBBON_TEXT } from 'Constants';
import { getFirstName, getFullFormattedAddress } from 'actions/utility_actions';
import defaultAgentPhoto from 'assets/images/default-placeholder.jpg';
import equalHousing from 'assets/images/equal-housing.png';
import {
  FacebookLogo,
  GoogleMyBusinessLogo,
  InstagramLogo,
  LinkedInLogo,
  TwitterLogo,
  ZillowLogo,
} from 'components/Icons';

class AgentInfo extends Component {
  handleScrollToElement = () => {
    document.getElementById('agent-form-container').scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  componentDidMount() {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({ agentName: this.props.agent.name });
  }

  render() {
    const { agent } = this.props;
    let careerStartedAt,
      website,
      rating,
      licenseDisclosure,
      phoneNumber = null;
    const address = getFullFormattedAddress(agent.address);
    const secondary_address = getFullFormattedAddress(agent.secondary_address);

    const SOCIAL_PROFILE_PLATFORM_ICONS = {
      FB: <FacebookLogo />,
      GB: <GoogleMyBusinessLogo />,
      IG: <InstagramLogo />,
      IN: <LinkedInLogo />,
      TW: <TwitterLogo />,
      ZI: <ZillowLogo />,
    };

    if (agent.career_started_at) careerStartedAt = new Date(agent.career_started_at).getFullYear();
    if (agent.website) {
      website = agent.website.indexOf('://') === -1 ? `http://${agent.website}` : agent.website;
    }
    if (agent.lender && agent.lender.license_disclosure) {
      licenseDisclosure = agent.lender.license_disclosure;
      licenseDisclosure = licenseDisclosure.indexOf('://') === -1 ? `http://${licenseDisclosure}` : licenseDisclosure;
    }
    if (agent.phone_number || agent.cell_number) {
      phoneNumber = `tel:${agent.cell_number || agent.phone_number}`;
    }
    if (this.props.rating) {
      rating = this.props.rating.points.average;
    } else if (this.props.agent) {
      rating = this.props.agent.rating.points;
    }

    return (
      <div className='agent-detail'>
        {agent.avatar ? (
          <div className='image-holder' style={{ backgroundImage: `url(${agent.avatar})` }} />
        ) : (
          <div className='image-holder' style={{ backgroundImage: `url(${defaultAgentPhoto})` }} />
        )}
        <div className='contact-info'>
          <h1>{agent.name}</h1>
          <div className='display-table'>
            <div className='display-table-row'>
              <div className='display-table-cell'>NMLSID#</div>
              <div className='display-table-cell'>{agent.nmls}</div>
            </div>

            {agent.lender ? (
              <div className='display-table-row'>
                <div className='display-table-cell'>Company</div>
                <div className='display-table-cell'>
                  <span className='lender-name'>
                    {agent.lender.name} - NMLS# {agent.lender.nmls}
                  </span>
                  {agent.lender.is_certified ? <Ribbon popContent={RIBBON_TEXT} /> : null}
                  <span className='equal-housing'>
                    <img alt='Equal Housing Opportunity' src={equalHousing} />
                  </span>
                </div>
              </div>
            ) : null}

            {agent.job_title ? (
              <div className='display-table-row'>
                <div className='display-table-cell'>Job Title</div>
                <div className='display-table-cell'>{agent.job_title}</div>
              </div>
            ) : null}

            {agent.career_started_at ? (
              <div className='display-table-row'>
                <div className='display-table-cell'>Career Started</div>
                <div className='display-table-cell'>{careerStartedAt}</div>
              </div>
            ) : null}

            {website ? (
              <div className='display-table-row'>
                <div className='display-table-cell'>Website</div>
                <div className='display-table-cell'>
                  <a href={website} className='officer-website' target='_blank' rel='nofollow noreferrer'>
                    {agent.website}
                  </a>
                </div>
              </div>
            ) : null}

            {address ? (
              <div className='display-table-row'>
                <div className='display-table-cell'>Address</div>
                <div className='display-table-cell'>{address}</div>
              </div>
            ) : null}

            {secondary_address ? (
              <div className='display-table-row'>
                <div className='display-table-cell'>Second Branch</div>
                <div className='display-table-cell'>{secondary_address}</div>
              </div>
            ) : null}

            {agent.state_licenses ? (
              <div className='display-table-row'>
                <div className='display-table-cell'>State Licenses</div>
                <div className='display-table-cell'>{agent.state_licenses}</div>
              </div>
            ) : null}

            {agent.social_profiles && agent.social_profiles.length ? (
              <div className='display-table-row social-profiles-row'>
                <div className='display-table-cell'>Social Profiles</div>
                <div className='display-table-cell social-profiles'>
                  {agent.social_profiles.map(socialProfile => (
                    <a
                      href={socialProfile.url}
                      className='social-profile-link'
                      target='_blank'
                      rel='noreferrer nofollow'
                      key={`social-profile-${socialProfile.platform}`}
                    >
                      {SOCIAL_PROFILE_PLATFORM_ICONS[socialProfile.platform]}
                    </a>
                  ))}
                </div>
              </div>
            ) : null}
          </div>

          <span className='small-text'>
            For most up to date loan officer license info and work history&nbsp;
            <a
              href={`http://nmlsconsumeraccess.org/EntityDetails.aspx/INDIVIDUAL/${agent.nmls}`}
              target='_blank'
              rel='nofollow noreferrer'
            >
              NMLS Consumer Access
            </a>
          </span>

          {agent.lender ? (
            <span className='small-text'>
              License and Disclosure information for&nbsp;
              <a
                href={
                  licenseDisclosure || `https://nmlsconsumeraccess.org/EntityDetails.aspx/COMPANY/${agent.lender.nmls}`
                }
                target='_blank'
                rel='nofollow noreferrer'
              >
                {agent.lender.name}
              </a>
            </span>
          ) : null}

          <span className='small-text'>
            Loan officers can only respond to inquiries for states in which they are licensed. This lender cannot accept
            mortgage loan applications or inquiries for properties located in New York through this site.
          </span>

          {!agent.is_claimed ? (
            <span className='small-text'>
              To claim your profile, ask questions or get more information&nbsp;<Link to='/contact-us'>contact us</Link>
              .
            </span>
          ) : null}
        </div>
        <div className='rating-holder' id='rating-and-cta-buttons'>
          <Rating rate={rating} reviewsCount={`${agent.reviews_count} LENDAID Certified Reviews`} />
          {agent.reviews_count &&
          (!agent['is_survey_enabled'] || (agent.lender ? agent.lender['is_reviews_sourced_independently'] : false)) ? (
            <Shield popContent={SHIELD_TEXT} />
          ) : (
            <div />
          )}
          {this.props.showContactButton === 'on' && (
            <button
              className='button arrow small'
              style={{ minWidth: 250, justifyContent: 'center' }}
              onClick={this.handleScrollToElement.bind(this)}
              type='button'
            >
              Contact {getFirstName(agent.name)}
            </button>
          )}
          {phoneNumber ? (
            <a className='display-table-cell officer-phone-number' href={phoneNumber}>
              <button
                className='button arrow small'
                style={{ minWidth: 250, marginTop: 10, justifyContent: 'center' }}
                type='button'
              >
                Call {getFirstName(agent.name)}
              </button>
            </a>
          ) : null}
        </div>
      </div>
    );
  }
}

function mapStateToProp({ agentProfile }) {
  return { rating: agentProfile.rating };
}

export default connect(mapStateToProp)(AgentInfo);
