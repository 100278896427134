import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import _ from 'lodash';

import { REVIEW_REPORT_SUCCESS_MESSAGE, REVIEW_REPORT_FAILURE_MESSAGE } from 'Constants';
import TextField from 'components/FormComponents/TextField';
import Select from 'components/FormComponents/Select';
import { submitReviewReport } from 'actions/form_actions';

const defaultValues = {
  name: '',
  email: '',
  reason: '',
  comments: '',
};

const schemaValidation = yup
  .object({
    name: yup.string().trim().required('Enter Name'),
    email: yup.string().trim().required('Enter Email').email('Invalid Email Address'),
    reason: yup.string().required('Select an option'),
    comments: yup.string().trim().required('Enter Comments'),
  })
  .required();

function ReportReviewForm({ reviewId }) {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const { status, isSubmitted } = useSelector(state => state.forms.reportReviewForm);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    watch,
    trigger,
  } = useForm({ mode: 'onTouched', defaultValues, resolver: yupResolver(schemaValidation) });

  const reasonValue = watch('reason');

  useEffect(() => {
    if (reasonValue !== defaultValues.reason) {
      trigger('reason');
    }
  }, [reasonValue, trigger]);

  useEffect(() => {
    if (status === 201) {
      reset();
    }
    setFormSubmitting(false);
  }, [status, isSubmitted, reset]);

  const onSubmit = data => {
    setFormSubmitting(true);
    dispatch(submitReviewReport(reviewId, data));
  };

  return (
    <div>
      {isSubmitted === true ? (
        <div className='form-status-msg-container success'>{REVIEW_REPORT_SUCCESS_MESSAGE}</div>
      ) : isSubmitted === false ? (
        <div className='form-status-msg-container error'>{REVIEW_REPORT_FAILURE_MESSAGE}</div>
      ) : null}

      <form onSubmit={handleSubmit(onSubmit)} className='form'>
        <div className='form-row'>
          <TextField
            id='name'
            type='text'
            placeholder='Name*'
            {...register('name')}
            errorMessage={errors?.name?.message}
            containerClass='form-item'
          />
        </div>

        <div className='form-row'>
          <TextField
            id='email'
            type='email'
            placeholder='Email*'
            {...register('email')}
            errorMessage={errors?.email?.message}
            containerClass='form-item'
          />
        </div>

        <div className='form-row'>
          <Select
            id='reason'
            placeholder='Select your option*'
            {...register('reason')}
            options={[
              { value: 'IC', label: 'Inappropriate/Offensive Content' },
              { value: 'SC', label: 'Spam Content' },
              { value: 'FR', label: 'Fake Review' },
              { value: 'OT', label: 'Other' },
            ]}
            setValue={setValue}
            containerClass='form-item'
            errorMessage={errors?.reason?.message}
          />
        </div>

        <div className='form-row'>
          <TextField
            id='comments'
            type='textarea'
            placeholder='Comments*'
            {...register('comments')}
            errorMessage={errors?.comments?.message}
            containerClass='form-item'
          />
        </div>

        <div className='form-row'>
          <button
            type='submit'
            disabled={!_.isEmpty(errors) || formSubmitting || isSubmitted === true}
            className='button arrow small'
          >
            Submit the report
          </button>
        </div>
      </form>
    </div>
  );
}

export default ReportReviewForm;
