import React, { forwardRef, useRef, useState } from 'react';
import classNames from 'classnames';

function Rating({ id, label, name, onChange, errorMessage, helpText, containerClass, setValue, clearErrors }, ref) {
  const [rating, setRating] = useState(0);
  const inputRef = useRef(null);

  const onStarClick = rating => {
    inputRef.current.value = rating;
    setValue(name, rating);
    clearErrors(name);
    setRating(rating);
  };
  const inputProps = {
    id,
    name,
    onChange,
    type: 'text',
    hidden: true,
  };

  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <div className='star' key={i} onClick={() => onStarClick(i)}>
          <span className='icon-star' data-rating={i} data-name={name} />
        </div>
      );
    }
    return stars;
  };

  return (
    <div className={classNames(containerClass, 'rating-input')}>
      <label className='label'>{label}</label>

      <div className='input-stars'>
        <input
          {...inputProps}
          ref={e => {
            ref(e);
            inputRef.current = e;
          }}
        />

        <div className={`stars stars--${rating}`}>{renderStars()}</div>
      </div>
      {errorMessage ? <span className='error-message'>{errorMessage}</span> : null}

      <span className='help-text'>{helpText}</span>
    </div>
  );
}

export default forwardRef(Rating);
