import React from 'react';
import { useDispatch } from 'react-redux';

import Rating from 'components/Common/Rating';
import shield from 'assets/images/shield.png';
import google from 'assets/images/google.png';
import ReportIcon from 'components/Icons/ReportFlag';
import { openReportReviewModal } from 'actions/modal_actions';
import Tooltip from 'components/Common/Tooltip';

function OfficerTestimonial(props) {
  const { reviewId, reviewerType, purchase, address, rating, source } = props;
  const reply = props.reply ? props.reply.comment : '';
  const avatar = props.avatar || '';
  const borrower = props.borrower || '';

  const dispatch = useDispatch();

  const handleReportClick = () => {
    dispatch(openReportReviewModal(reviewId));
  };

  return (
    <div className='testimonial'>
      <div>
        <div style={{ float: 'right' }}>
          {source === 'Google' ? 'Google' : 'Verified'}{' '}
          <img alt='Certified' src={source !== 'Google' ? shield : google} height='25px' />
        </div>
        <Rating rate={rating} />
      </div>

      <div className='comment'>{props.children}</div>

      <div>
        <Tooltip message='Flag review' position='top' style={{ float: 'right' }}>
          <ReportIcon onClick={handleReportClick} className='icon' />
        </Tooltip>

        <div className='user'>
          <strong>{purchase}</strong>
          <br />
          {borrower && borrower}
          {source !== 'Google' && ` - ${address}`}
          <br />
          {reviewerType === 'RP' && 'Referral Partner'}
        </div>
      </div>

      {reply && (
        <div className='comment reply'>
          <div className='testimonial__image' style={{ backgroundImage: `url(${avatar})` }} />
          <p>{`"${reply}"`}</p>
        </div>
      )}
    </div>
  );
}

export default OfficerTestimonial;
