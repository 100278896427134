import React, { forwardRef, useState, useRef, useImperativeHandle } from 'react';
import classNames from 'classnames';

function Select(props, ref) {
  const [open, setOpen] = useState(false);
  const inputRef = useRef();
  useImperativeHandle(ref, () => inputRef.current, [inputRef]);

  const { placeholder, name, onChange, options, containerClass, setValue, errorMessage } = props;

  const displayedOption = options.find(option => option.value === inputRef.current?.value);

  const selectOption = option => {
    inputRef.current.value = option.value;
    setOpen(false);
    setValue(name, option.value);
  };

  const renderOptions = options =>
    options.map(option => (
      <div className='option' onClick={() => selectOption(option)} key={`${name}-option-${option.value}`}>
        {option.label}
      </div>
    ));

  const inputProps = {
    name,
    onChange,
  };

  return (
    <div className={classNames('select-container', containerClass, { invalid: !!errorMessage })}>
      <input {...inputProps} hidden ref={inputRef} />
      <div className='select-wrapper'>
        <div
          className={classNames('select-value', { open }, { placeholder: !displayedOption })}
          onClick={() => setOpen(!open)}
        >
          {displayedOption ? displayedOption.label : placeholder}
        </div>
        {open && <div className='select-options'>{renderOptions(options || [])}</div>}
      </div>
      {errorMessage ? <span className='error-message'>{errorMessage}</span> : null}
    </div>
  );
}

export default forwardRef(Select);
