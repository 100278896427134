import React from 'react';
import { ADDRESS } from 'Constants';

function Privacy() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  scrollToTop();

  return (
    <div id='privacy'>
      <section className='padding-all'>
        <div className='single-column'>
          <h1 className='no-margin--bottom'>Privacy Policy</h1>
        </div>
      </section>
      <section className='padding-x padding-bottom'>
        <div className='single-column'>
          <p>
            LENDAID, Inc. (“<strong>LENDAID</strong>”), has created the LENDAID website at <strong>LENDAID.com </strong>
            (the “<strong>Website</strong>” or the “<strong>Site</strong>”) to provide an online platform for
            prospective home buyers to find positively rated Licensed Mortgage Loan Officers (“<strong>MLOs</strong>”)
            employed by Licensed Mortgage Lenders <strong>(“Lenders”) </strong>
            (see our Terms of Use for more information about our Services).
          </p>
          <p>
            LENDAID is committed to maintaining your privacy. This privacy policy (“<strong>Privacy Policy</strong>”)
            covers how we collect, use, and treat personal information that we may collect through our Website. Our
            Privacy Policy applies to any visitor to the Website (collectively, “<strong>you</strong>”), including (i)
            casual visitors to our Site, who do not participate in the Services (“<strong>Site Visitors</strong>”), (ii)
            MLOs and Lenders who register to use the Site <strong>(“Registered Users”)</strong> and (iii) individuals
            who otherwise participate in the Services (“
            <strong>Service Users</strong>”). The terms “<strong>LENDAID</strong>,” “<strong>we</strong>” and “
            <strong>us</strong>” refer to LENDAID, Inc.
          </p>

          <p id='your-consent'>
            <strong className='heading'>1. Your Consent. </strong> Please read this Privacy Policy carefully; once you
            consent to this Policy&apos;s terms, it creates legal obligations on you and on LENDAID. By accessing and
            using our Site, you are acknowledging that you have read and understood this Privacy Policy and agree to be
            legally bound by it. If you do not agree with the terms of this Privacy Policy, please refrain from using
            our Site.
          </p>

          <p id='information-you-provide'>
            <strong className='heading'>2. The Types of Information You May Provide.</strong> Information you may
            provide to LENDAID falls into two broad categories: (i) personally identifiable information, and (ii)
            aggregate information. We use the term “<strong>Personally Identifiable Information</strong>” to mean any
            information that could reasonably be used to identify you, including your name, address, e-mail address,
            birth date, financial information, photograph, telephone number or any combination of information that could
            be used to identify you. “<strong>Anonymous Information</strong>” is information that does not identify you,
            and may include statistical information concerning, for example, your use of our Website and associated
            Services, including the aspects of our Website that you use most frequently.
          </p>

          <p id='personally-identifiable-information'>
            <strong className='heading'>3. Personally Identifiable Information; Our Uses of This Information. </strong>
            We will not share your Personally Identifiable Information that we receive in connection with the Website
            with third parties, other than as expressly disclosed in this Privacy Policy or otherwise with your express
            consent. We collect information from Service Users and Registered Users at different points through our
            Website and through our Services, and use this information as follows:
          </p>

          <p id='mlo-contact-request'>
            <strong className='heading'>3.1. MLO Contact Request Form. </strong>
            In order to use the contact button feature to connect to an MLO via the Website, we will collect the
            following Personally Identifiable Information: (i) name; (ii) email address; (iii) company name; (iv) phone
            number; (v) geographic search information.{' '}
            <strong>We will ONLY share this information with the MLO you selected to contact. </strong>
          </p>

          <p id='review-information'>
            <strong className='heading'>3.2. Review Information. </strong>
            The Website may provide Service Users the ability to provide reviews of the MLOs with whom you have worked
            (“<strong>Reviews</strong>”). To the extent we provide this functionality, in displaying your Reviews on the
            Website, we will only display the town in which you purchased or refinanced a property – we will display
            your name if you choose to leave your name in the Review. PLEASE NOTE: Any information you include in a
            Review will become public upon posting the Review. Any information you disclose in a Review or any other
            public discussion feature of our Services is not treated as Personally Identifiable Information under this
            Privacy Policy. It is your responsibility to use discretion in what is posted or otherwise shared in
            publicly accessible parts of our Website in conjunction with your participation in the Services. We may also
            aggregate the Review with other Reviews and we may share the aggregated Review results with the MLOs.
          </p>

          <p id='email-addresses'>
            <strong className='heading'>3.3. Email Addresses </strong>
            Service Users and Registered Users may wish to provide us with comments and feedback concerning our Website
            generally. By communicating with us for these purposes, you may provide us with Personally Identifiable
            Information. In these instances, we use any information you provide to us strictly for the purposes for
            which you submit the information.
          </p>

          <p id='questionnaires-surveys'>
            <strong className='heading'>3.4. Questionnaires and Surveys. </strong>
            Our Website may allow Service Users and Registered Users to participate in surveys and/or questionnaires,
            which we may post via the Website from time to time. You are free to choose whether you participate in a
            survey or questionnaire. We may ask that, in addition to providing your responses, you also provide
            Personally Identifiable Information, such as your email address and name. In these instances, we use any
            information you provide to us strictly for the purposes for which you submit the information. Survey and
            questionnaire information may be used for the purposes of monitoring or improving the use and appeal of our
            Website. We may publish survey results on our Website in the form of cumulative star ratings and we may
            share survey results with the MLO the survey was conducted about (as applicable), though we will not
            identify you in association with a particular survey response.
          </p>

          <p id='informational-notices-bulletins'>
            <strong className='heading'>3.5. Informational Notices and Bulletins. </strong>
            Periodically, we may choose to send news, bulletins, marketing materials, or other information to Service
            Users and Registered Users, and will use Personally Identifiable Information to send such communications. If
            we choose to undertake such communications, you will have the ability to opt out of receiving these
            communications as provided in Section <a href='#removing-information'>7</a> (Changing or Removing
            Information; Opting Out).
          </p>

          <p id='blogs-message-boards'>
            <strong className='heading'>3.6. Blogs, Message Boards. </strong>
            We may offer a blog or message board system on our Website and you may choose to participate in an online
            discussion or to provide online comments. Any comments you contribute to the online dialogue, however,
            become public immediately and any Personally Identifiable Information you disclose becomes available to the
            other participants in the discussion. Our Privacy Policy does not cover the information you disclose in such
            online discussions on our Website. You should, therefore, use discretion in what you submit to these
            exchanges.
          </p>

          <p id='information-collected'>
            <strong className='heading'>3.7. Extent of Information Collected. </strong>
            LENDAID does not collect any more Personally Identifiable Information than reasonably necessary to provide
            the Services.
          </p>

          <p id='review-sharing'>
            <strong className='heading'>3.8. Review Sharing. </strong>
            Reviews may be posted to other websites for testimonial purposes. Reviews may be posted to MLO company
            webpages as well as shared on third-party social media sites including but not limited to Facebook, Twitter,
            Instagram, and LinkedIn. These Reviews will include your rating, comments, property city/state, and your
            first name, if you elected to provide your first name in the Review. PLEASE NOTE: Any information you
            include in a Review will become public upon posting the Review. Any information you disclose in a Review or
            any other public discussion feature of our Services is not treated as Personally Identifiable Information
            under this Privacy Policy.
          </p>

          <p id='review-sharing'>
            <strong className='heading'>3.9 MLO and Social Media Sharing. </strong>
            Registered Users on the LENDAID platform are offered the ability to share their Reviews to third-party
            social media platforms Facebook, LinkedIn and Twitter. In order to facilitate this social sharing of your
            Reviews, you have the option to opt into this sharing feature by connecting our LENDAID platform to your
            chosen social media platform(s). By opting into this connection on our App, you are granting LENDAID access
            to your social media pages for the limited and express purpose of posting Reviews to your social media
            pages. LENDAID will only post to your social media pages Reviews you specifically chose to share or that
            meet specific criteria for sharing.
          </p>
          <p>
            If you would prefer to not have your Reviews posted, you should not connect LENDAID with the available
            third-party sites. You can also delete the posts on the third-party sites if they allow you to do so.
          </p>
          <p>
            We have no control over the privacy practices of sites that we do not own. Review your privacy settings and
            policies for the third-party sites to understand the information it sends to us and how they use information
            they receive before connecting them to our Services. Such linking is at the complete discretion of users.
            Because of this, LENDAID cannot be held responsible or liable for the linking of a Registered User’s Data to
            such third-party sites, nor for how these third-party sites use such links.
          </p>

          <p id='anonymous-aggregate-information'>
            <strong className='heading'>4. Anonymous, Aggregate Information. </strong>
            We use Anonymous Information to analyze the effectiveness of our Website, to improve our Services, and for
            other similar purposes. In addition, from time to time, we may undertake or commission statistical and other
            summary analyses of the general behavior and characteristics of users participating in our Services. We may
            collect Anonymous Information through features of the software that supports our Services, through cookies,
            and through other means described below.
          </p>

          <p id='ip-addresses-logs.'>
            <strong className='heading'>4.1. IP Addresses; Logs. </strong>
            LENDAID may automatically receive and record information in our server logs from your browser, including
            your IP address (the Internet address of your mobile device), your mobile device&apos;s name, the type and
            version of your web browser, referrer addresses and other generally accepted log information. We may also
            record page views (hit counts) and other general statistical and tracking information, which will be
            aggregated with that of other users in order to understand how our Website is being used, and for security
            and monitoring purposes. None of this data alone can be used to identify you.
          </p>

          <p id='cookies'>
            <strong className='heading'>4.2. Cookies. </strong>A cookie is a small amount of data, which often includes
            an anonymous unique identifier, which is sent to your browser from a website and stored on your mobile
            device&apos;s hard drive. Cookies can be used to provide you with a tailored user experience and to make it
            easier for you to use a website upon a future visit. We may include cookies on our Website and use them to
            recognize you when you return to our Website. You may set your browser so that it does not accept cookies.
            Cookies must be enabled on your web browser, however, if you wish to access certain personalized features of
            our Services.
          </p>

          <p id='tags'>
            <strong className='heading'>4.3. Tags. </strong>
            We may use so-called “pixel tags” — small graphic images (also known as “web beacons” or “single-pixel
            GIFS”) — to tell us what parts of our Website have been visited or to measure the effectiveness of user
            activity on our Website. Pixel tags also enable us to send email messages in a format users can read, and
            they inform us whether emails have been opened, to help ensure that our messages are of interest to our
            users. None of this data includes Personally Identifiable Information, and you can “opt-out” of receiving
            these types of emails from us by following the directions provided in Section{' '}
            <a href='#removing-information'>7</a> (Changing or Removing Information; Opting Out). If any Personally
            Identifiable Information is collected using such tools, it will be subject to the terms of this Privacy
            Policy.
          </p>

          <p id='click-throughs'>
            <strong className='heading'>4.4. Click-Throughs. </strong>
            We may send email messages, which use a “click-through URL” linked to content on our Website. When you click
            one of these URLs, you pass through our web server before arriving at the Website. We track this
            click-through data to help determine interest in particular topics and measure the effectiveness of our
            Service User and Registered User communications. If you prefer not to be tracked simply do not click text or
            graphic links in the email, or notify us in accordance with{' '}
            <a href='#removing-information'> Section 7 (Changing or Removing Information; Opting Out)</a>.
          </p>

          <p id='computer-configuration'>
            <strong className='heading'>4.5. Computer Configuration. </strong>
            In order to determine whether your mobile device is supported by our system, we may collect certain
            Anonymous Information. This information includes, but may not be limited to, your operating system and
            browser, as well as the presence of any software that our Website may require to operate with your mobile
            device, or other third-party software on your mobile device. This information is kept strictly confidential
            and is not shared with third parties, except as provided for in this Privacy Policy.
          </p>

          <p id='information-linked'>
            <strong className='heading'>
              5. Any Information Linked with Your Personally Identifiable Information Is Protected As “Personally
              Identifiable Information.”{' '}
            </strong>
            To enable us to better understand the characteristics of our users and/or to provide services tailored to
            your needs, we may link (a) the Personally Identifiable Information you have provided; with (b) Anonymous
            Information. If we combine or link any Anonymous Information or other information with your Personally
            Identifiable Information, the resulting combination will be treated and protected as Personally Identifiable
            Information under this Privacy Policy. Accordingly, no unrelated third parties will have access to this
            information, and any of our service providers who are granted access to this information will be bound to
            protect it in accordance with this Policy.
          </p>

          <p id='disclose-personally-identifiable'>
            <strong className='heading'>
              6. We Do Not Disclose Personally Identifiable Information to Unaffiliated Third Parties; Limited
              Exceptions.{' '}
            </strong>
            We will not disclose your Personally Identifiable Information collected by LENDAID to unaffiliated third
            parties without your express consent, except in the following limited circumstances:
          </p>

          <p id='website-vendors'>
            <strong className='heading'>6.1. LENDAID Website Vendors. </strong>
            We may employ other companies to perform functions on our behalf, such as hosting or maintaining the
            Website, providing services related to the Website, collecting information, responding to and sending
            electronic mail, or other functions necessary to our business. We may need to share your Personally
            Identifiable Information with these companies (collectively, “<strong>LENDAID Vendors</strong>”). We will
            provide LENDAID Vendors with only that information necessary to perform their functions, and we will not
            allow them to use your Personally Identifiable Information for any other purpose.
          </p>

          <p id='information-transmission'>
            <strong className='heading'>6.2. Transmission of Information to Other Countries. </strong>
            While LENDAID is located in the United States, LENDAID Vendors may be located in the United States and other
            countries around the world. As a result, your information may be processed in a foreign country with
            different privacy laws than the laws in your country. We ensure that LENDAID Vendors’ security policies are
            at least as protective of your information as our policy. Moreover, we provide access to our databases
            containing Personally Identifiable Information on a need-to-know basis only. By submitting your Personally
            Identifiable Information to us you agree to the transfer, storage and processing of your Personally
            Identifiable Information in a country other than your country of residence including, but not necessarily
            limited to, the United States.
          </p>

          <p id='question-harm'>
            <strong className='heading'>6.3. Question of Harm. </strong>
            We may reveal your Personally Identifiable Information to attorneys, private investigator organizations or
            law enforcement agencies if we believe (a) that you are in risk of harm from another, or (b) that you are
            harming or interfering (or will harm or interfere) with others or violating (either intentionally or
            unintentionally) our Terms and Conditions of Use or otherwise violating legal rights.
          </p>

          <p id='legal'>
            <strong className='heading'>6.4. Legal. </strong>
            LENDAID will reveal your Personally Identifiable Information, to the extent we reasonably believe we are
            required to do so by law. If we receive a legal process calling for the disclosure of your Personally
            Identifiable Information, we will attempt to notify you via the email address you supplied during
            registration within a reasonable amount of time before we respond to the request, unless such notification
            is not permitted.
          </p>

          <p id='transfer-services'>
            <strong className='heading'>6.5. Transfer of Services. </strong>
            LENDAID shall be entitled to transfer to a third party information it collects, including any Personally
            Identifiable Information, in connection with a sale of all or substantially all of the assets of LENDAID,
            provided the acquiring third party has agreed to safeguard your Personally Identifiable Information with
            protections that in all material respects are the same as or more protective than those set out in this
            Privacy Policy.
          </p>

          <p id='removing-information'>
            <strong className='heading'>7. Changing or Removing Information; Opting Out. </strong>
          </p>

          <p id='account-information'>
            <strong className='heading'>7.1. Discretionary Account Information. </strong>
            To allow appropriate control over Personally Identifiable Information, you can submit a request that we
            update any Information we have about you that you have previously submitted by contacting us as provided
            below (Contact Us, <a href='#contact-us'>16</a>).
          </p>

          <p id='opt-out'>
            <strong className='heading'>7.2. Opt-Out. </strong>
            If we choose to send to you bulletins, updates, or other unsolicited communications that are
            marketing-related materials, we will provide you with the ability to decline - or “opt-out of” – receiving
            such communications. Instructions for opting-out will be provided if and when we determine to send you such
            a communication. Please understand that you will not be allowed to “opt–out” of formal notices concerning
            operation of this Website, or legal and other related notices concerning your relationship to the Website.
          </p>

          <p id='deleting-information'>
            <strong className='heading'>7.3. Deleting Information. </strong>
            Finally, if you request by contacting us as provided below (Contact Us, <a href='#contact-us'>16</a>), we
            will remove your name and all other Personally Identifiable Information from our databases. Please
            understand, however, that it may be impossible to remove this information completely, due to backups and
            records of deletions. In addition, please understand that, if you request deletion of your information, you
            will be unable to utilize associated features of the Website and any associated Services. You may not remove
            de-identified, anonymous, or aggregate data from our databases.
          </p>

          <p id='errors-inaccuracies-omissions'>
            <strong className='heading'>7.4. Errors, Inaccuracies and Omissions. </strong>
            Occasionally there may be information on the Website that contain typographical errors, inaccuracies, and/or
            omissions. We reserve the right to: (i) correct any errors, inaccuracies, and/or omissions; and/or (ii) make
            changes to content and/or service descriptions, and/or other information without obligation to issue any
            notice of such changes.
          </p>

          <p id='security'>
            <strong className='heading'>8. Security. </strong>
            We have put in place security systems designed to prevent unauthorized access to or disclosure of Personally
            Identifiable Information you provide to us, and we take all reasonable steps to secure and safeguard this
            Information. LENDAID employees are required to acknowledge that they understand and will abide by our
            policies with respect to the confidentiality of Personally Identifiable Information. As stated previously,
            we ensure that LENDAID Vendors’ security policies are at least as protective of your information as our
            policy. Moreover, we provide access to our databases containing Personally Identifiable Information on a
            need-to-know basis only.
          </p>
          <p>
            Our security systems are therefore structured to deter and prevent hackers and others from accessing
            information you provide to us. We also use software programs to monitor network traffic to identify
            unauthorized attempts to upload or change information, or otherwise cause damage. Please understand, though,
            that this information should not be construed in any way as giving business, legal, or other advice, or
            warranting as fail proof, the security of information provided by or submitted to LENDAID. Due to the nature
            of Internet communications and evolving technologies, we cannot provide, and disclaim, assurance that the
            information you provide us will remain free from loss, misuse, or alteration by third parties who, despite
            our efforts, obtain unauthorized access.
          </p>

          <p id='security-incident'>
            <strong className='heading'>9. Notice of Security Incident. </strong>
            If we detect, despite the safeguards set out above, an intrusion or other unauthorized access to or use of
            Personally Identifiable Information (an “<strong>Intrusion</strong>”), we will (i) notify affected users of
            the Intrusion if the information at issue is sensitive, in our discretion, (ii) deliver this notice by the
            means we deem most efficient under the circumstances (such as, for example, first class mail or email);
            (iii) use contact information for each effected user that is current in our files; and (iv) use commercially
            reasonable efforts to accomplish these steps and effect this notice in a timely manner. To the extent
            applicable law requires steps in addition to those specified above, we will under all circumstances comply
            with applicable law.
          </p>

          <p id='teen-advisory'>
            <strong className='heading'>10. Participation by Children and Teens; Advisory. </strong>
            Due to federal law (as reflected in the Children&apos;s Online Privacy Protection Act), WE DO NOT ALLOW
            INDIVIDUALS WHO WE KNOW ARE UNDER 13 TO PARTICIPATE IN OUR WEBSITE OR SERVICES. YOU MUST BE AT LEAST 13
            YEARS OLD TO USE OUR WEBSITE AND SERVICES. IF YOU ARE BETWEEN 13 AND THE APPLICABLE AGE OF MAJORITY, PLEASE
            REVIEW THIS AGREEMENT WITH YOUR PARENT OR GUARDIAN. Please understand that we cannot necessarily tell if a
            user is providing us with his or her true age.
          </p>

          <p id='california-residents'>
            <strong className='heading'>11. Notice to California Residents. </strong>
            If you are a California resident, California Civil Code Section 1798.83 permits you to request information
            regarding the disclosure of your personal information by LENDAID to third parties for the third parties’
            direct marketing purposes. As described above, we do not disclose users&apos; personal information to third
            parties for direct marketing purposes. Nevertheless, if you wish to make such a request, please send an
            email to or write us at:
            <p>Attention: Customer Service</p>
            <p>
              Email: <a href='mailto:info@LENDAID.com'>info@LENDAID.com</a>
            </p>
            <p>
              Mail:{' '}
              <a href='https://goo.gl/maps/wnEGuRJmSV72' target='_blank' rel='noopener noreferrer'>
                {ADDRESS['street']}, {ADDRESS['suite']}, {ADDRESS['state']}
              </a>
            </p>
          </p>

          <p id='track-notice'>
            <strong className='heading'>12. Do Not Track Notice. </strong>
            Certain web browsers allow consumers to enable “do not track” signals through which a consumer can signal to
            websites and online services whether the consumer approves of the collection of personally identifiable
            information about its online activities over time and across third-party websites and online services.
            LENDAID does not respond to “do not track” signals.
          </p>

          <p id='notification-of-changes'>
            <strong className='heading'>13. Notification of Changes. </strong>
            From time to time, LENDAID may change its Privacy Policy. If we make any material changes regarding our use
            or disclosure of Personally Identifiable Information to third parties, we will attempt to contact you prior
            to the date the modified policy is scheduled to take effect. We will post notice of the new Policy from the
            privacy link on our Website. With respect to users, no such changes will take effect unless and until you
            have provided us with your consent to such changes. With respect to Site Visitors, such changes will take
            effect the next time you visit our Website.
          </p>

          <p id='relationship-term'>
            <strong className='heading'>14. Relationship to Terms of Use and Other Contracts. </strong>
            This Privacy Policy must be read in conjunction with our Terms of Use, and the provisions of our Terms of
            Use are incorporated herein. To the extent the Terms of Use conflict with the terms of this Privacy Policy,
            the terms of this Privacy Policy will control.
          </p>

          <p id='further-resources'>
            <strong className='heading'>15. Further Resources. </strong>
            If you wish further information concerning privacy policies in general, you should visit the following site:
            <p>
              <a href='http://www.ftc.gov/privacy/index.html' target='_blank' rel='noopener noreferrer'>
                http://www.ftc.gov/privacy/index.html
              </a>
            </p>
          </p>

          <p id='contact-us'>
            <strong className='heading'>16. Contact Us. </strong>
            If you have any questions about this Privacy Policy, the practices of LENDAID, or your dealings with the
            Website, please contact us at:
            <a href='mailto:info@LENDAID.com'> info@LENDAID.com</a>
          </p>

          <p id='effective-date'>
            <strong className='heading'>17. Effective Date:</strong>
            <p>The effective date of this Privacy Policy is August 30, 2022.</p>
          </p>
          <p>
            <strong className='all-caps'>COPYRIGHT AND LEGAL NOTICE.</strong> Copyright © 2017 LENDAID LLC. All Rights
            Reserved.
          </p>
        </div>
      </section>
    </div>
  );
}

export default Privacy;
