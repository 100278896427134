import { LENDER_PROFILE } from 'actions/action_types';

const initialState = { lender: null };
function lendersReducer(state = initialState, action) {
  switch (action.type) {
    case LENDER_PROFILE:
      return { ...state, lender: action.payload.data };
    default:
      return state;
  }
}

export default lendersReducer;
