import React from 'react';

function ConsejoHeader() {
  return (
    <section className='padding-all'>
      <div className='blog-title'>
        <h3>Consejo</h3>
        <h1 className='no-margin--bottom'>Consejo en Espanol</h1>
      </div>
    </section>
  );
}

export default ConsejoHeader;
