import {
  AGENT_SURVEY,
  FETCH_AGENT_PROFILE,
  TOP_RATED_AGENT,
  AGENT_RATING,
  AGENT_GOOGLE_REVIEWS,
  AGENT_TRANSACTIONS,
  AGENT_CONTACT_FORM,
  AGENT_REVIEWS,
  SUBMITTING_SURVEY,
} from 'actions/action_types';
import { getLocationTypeAndTextFromLocalStorage, fillFormDataWithEmptyValues } from 'actions/utility_actions';
import {
  agentSurveyAPI,
  fetchAgentProfileAPI,
  fetchAgentRatingsAPI,
  fetchAgentReviewsAPI,
  fetchAgentGoogleReviewsAPI,
  fetchAgentTransactionsAPI,
  submitAgentContactFormAPI,
  topRatedAgentsAPI,
} from 'api';

export function fetchAgentProfile(nameSlug) {
  return {
    type: FETCH_AGENT_PROFILE,
    payload: fetchAgentProfileAPI(nameSlug),
  };
}

export function fetchAgentRatings(agentId) {
  return {
    type: AGENT_RATING,
    payload: fetchAgentRatingsAPI(agentId),
  };
}

export function fetchAgentReviews(agentId, page) {
  return {
    type: AGENT_REVIEWS,
    payload: fetchAgentReviewsAPI(agentId, page),
  };
}

export function fetchAgentGoogleReviews(agentId, page) {
  return {
    type: AGENT_GOOGLE_REVIEWS,
    payload: fetchAgentGoogleReviewsAPI(agentId, page),
  };
}

export function submitAgentContactForm(agentId, formData) {
  formData = fillFormDataWithEmptyValues(formData, ['email', 'message', 'location']);
  return {
    type: AGENT_CONTACT_FORM,
    payload: submitAgentContactFormAPI(agentId, formData),
  };
}

export function topRatedAgents(formData) {
  const location = getLocationTypeAndTextFromLocalStorage();
  return {
    type: TOP_RATED_AGENT,
    payload: topRatedAgentsAPI(location),
  };
}

export function fetchAgentTransactions(agentId) {
  return {
    type: AGENT_TRANSACTIONS,
    payload: fetchAgentTransactionsAPI(agentId),
  };
}

export function agentSurvey(surveyData, officerId) {
  return {
    type: AGENT_SURVEY,
    payload: agentSurveyAPI(surveyData, officerId),
  };
}

export function submittingSurvey() {
  return {
    type: SUBMITTING_SURVEY,
  };
}
